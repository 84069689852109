import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { NavLink } from "react-router-dom";
import { Col, Container, Form, FormGroup, Row } from "react-bootstrap";
import Heading from "src/common/Heading";
import FormInput from "src/common/FormInput";
import CustomCard from "src/common/CustomCard";
import FormButton from "src/common/FormButton";
import MessagePopup from "src/common/MessagePopup";
import LoadingSpinner from "src/common/LoadingSpinner";
import { AuthHook } from "src/context/auth";
import { UserService } from "src/utility/services";
import { ErrorMessages } from "src/utility/constants/common";
import { ReactComponent as Person } from "src/assets/icons/person.svg";

const profileSchema = Yup.object().shape({
  firstName: Yup.string().trim().required("First name is required"),
  lastName: Yup.string().trim().required("Last name is required"),
  email: Yup.string(),
  mobileNumber: Yup.string().trim().required("Mobile number is required"),
  // birthDate: Yup.date().required("Birthday is Required"),
  // address: Yup.string().required("Address is required"),
});

type FormType = {
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  birthDate: string;
  address: string;
};

const EditProfile = () => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState("");

  const { user, login } = AuthHook();

  const save = async (data: FormType) => {
    try {
      setError("");
      setSuccess("");
      setLoading(true);
      const body = {
        ...user,
        ...data,
      };
      await UserService.add(body);
      login(body);

      UserService.storeUser(body);
      setSuccess("Data saved successfully.");
    } catch (err: any) {
      setError(err?.data?.detail || ErrorMessages.default);
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      {loading ? <LoadingSpinner /> : null}
      <MessagePopup
        show={!!success}
        title="SUCCESSSS"
        text={success}
        btnText="OK"
        onClick={() => {
          setSuccess("");
        }}
        children=""
      />
      <div className="bgdark min-vh-100">
        <Container className="h-100 py-5">
          <Row className="txt-white txt-medium align-items-center pb-5">
            <Col lg={6} sm={6} xs={12} className="responsive-left">
              <Heading
                type="main"
                text="My Profile"
                color="white"
                className="txt-heading"
              />
            </Col>
            <Col lg={6} sm={6} xs={12} className="responsive-right">
              <NavLink to="/app/dashboard" className="txt-medium navbar-link">
                My Wallet
              </NavLink>
              <NavLink
                to="/app/pack-sale"
                className="txt-medium navbar-link ps-5"
              >
                Pack Sale
              </NavLink>
            </Col>
          </Row>
          <Row className="justify-content-center align-items-center m-0">
            <Col lg={5} md={8}>
              <CustomCard bg="gray">
                <div className="d-flex align-items-center ms-3 my-4">
                  <Person className="mx-2" />
                  <Heading
                    type="subheading"
                    text="My Profile Details"
                    className="m-0"
                  />
                </div>
                <Formik
                  initialValues={{
                    firstName: `${user.firstName}`,
                    lastName: `${user.lastName}`,
                    email: `${user.email}`,
                    mobileNumber: `${user.mobileNumber}`,
                    address: `${user.address}`,
                    birthDate: `${user.birthDate}`,
                  }}
                  validationSchema={profileSchema}
                  onSubmit={(values) => {
                    values.mobileNumber = values.mobileNumber.toString();
                    save(values);
                  }}
                >
                  {({ values, errors, handleChange, handleSubmit }) => (
                    <Form onSubmit={handleSubmit} className="my-4">
                      <FormGroup className="d-flex">
                        <FormInput
                          type="text"
                          name="firstName"
                          placeholder={user.firstName || "First Name"}
                          error={errors.firstName}
                          border
                          className="me-3"
                          onChange={handleChange}
                          children=""
                        />
                        <FormInput
                          type="text"
                          name="lastName"
                          placeholder={user.lastName || "Last Name"}
                          error={errors.lastName}
                          border
                          onChange={handleChange}
                          children=""
                        />
                      </FormGroup>
                      <FormGroup className="d-flex flex-column align-items-center">
                        {/* {user.hasPassword && (
                          <FormInput
                            type="email"
                            name="email"
                            placeholder={user.email || "Email Address"}
                            error={errors.email}
                            border
                            onChange={handleChange}
                          />
                        )} */}

                        <FormInput
                          type="number"
                          name="mobileNumber"
                          placeholder={user.mobileNumber || "Mobile Number"}
                          error={errors.mobileNumber}
                          border
                          phone
                          onChange={handleChange}
                          children=""
                        />

                        <FormInput
                          type="date"
                          name="birthDate"
                          calendar={true}
                          border
                          onChange={handleChange}
                          placeholder={user.birthDate || "Birthday"}
                          value={values?.birthDate}
                          error={errors.birthDate}
                          children=""
                        />

                        <FormInput
                          type="text"
                          name="address"
                          border
                          placeholder={user?.address || "Address"}
                          location={true}
                          onChange={handleChange}
                          error={errors.address}
                          children=""
                        />
                      </FormGroup>
                      <FormGroup className="d-flex justify-content-center">
                        <FormButton type="submit" text="SAVE CHANGES" />
                      </FormGroup>
                      {error ? (
                        <p className="text-center txt-medium text-danger mt-3 mb-0">
                          {error}
                        </p>
                      ) : null}
                    </Form>
                  )}
                </Formik>
              </CustomCard>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditProfile;
