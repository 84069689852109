import { Image, Nav, NavDropdown } from "react-bootstrap";
import { ChevronDown } from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import { UserService } from "src/utility/services";
import { AuthHook } from "src/context/auth";
import { useGoogleLogout } from "react-google-login";

import defaultUserImage from "src/assets/images/user.png";
import { REACT_APP_GOOGLE_CLIENT_ID } from "src/utility/constants/common";

const ProfileDropdown = () => {
  const navigate = useNavigate();
  const { logout: logoutContext, user } = AuthHook();
  const { signOut } = useGoogleLogout({
    jsSrc: "https://apis.google.com/js/api.js",
    clientId: REACT_APP_GOOGLE_CLIENT_ID,
    cookiePolicy: "single_host_origin",
  });

  const logoutClick = () => {
    UserService.logout();
    logoutContext();
    signOut();
    navigate("/login");
  };
  return (
    <Nav className="align-items-center hideMobile">
      <Image
        src={user?.imageUrl || defaultUserImage}
        roundedCircle
        width="40"
      />
      <div className="d-flex align-items-center ms-3">
        <div>
          <p className="m-0 fw-bold">{`${user?.firstName} ${user?.lastName}`}</p>
          <p className="m-0 txt-small">{user?.email}</p>
        </div>
        <NavDropdown
          title={<ChevronDown strokeWidth="3" className="redirectBlue" />}
          id="basic-nav-dropdown"
          align="end"
        >
          <Link to="/app/my-profile" className="dropdown-item">
            My Profile
          </Link>
          {user?.hasPassword && (
            <Link to="/app/change-password" className="dropdown-item">
              Change Password
            </Link>
          )}

          <NavDropdown.Item onClick={logoutClick}>Log Out</NavDropdown.Item>
        </NavDropdown>
      </div>
    </Nav>
  );
};

export default ProfileDropdown;
