import CommonUtility from 'src/utility/common';
import { BaseService } from './base';

export class CRUDService {
  url = '';
  isSecure;

  constructor(url: string, isSecure = true) {
    this.url = url;
    this.isSecure = isSecure;
  }

  get(params?: {}) {
    const url = `${this.url}?${CommonUtility.objectToParams(params || {})}`;
    return BaseService.get(url, this.isSecure);
  }

  getById(id: string | number) {
    const url = `${this.url}/${id}`;
    return BaseService.get(url, this.isSecure);
  }

  add(data: {} | null) {
    return BaseService.post(this.url, data, this.isSecure);
  }

  update(id: string, data: {} | null) {
    let url = `${this.url}`;
    if (id) {
      url += `/${id}`;
    }
    return BaseService.put(url, data, this.isSecure);
  }

  remove(id: string) {
    const url = `${this.url}/${id}`;
    return BaseService.remove(url, this.isSecure);
  }
}
