import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';

import NavBar from 'src/common/NavBar';
import Footer from 'src/common/Footer';
import Payment from 'src/screens/Payment';
import PackSale from 'src/screens/PackSale';
import Dashboard from 'src/screens/Dashboard';
import ScrollToTop from 'src/common/ScrollToTop';
import EditProfile from 'src/screens/EditProfile';
import CardDetails from 'src/screens/CardDetails';
import LoadingSpinner from 'src/common/LoadingSpinner';
import ChangePassword from 'src/screens/ChangePassword';
import { BrandingContextHook } from 'src/context/branding';
import PaymentConfirmation from 'src/screens/PaymentConfirmation';
import Marketplace from 'src/screens/Marketplace';

function AppScreen() {
  const { data: branding } = BrandingContextHook();
  const [stripePromise, setStripePromise] = React.useState<Stripe | null>(null);

  React.useEffect(() => {
    if (branding?.stipePublishableKey) {
      const getStripePromise = async () => {
        const res = await loadStripe(branding?.stipePublishableKey);
        setStripePromise(res);
      };
      getStripePromise();
    }
  }, [branding]);

  return stripePromise ? (
    <Elements stripe={stripePromise}>
      <NavBar />
      <ScrollToTop>
        <Routes>
          <Route path='/' element={<Dashboard />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/change-password' element={<ChangePassword />} />
          <Route path='/my-profile' element={<EditProfile />} />
          <Route path='/payment' element={<Payment />} />
          <Route path='/marketplace' element={<Marketplace />} />
          <Route path='/pack-sale' element={<PackSale />} />
          <Route path='/card-details/:id' element={<CardDetails />} />
          <Route
            path='/payment-confirmation'
            element={<PaymentConfirmation />}
          />
          <Route path='*' element={<Dashboard />} />
        </Routes>
      </ScrollToTop>
      <Footer />
    </Elements>
  ) : (
    <LoadingSpinner />
  );
}

export default AppScreen;
