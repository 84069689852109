import React from 'react';
import { Spinner } from 'react-bootstrap';

const LoadingSpinner = () => {
  return (
    <div
      className='d-flex justify-content-center align-items-center position-fixed'
      style={{
        zIndex: 5,
        backgroundColor: 'rgba(0,0,0,0.5)',
        inset: 0,
      }}
    >
      <Spinner animation='border' variant='light' />
    </div>
  );
};

export default LoadingSpinner;
