import { APIPath } from 'src/utility/constants/api';
import { CRUDService } from 'src/utility/services/crud';

class Marketplace extends CRUDService {
  constructor() {
    super(APIPath.marketplace);
  }
}

const MarketplaceService = new Marketplace();
Object.freeze(MarketplaceService);
export { MarketplaceService };
