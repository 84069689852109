import { APIPath } from '../constants/api';
import { BaseService } from './base';
import { CRUDService } from './crud';

class PackTemplates extends CRUDService {
  constructor() {
    super(APIPath.packTemplates);
  }

  tax(id: string) {
    return BaseService.get(`${APIPath.packTemplates}/${id}/tax`);
  }
}

const PackTemplatesService = new PackTemplates();
Object.freeze(PackTemplatesService);
export { PackTemplatesService };
