import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Link, useLocation } from "react-router-dom";
import { Col, Form, FormGroup, Row } from "react-bootstrap";

import FormInput from "src/common/FormInput";
import CustomCard from "src/common/CustomCard";
import FormButton from "src/common/FormButton";
import LoadingSpinner from "src/common/LoadingSpinner";
import BackgroundImage from "src/common/BackgroundImage";
import { UserService } from "src/utility/services";
import { ErrorMessages } from "src/utility/constants/common";
import carBg from "src/assets/images/car-bg.jpg";

const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(4, "Password should be between 4 to 16 characters")
    .max(16, "Password should be between 4 to 16 characters")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Password must match")
    .required(),
});

const ResetPassword = () => {
  const [key, setKey] = React.useState("");
  const [keyError, setKeyError] = React.useState("");
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  let { search } = useLocation();

  React.useEffect(() => {
    const key = new URLSearchParams(search).get("token");
    if (key) {
      setKey(key);
    } else {
      setKeyError("Invalid Link, Please contact administrator.");
    }
  }, [search]);

  const onSubmit = async (data: { password: string }) => {
    try {
      setSuccess(false);
      setLoading(true);
      await UserService.resetPassword({
        key,
        newPassword: data.password,
      });
      setSuccess(true);
    } catch (err: any) {
      console.log(err);
      setError(err?.error?.detail || ErrorMessages.badCredentials);
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      {loading ? <LoadingSpinner /> : null}
      <BackgroundImage src={carBg} />
      <Row className="m-0 justify-content-center">
        <Col lg={6} md={8} sm={10} className="mt-5">
          <CustomCard header="RESET PASSWORD">
            {!success && !keyError ? (
              <Formik
                initialValues={{ password: "", confirmPassword: "" }}
                validationSchema={ResetPasswordSchema}
                onSubmit={(values) => {
                  onSubmit(values);
                }}
              >
                {({ errors, handleChange, handleSubmit }) => (
                  <Form onSubmit={handleSubmit}>
                    <FormGroup className="d-flex flex-column align-items-center">
                      <FormInput
                        type="password"
                        name="password"
                        placeholder="Password"
                        onChange={handleChange}
                        children=""
                        error={errors.password}
                      />
                      <FormInput
                        type="password"
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        onChange={handleChange}
                        children=""
                        error={errors.confirmPassword}
                      />
                      <FormButton
                        type="submit"
                        text={loading ? "Loading..." : "Confirm"}
                      />
                    </FormGroup>
                  </Form>
                )}
              </Formik>
            ) : null}
            {success ? (
              <p className="text-center mt-3 mb-0">
                Password Reset successfully. Please login again to access the
                application.
              </p>
            ) : null}
            {error ? (
              <p className="text-center mt-3 text-danger mb-0">{error}</p>
            ) : null}
            {keyError ? (
              <p className="text-center mt-3 text-danger mb-0">{keyError}</p>
            ) : null}
            <div className="d-flex justify-content-center">
              <Link to="/login" className="text-white mt-3">
                Back to Login
              </Link>
            </div>
          </CustomCard>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ResetPassword;
