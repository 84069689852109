import { Link, NavLink } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import React from "react";
import Heading from "src/common/Heading";
import { AuthHook } from "src/context/auth";
import CommonUtility from "src/utility/common";
import CustomCard from "src/common/CustomCard";
import FormButton from "src/common/FormButton";
import LoadingSpinner from "src/common/LoadingSpinner";
import { MarketplaceHook } from "src/hooks/marketplace";
import DefaultCard from "src/assets/images/default-card.png";
import { CardsType } from "src/hooks/cards";
import Filters from "src/common/Filters/Filters";
const filterButtons = ["All", "Video", "Image", "Illustration"];
const ItemCard = ({ item, isOwner }: { item: any; isOwner: boolean }) => (
  <Col lg={3} md={6} sm={6} className="mb-3">
    <CustomCard className="p-0 m-0 rounded border-gray h-100 sparknft">
      <div className="d-flex justify-content-between mb-2"></div>
      <Link to={`/app/card-details/${item.id}`}>
        <div className="card-img-container rounded">
          <img src={item.icon?.url || DefaultCard} alt={item?.name || "Card"} />
        </div>
      </Link>
      <div className="mt-3">
        <div className="mb-3">{item.name}</div>
        <div className="mb-3">
          #{item.groupSequence} of {item.maxExisting}
        </div>
        <Link
          to={
            isOwner
              ? `/app/card-details/${item.id}`
              : `/app/payment?id=${item.id}&type=card`
          }
        >
          <FormButton
            className="formSubmit"
            text={
              isOwner
                ? "Details"
                : `Buy @ ${CommonUtility.currencyFormat(item.sellPrice / 100)}`
            }
          />
        </Link>
        <div className="mt-3 txt-three-lines">{item.text}</div>
      </div>
    </CustomCard>
  </Col>
);

const Marketplace = () => {
  const { user } = AuthHook();
  const { data, loading, hasMore, pageChanged } = MarketplaceHook();
  const [activeButton, setActiveButton] = React.useState("all");
  const [filteredData, setFilteredData] = React.useState<CardsType[]>([]);
  React.useEffect(() => {
    if (data) {
      setFilteredData(data);
    }
  }, [data]);
  const FilterButtons = (text: string, index: number) => (
    <Col
      lg={2}
      md={2}
      sm={3}
      xs={6}
      className="mb1-small"
      key={`${text}-${index}`}
    >
      <FormButton
        text={text}
        className={`${activeButton === text.toLowerCase() ? "bgblue" : ""}`}
        onClick={() => {
          setActiveButton(text.toLowerCase());
          if (text.toLowerCase() === "all") {
            setFilteredData(data);
          } else {
            const cardFilter = data.filter(
              (item: any) => item.assetType.toLowerCase() === text.toLowerCase()
            );
            setFilteredData(cardFilter);
          }
        }}
      />
    </Col>
  );
  return (
    <div className="bgdark min-vh-100">
      {loading ? <LoadingSpinner /> : null}
      <Container className="h-100 py-5">
        <Row className="txt-white txt-medium align-items-center pb-5">
          <Col md={6} xs={12} className="responsive-left">
            <Heading
              type="main"
              text="Marketplace"
              color="white"
              className="txt-heading"
            />
          </Col>
          <Col lg={6} sm={6} xs={12} className="responsive-right">
            <NavLink to="/app/dashboard" className="txt-medium navbar-link">
              My Wallet
            </NavLink>
            <NavLink
              to="/app/pack-sale"
              className="txt-medium navbar-link fw-bold"
            >
              Pack Sale
            </NavLink>
            <NavLink
              to="/app/marketplace"
              className="txt-medium navbar-link fw-bold"
            >
              Marketplace
            </NavLink>
          </Col>
        </Row>
        <Row className="my-4 mb-5">
          {filterButtons.map((item, index) => FilterButtons(item, index))}
        </Row>
        <Row className="my-4 mb-5">
          <Filters
            setFilteredData={setFilteredData}
            allData={data}
            activeButton={activeButton}
          />
        </Row>
        {!loading && data && data.length > 0 ? (
          <Row>
            {filteredData?.map((item: any) => (
              <ItemCard
                key={item.id}
                item={item}
                isOwner={item.owner?.id === user?.id}
              />
            ))}
          </Row>
        ) : null}
         {hasMore && (
            <div className='row justify-content-center'>
              <div className='col-6 text-center my-4'>
                <FormButton text='Load More Data' onClick={pageChanged} />
              </div>
            </div>
          )}
        {!loading && data.length === 0 ? (
          <CustomCard header="No cards" bg="gray">
            <p className="text-center mb-0">No cards for selling</p>
          </CustomCard>
        ) : null}
      </Container>
    </div>
  );
};

export default Marketplace;
