import { BrowserRouter, Routes, Route } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.css";
import "./App.scss";

import About from "src/screens/About";
import Login from "src/screens/Login";
import SignUp from "src/screens/SignUp";
import TermsOfUse from "src/screens/TermsOfUse";
import PrivacyPolicy from "src/screens/PrivacyPolicy";
import ResetPassword from "src/screens/ResetPassword";
import ForgotPassword from "src/screens/ForgotPassword";
import RegisterActivation from "src/screens/RegisterActivation";
import { CardsProvider } from "src/context/cards";
import { BrandingProvider } from "src/context/branding";
import { AuthProvider, ProtectedRoute } from "src/context/auth";
import metadata from "./metadata.json";

function App() {
  return (
    <BrandingProvider>
      <AuthProvider>
        <CardsProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/about" element={<About />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/terms-of-service" element={<TermsOfUse />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route
                path="/register-activation"
                element={<RegisterActivation />}
              />
              <Route path="/app/*" element={<ProtectedRoute />} />
              <Route path="*" element={<ProtectedRoute />} />
            </Routes>
          </BrowserRouter>
        </CardsProvider>
      </AuthProvider>
    </BrandingProvider>
  );
}

export default App;
