import React, { PropsWithChildren } from "react";
import { createContext, useContext } from "react";
import { BrandingHook } from "src/hooks/user";

const BrandingContext = createContext({
  data: {
    stipePublishableKey: "",
    packOpeningAnimation: {
      url: "",
    },
  },
  loading: false,
});

interface BrandingProviderProps {
  children: any;
}

export const BrandingProvider: React.FC<
  PropsWithChildren<BrandingProviderProps>
> = ({ children }) => {
  const { data, loading } = BrandingHook();

  return (
    <BrandingContext.Provider value={{ data, loading }}>
      {children}
    </BrandingContext.Provider>
  );
};

export const BrandingContextHook = () => useContext(BrandingContext);
