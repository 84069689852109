import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Link, NavLink, useParams } from "react-router-dom";
import { Check, ChevronLeft, DollarSign, Share2 } from "react-feather";
import {
  Col,
  Row,
  Form,
  Image,
  Table,
  Container,
  FormGroup,
  Spinner,
} from "react-bootstrap";

import Heading from "src/common/Heading";
import DateUtility from "src/utility/date";
import { AuthHook } from "src/context/auth";
import CommonUtility from "src/utility/common";
import { CardsService, UserService } from "src/utility/services";
import { ErrorMessages, Roles } from "src/utility/constants/common";
import FormButton from "src/common/FormButton";
import MessagePopup from "src/common/MessagePopup";
import LoadingSpinner from "src/common/LoadingSpinner";
import DefaultCard from "src/assets/images/default-card.png";
import { CanSellHook, CardDetailsHook, CardHistoryHook } from "src/hooks/cards";
import FormInput from "src/common/FormInput";

const SellCardSchema = Yup.object().shape({
  price: Yup.number()
    .positive("Selling Price should be positive number")
    .required("Selling Price is required")
    .min(1, "Selling Price should be minimum $1"),
});

const CardDetails = () => {
  const { id } = useParams();
  const { user } = AuthHook();
  const { data: canSell } = CanSellHook();
  const { data, setData, loading } = CardDetailsHook(id);
  const { data: historyData, loading: historyLoading } = CardHistoryHook(id);
  const [copied, setCopied] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [showSellingModal, setShowSellingModal] = React.useState(false);
  const [sellConfirmation, setSellConfirmation] = React.useState(false);
  const [showBankModal, setShowBankModal] = React.useState(false);
  const [bankSetupLoading, setBankSetupLoading] = React.useState(false);
  const [bankSetupError, setBankSetupError] = React.useState("");

  const allowedSell = React.useMemo(
    () => (user?.authorities || []).includes(Roles.sellCard),
    [user]
  );
  const allowedMarketplace = React.useMemo(
    () => (user?.authorities || []).includes(Roles.marketplace),
    [user]
  );

  const sellCard = async (formData: { price: number }) => {
    try {
      if (id && data) {
        const price =
          Math.round((formData.price * 100 + Number.EPSILON) * 100) / 100;
        await CardsService.sell(id, price);
        setData({
          ...data,
          forSale: true,
          sellPrice: price,
        });
      }
      setShowSellingModal(false);
      setSellConfirmation(true);
    } catch (error) {
      console.log(error);
    }
  };

  const sellClicked = () => {
    if (canSell) {
      setShowSellingModal(true);
    } else {
      setShowBankModal(true);
    }
  };

  const cancelCard = async () => {
    try {
      if (id && data) {
        await CardsService.cancel(id);
        setShowModal(false);
        setData({
          ...data,
          forSale: false,
        });
      }
    } catch (error) {}
  };

  const initBankSetup = async () => {
    try {
      setShowBankModal(false);
      setBankSetupLoading(true);
      const result = await UserService.setupBank();
      window.location.href = result;
    } catch (error: any) {
      setBankSetupError(error?.error?.title || ErrorMessages.default);
    } finally {
      setBankSetupLoading(false);
    }
  };
  const isOwner = () => {
    if (data?.owner?.id === user?.id) return true;
    return false;
  };

  return (
    <React.Fragment>
      <div className="bgdark min-vh-100">
        {loading ? <LoadingSpinner /> : null}
        <Container className="h-100 py-5">
          <Row className="txt-white txt-medium align-items-center pb-5">
            <Col lg={6} sm={6} xs={12}>
              <Link
                to="/app/dashboard"
                className="d-flex align-items-center txt-medium navbar-link responsive-start"
              >
                <ChevronLeft className="txt-blue" />
                Back to SparkNFTs
              </Link>
            </Col>
            <Col lg={6} sm={6} xs={12} className="responsive-right">
              <NavLink to="/app/dashboard" className="txt-medium navbar-link">
                My Wallet
              </NavLink>
              <NavLink
                to="/app/pack-sale"
                className="txt-medium navbar-link fw-bold"
              >
                Pack Sale
              </NavLink>
              {allowedMarketplace ? (
                <NavLink
                  to="/app/marketplace"
                  className="txt-medium navbar-link"
                >
                  Marketplace
                </NavLink>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6}>
              {!loading && data?.id && (
                <>
                  {data?.video?.url ? (
                    <video controls autoPlay muted loop className="w-100">
                      <source src={data?.video.url} type="video/mp4" />
                    </video>
                  ) : (
                    <img
                      src={data?.attachments[0]?.url || DefaultCard}
                      alt={data?.name || "Card Image"}
                      width="100%"
                    />
                  )}
                </>
              )}
            </Col>
            <Col lg={6} md={6} className="txt-white">
              {!loading && data?.id && (
                <React.Fragment>
                  <div className="display-5 fw-bold mb-4 responsive-mt-4">
                    {data?.name}
                  </div>
                  <Image src={data?.logo?.url} width={240} className="pb-4" />
                  <div className="mb-4" style={{ whiteSpace: "pre-line" }}>
                    {data?.text}
                  </div>
                  <Heading
                    type="main"
                    text={`#${data?.groupSequence || 0} of ${
                      data?.maxExisting || 0
                    }`}
                    className="mb-4"
                  />
                  {!loading && data.forSale && data?.sellPrice ? (
                    <p>
                      Selling Price:{" "}
                      {CommonUtility.currencyFormat(data?.sellPrice / 100)}
                    </p>
                  ) : null}
                  <div className="d-flex">
                    {!loading && data.forSale && data?.sellPrice ? (
                      isOwner() && allowedMarketplace ? (
                        <FormButton
                          text="Cancel Sell"
                          className="w-auto formSubmit share me-2"
                          onClick={() => setShowModal(true)}
                        />
                      ) : (
                        <Link to={`/app/payment?id=${data.id}&type=card`}>
                          <FormButton
                            text="Buy"
                            className="w-auto formSubmit share me-2"
                            onClick={sellClicked}
                          />
                        </Link>
                      )
                    ) : null}
                    {allowedSell && !loading && !data?.forSale && isOwner() && (
                      <FormButton
                        text="SELL"
                        className="w-auto formSubmit share me-2"
                        onClick={sellClicked}
                      />
                    )}
                    <FormButton
                      text={copied ? "Copied!" : "SHARE"}
                      className="w-auto formSubmit share"
                      icon={
                        copied ? (
                          <Check className="me-2" />
                        ) : (
                          <Share2 className="me-2" />
                        )
                      }
                      onClick={() => {
                        navigator.clipboard.writeText(window.location.href);
                        setCopied(!copied);
                      }}
                    />
                  </div>
                </React.Fragment>
              )}
            </Col>
          </Row>
          <div className="bggray my-5 py-3">
            <Heading
              type="main"
              text="Cover Image"
              color="white"
              className="ps-5 m-0"
            />
          </div>
          <Row className="txt-white">
            <Col lg={3} md={4} sm={6}>
              {!loading && (
                <img src={data?.icon?.url} width="100%" alt={data?.name} />
              )}
            </Col>
            {/* <Col className='d-flex flex-column justify-content-center' style={{ whiteSpace: "pre-line" }}>
              {data?.text}
            </Col> */}
          </Row>

          {!historyLoading && historyData?.length > 0 && (
            <React.Fragment>
              <div className="bggray my-5 py-3">
                <Heading
                  type="main"
                  text="History"
                  color="white"
                  className="ps-5 m-0"
                />
              </div>
              <Table responsive borderless className="border-gray txt-white">
                <thead className="border-gray">
                  <tr>
                    <th>Date</th>
                    <th>Event</th>
                    <th>User</th>
                  </tr>
                </thead>
                <tbody>
                  {historyData
                    .map((item) => (
                      <tr key={item.id}>
                        <td>{DateUtility.formatDate(item.createdDate)}</td>
                        <td>{item.event}</td>
                        <td>{`${item.user?.userName || "Anonymous"}`}</td>
                      </tr>
                    ))
                    .reverse()}
                </tbody>
              </Table>
            </React.Fragment>
          )}
        </Container>
      </div>
      <MessagePopup show={showModal} title="Sell Card">
        <p>Are you sure you want to cancel sell this card?</p>
        <FormGroup className="d-flex">
          <FormButton text="Yes" className="me-2" onClick={cancelCard} />
          <FormButton text="No" onClick={() => setShowModal(false)} />
        </FormGroup>
      </MessagePopup>
      <MessagePopup show={showSellingModal} title="Sell Card">
        <Formik
          initialValues={{ price: 0 }}
          validationSchema={SellCardSchema}
          onSubmit={(values) => sellCard(values)}
        >
          {({ errors, handleChange, handleSubmit, values }) => (
            <Form onSubmit={handleSubmit}>
              <FormInput
                type="number"
                name="price"
                onChange={handleChange}
                error={errors.price}
              >
                <DollarSign />
              </FormInput>
              <FormGroup className="d-flex">
                <FormButton
                  type="submit"
                  text="Submit"
                  className="me-2 formSubmit"
                />
                <FormButton
                  text="Cancel"
                  onClick={() => setShowSellingModal(false)}
                />
              </FormGroup>
              <p className="text-center txt-medium text-danger mt-3 mb-0">
                {errors.price}
              </p>
            </Form>
          )}
        </Formik>
      </MessagePopup>
      <MessagePopup show={showBankModal} title="Bank Setup">
        <p className="text-center">Need to setup bank before sell card</p>
        <FormGroup className="d-flex">
          <FormButton
            text="Initiate Process"
            onClick={initBankSetup}
            className="me-2 text-nowrap"
          />
          <FormButton text="No" onClick={() => setShowBankModal(false)} />
        </FormGroup>
        <p className="text-center txt-medium text-danger mt-3 mb-0"></p>
      </MessagePopup>
      <MessagePopup show={bankSetupLoading}>
        <p className="text-center">Initailing Bank Setup, please wait...</p>
        <div className="d-flex justify-content-center">
          <Spinner animation="border" variant="light" />
        </div>
      </MessagePopup>
      <MessagePopup show={!!bankSetupError} title="Bank Setup">
        <p className="text-center">{bankSetupError}</p>
        <FormGroup className="d-flex">
          <FormButton text="Okay" onClick={() => setBankSetupError("")} />
        </FormGroup>
      </MessagePopup>
      <MessagePopup show={sellConfirmation} title="Sell Card">
        <p className="text-center">
          Card Successfully placed on the Marketplace
        </p>
        <FormGroup className="d-flex">
          <FormButton text="Okay" onClick={() => setSellConfirmation(false)} />
        </FormGroup>
      </MessagePopup>
    </React.Fragment>
  );
};

export default CardDetails;
