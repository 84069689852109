import React from "react";
import { Modal } from "react-bootstrap";
import CustomCard from "src/common/CustomCard";
import FormButton from "src/common/FormButton";

type SuccessModalType = {
  show?: boolean;
  title?: string;
  text?: string;
  btnText?: string;
  className?: string;
  onClick?: () => void;
  children: any;
};

const MessagePopup: React.FC<SuccessModalType> = ({
  show,
  title,
  text,
  btnText,
  onClick,
  className,
  children,
}) => {
  return (
    <Modal show={show} centered>
      <Modal.Dialog>
        <Modal.Body>
          <CustomCard header={title} className={className}>
            {text ? <p className="text-center">{text}</p> : null}
            {btnText ? (
              <FormButton text={btnText} onClick={onClick} className="bgblue" />
            ) : null}
            {children}
          </CustomCard>
        </Modal.Body>
      </Modal.Dialog>
    </Modal>
  );
};

export default MessagePopup;
