import { APIPath } from "src/utility/constants/api";
import { BaseService } from "src/utility/services/base";
import { CRUDService } from "src/utility/services/crud";

class Packs extends CRUDService {
  constructor() {
    super(APIPath.packs);
  }

  my() {
    return BaseService.get(APIPath.myPacks);
  }

  buy(
    id: string,
    reqData: {
      sendFacebookEvent: boolean;
      paymentMethodVM: {
        paymentMethodId: string;
        saveCard: any;
        isCrypto: boolean;
      } | null;
    } | null
  ) {
    return BaseService.post(`${APIPath.buyPack}/${id}`, reqData);
  }

  open(id: string | number) {
    return BaseService.get(`${APIPath.openPack}/${id}`);
  }
}

const PacksService = new Packs();
Object.freeze(PacksService);
export { PacksService };
