import { useEffect, useState } from "react";
import { PackTemplatesService } from "src/utility/services";
import { ErrorMessages } from "src/utility/constants/common";

export type PackTemplateType = {
  cardsQuantity: number;
  company: {};
  description: string;
  id: number;
  name: string;
  picture: { url: string; name: string };
  price: number;
  quantity: number;
  rarityClass: string;
  release: string;
  releaseDate: Date;
  soldCount: number;
};

export const PackTemplatesHook = () => {
  const [data, setData] = useState<PackTemplateType[] | []>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchData = async () => {
    try {
      setLoading(true);
      let result: any[] = await PackTemplatesService.get({
        page: 0,
        size: 99999,
      });
      result = result.filter((x: any) => (x.quantity || 0) !== -19);
      // const convert = (n: number) => {
      //   if (n == -21) return Math.max();
      //   if (n == 0) return -Math.max();
      //   return n;
      // }

      // result = result.sort((a, b) => {
      //   return convert(a.quantity) - convert(b.quantity);
      // })
      setData(result.reverse());
    } catch (error) {
      setError(ErrorMessages.default);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { data, loading, error };
};

export const PackTemplateDetailsHook = (id: string) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await PackTemplatesService.getById(id);

      setData(result);
    } catch (error) {
      setError(ErrorMessages.default);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return { data, loading, error };
};

export const PackTemplateTaxHook = (id: string) => {
  const [data, setData] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await PackTemplatesService.tax(id);
      setData(result);
    } catch (error) {
      setError(ErrorMessages.default);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return { data, loading, error };
};
