import CommonUtility from "src/utility/common";
import { APIPath } from "src/utility/constants/api";
import { BaseService } from "src/utility/services/base";
import { CRUDService } from "src/utility/services/crud";

class Cards extends CRUDService {
  constructor() {
    super(APIPath.cards);
  }

  
  my(filter: {}) {
    const params = CommonUtility.objectToParams(filter);
    return BaseService.get(`${APIPath.myCards}?${params}`);
  }

  sell(id: string, price: number) {
    return BaseService.put(`${APIPath.sellCard}/${id}/${price}`, null);
  }

  cancel(id: string) {
    return BaseService.put(`${APIPath.sellCard}/${id}/cancel`, null);
  }

  buy(
    id: string,
    reqData: {
      paymentMethodId: string;
      saveCard: any;
      isCrypto: boolean;
    } | null
  ) {
    return BaseService.post(`${APIPath.buyCard}/${id}`, reqData);
  }

  history(id: string) {
    return BaseService.get(`${APIPath.cardHistory}/${id}`);
  }

  canSell() {
    return BaseService.get(APIPath.canSell);
  }
}

const CardsService = new Cards();
Object.freeze(CardsService);
export { CardsService };
