import React, { PropsWithChildren } from "react";

import { CardsType } from "src/hooks/cards";
import { CommonConstant, ErrorMessages } from "src/utility/constants/common";
import { CardsService } from "src/utility/services";

const CardsContext = React.createContext({
  data: [] as CardsType[],
  loading: false,
  error: "",
  hasMore: true,
  pageChanged: () => {},
  fetchData: (_fetchFilter: FilterType) => {},
  addCard: (_id: number) => {},
});

interface FilterType {
  page: number;
  size: number;
}

interface CardProps {
  children: any;
}

export const CardsProvider: React.FC<PropsWithChildren<CardProps>> = ({
  children,
}) => {
  const [data, setData] = React.useState<CardsType[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [hasMore, setHasMore] = React.useState(true);
  const [filter, setFilter] = React.useState<FilterType>({
    page: 0,
    size: CommonConstant.defaultPageSize,
  });

  const fetchData = async (fetchFilter: FilterType) => {
    try {
      setLoading(true);
      const result = await CardsService.my(fetchFilter);
      const newEntries = result.filter(
        (item: any) => !data.some((element) => item.id === element.id)
      );
      setData(data.concat(newEntries || []));
      setHasMore((result || []).length === fetchFilter.size);
    } catch (error) {
      setError(ErrorMessages.default);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  };

  const addCard = async (id: number) => {
    try {
      setLoading(true);
      const result = await CardsService.getById(id);
      const temp = JSON.parse(JSON.stringify(data));
      temp.push(result);
      setData(temp);
    } catch (error) {
      setError(ErrorMessages.default);
    } finally {
      setLoading(false);
    }
  };

  const pageChanged = () => {
    const temp = {
      ...filter,
      page: filter.page + 1,
    };
    setFilter({ ...temp });
    fetchData({ ...temp });
  };

  return (
    <CardsContext.Provider
      value={{ data, loading, error, hasMore, pageChanged, addCard, fetchData }}
    >
      {children}
    </CardsContext.Provider>
  );
};

interface CardsContextType {
  data: CardsType[];
  loading: boolean;
  error: string;
  hasMore: boolean;
  pageChanged: () => void;
  fetchData: (fetchFilter: FilterType) => void;
  addCard: (id: number) => void;
}

export const CardsContextHook = () =>
  React.useContext<CardsContextType>(CardsContext);
