import React from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { ArrowRight, Image, PenTool, Video } from 'react-feather';
import { Link } from 'react-router-dom';

import CustomCard from 'src/common/CustomCard';
import FormButton from 'src/common/FormButton';
import { CardsType } from 'src/hooks/cards';
import { CardsContextHook } from 'src/context/cards';
import { CommonConstant } from 'src/utility/constants/common';
import DefaultCard from 'src/assets/images/default-card.png';

const filterButtons = ['All', 'Video', 'Image', 'Illustration'];

const CardThumbnail = ({ item }: { item: CardsType }) => (
  <Col lg={3} md={6} sm={6} className='mb-3'>
    <CustomCard className='p-0 m-0 rounded border-gray h-100 sparknft'>
      <div className='d-flex justify-content-between mb-2'>
        <span>
          #{item.groupSequence} of {item.maxExisting}
        </span>
        {item.assetType === 'IMAGE' ? (
          <Image />
        ) : item.assetType === 'VIDEO' ? (
          <Video />
        ) : (
          <PenTool />
        )}
      </div>
      <div
        className='card-img-container rounded'

      >
        <img src={item.icon?.url || DefaultCard} alt={item?.name || 'Card'} />
      </div>
      <div className='mt-3'>
        <div className='mb-3'>{item.name}</div>
        <Link to={`/app/card-details/${item.id}`}>
          Details <ArrowRight />
        </Link>
      </div>
    </CustomCard>
  </Col>
);

const Spark = () => {
  const [activeButton, setActiveButton] = React.useState('all');
  const [filteredData, setFilteredData] = React.useState<CardsType[]>([]);
  const { data, loading, hasMore, fetchData, pageChanged } = CardsContextHook();

  React.useEffect(() => {
    fetchData({ page: 0, size: CommonConstant.defaultPageSize });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (data) {
      setFilteredData(data);
    }
  }, [data]);

  const FilterButtons = (text: string, index: number) => (
    <Col
      lg={2}
      md={2}
      sm={3}
      xs={6}
      className='mb1-small'
      key={`${text}-${index}`}
    >
      <FormButton
        text={text}
        className={`${activeButton === text.toLowerCase() ? 'bgblue' : ''}`}
        onClick={() => {
          setActiveButton(text.toLowerCase());
          if (text.toLowerCase() === 'all') {
            setFilteredData(data);
          } else {
            const cardFilter = data.filter(
              (item) => item.assetType.toLowerCase() === text.toLowerCase()
            );
            setFilteredData(cardFilter);
          }
        }}
      />
    </Col>
  );

  return (
    <React.Fragment>
      {loading ? (
        <div className='d-flex justify-content-center'>
          <Spinner animation='border' variant='light' />
        </div>
      ) : null}
      {data?.length > 0 && !loading ? (
        <React.Fragment>
          <Row className='my-4 mb-5'>
            {filterButtons.map((item, index) => FilterButtons(item, index))}
          </Row>
          <Row>
            {filteredData.map((item) => (
              <CardThumbnail item={item} key={item.id} />
            ))}
          </Row>
          {hasMore && (
            <div className='row justify-content-center'>
              <div className='col-6 text-center my-4'>
                <FormButton text='Load More Data' onClick={pageChanged} />
              </div>
            </div>
          )}
        </React.Fragment>
      ) : null}
      {filteredData?.length === 0 && !loading ? (
        <CustomCard header='No cards' bg='gray'>
          <p className='text-center mb-0'>
            Buy packs from{' '}
            <Link to='/app/pack-sale' className='navbar-link p-0 fw-bold'>
              Packsales.
            </Link>
          </p>
        </CustomCard>
      ) : null}
    </React.Fragment>
  );
};

export default Spark;
