export const CommonConstant = {
  user: 'user',
  token: 'token',
  defaultPageSize: 25,
  phoneRegExp:
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
};

export const Roles = {
  marketplace: 'R_MARKET',
  sellCard: 'R_SELL_CARD',
  cashout: 'R_CASH_OUT',
};

export const ErrorMessages = {
  default: 'Something went wrong, please contact administrator',
  badCredentials: 'Invalid username and/or password',
};

export const TransactionStatus = {
  success: 'SUCCESS',
  fail: 'FAIL',
  pending: 'PENDING',
  crypto_waiting: 'WAITING_FOR_CRYPTO_CHARGE_CONFIRMATION',
};

export const BaseURL = `${window.location.protocol}//dsyfyh2jshkqp.cloudfront.net/api/`;

export const REACT_APP_GOOGLE_CLIENT_ID =
  '595383922322-3ap0i6jts80e9rt8a4tn8e2s41n4e9s8.apps.googleusercontent.com';
