import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import { Col, Form, FormGroup, Row } from "react-bootstrap";

import FormInput from "src/common/FormInput";
import CustomCard from "src/common/CustomCard";
import FormButton from "src/common/FormButton";
import LoadingSpinner from "src/common/LoadingSpinner";
import BackgroundImage from "src/common/BackgroundImage";
import { UserService } from "src/utility/services";
import { ErrorMessages } from "src/utility/constants/common";
import carBg from "src/assets/images/car-bg.jpg";

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email("Email Address is Incomplete").required("Required"),
});

const ForgotPassword = () => {
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const onSubmit = async (data: { email: string }) => {
    try {
      setLoading(true);
      setSuccess(false);
      await UserService.forgotPassword({ email: data?.email });
      setSuccess(true);
    } catch (err: any) {
      setError(err?.error?.detail || ErrorMessages.badCredentials);
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      {loading ? <LoadingSpinner /> : null}
      <BackgroundImage src={carBg} />
      <Row className="m-0 justify-content-center">
        <Col lg={6} md={8} sm={10} className="mt-5">
          <CustomCard header="FORGOT PASSWORD">
            {!success ? (
              <Formik
                initialValues={{ email: "" }}
                validationSchema={ForgotPasswordSchema}
                onSubmit={(values) => {
                  onSubmit(values);
                }}
              >
                {({ errors, handleChange, handleSubmit }) => (
                  <Form onSubmit={handleSubmit}>
                    <FormGroup className="d-flex flex-column align-items-center">
                      <FormInput
                        type="email"
                        name="email"
                        placeholder="Email Address"
                        onChange={handleChange}
                        error={errors.email}
                        children=""
                      />
                      <FormButton
                        type="submit"
                        text={loading ? "Loading..." : "Send Email"}
                      />
                    </FormGroup>
                  </Form>
                )}
              </Formik>
            ) : (
              <p className="text-center mt-3 mb-0">
                Email has been sent to your email address, please check your
                inbox to reset your password.
              </p>
            )}
            {error ? (
              <p className="text-center mt-3 text-danger mb-0">{error}</p>
            ) : null}
            <div className="d-flex justify-content-center">
              <Link to="/login" className="text-white mt-3">
                Back to Login
              </Link>
            </div>
          </CustomCard>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ForgotPassword;
