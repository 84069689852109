import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { ChevronLeft } from "react-feather";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Col, Container, Form, FormGroup, Row } from "react-bootstrap";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import CoinbaseCommerceButton from "react-coinbase-commerce";
import Heading from "src/common/Heading";
import FormInput from "src/common/FormInput";
import CommonUtility from "src/utility/common";
import CustomCard from "src/common/CustomCard";
import FormButton from "src/common/FormButton";
import MessagePopup from "src/common/MessagePopup";
import LoadingSpinner from "src/common/LoadingSpinner";
import { PackTemplateTaxHook } from "src/hooks/pack-templates";
import { CardsService, PacksService } from "src/utility/services";
import { ErrorMessages, TransactionStatus } from "src/utility/constants/common";
import {
  CryptoChargeHook,
  DetailsHook,
  SavedCardHook,
} from "src/hooks/payment";

import { LatestHistoryHook, HistoryByIdHook } from "src/hooks/user";
import { ReactComponent as Visa } from "src/assets/icons/visa.svg";
import { ReactComponent as Discover } from "src/assets/icons/discover.svg";
import { ReactComponent as MasterCard } from "src/assets/icons/mastercard.svg";
import { BrandingHook } from "src/hooks/user";

const useOptions = () => {
  const fontSize = "1.125rem";
  const options = React.useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#ffffff",
          letterSpacing: "0.025em",
          "::placeholder": {
            color: "#afb3bf",
          },
        },
        invalid: {
          color: "#dc3545",
        },
      },
    }),
    [fontSize]
  );

  return options;
};

const paymentSchema = Yup.object().shape({
  name: Yup.string().required("Card Holder Name is required"),
  saveCard: Yup.boolean(),
});

const Payment = () => {
  const [processing, setProcessing] = React.useState(false);
  const [error, setError] = React.useState("");
  const [paymentType, setPaymentType] = React.useState("");
  const [type, setType] = React.useState("");
  const [id, setId] = React.useState("");
  const [transInProgress, setTransInProgress] = React.useState(false);
  const [showForm, setShowForm] = React.useState(false);
  const [chargeId, setChargeId] = React.useState("");
  const [transactionId, setTransactionId] = React.useState(false);

  const packId = React.useMemo(() => (type === "pack" ? id : ""), [type, id]);
  const { data: details } = DetailsHook(id, type);
  const { data: charge } = CryptoChargeHook(chargeId);
  const { data: card, loading: cardLoading } = SavedCardHook();
  const {
    data: transaction,
    error: transactionError,
    refreshData,
  } = HistoryByIdHook(transactionId);

  const { data: finalAmount, loading: taxLoading } =
    PackTemplateTaxHook(packId);
  const { data: branding } = BrandingHook();
  const stripe = useStripe();
  const options = useOptions();
  const elements = useElements();
  const navigate = useNavigate();
  let { search } = useLocation();

  React.useEffect(() => {
    if (!cardLoading) {
      setShowForm(!card?.last4);
    }
  }, [card, cardLoading]);

  React.useEffect(() => {
    const id = new URLSearchParams(search).get("id");
    if (id) {
      setId(id);
    }
    const type = new URLSearchParams(search).get("type");
    if (type) {
      setType(type);
    }
  }, [search]);
  const claimPack = async (id: any) => {
    try {
      await PacksService.open(id);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    const fetch = async () => {
      switch (transaction?.transactionStatus) {
        case TransactionStatus.crypto_waiting:
          setChargeId(transaction?.cryptoChargeCode);
          break;
        case TransactionStatus.success:
          if (paymentType === "card") {
            setTransInProgress(false);
            navigate(`/app/payment-confirmation?id=${id}&type=${type}`);
          } else {
            setTransInProgress(false);
            await claimPack(transaction?.pack?.id);
            navigate(`/app/payment-confirmation?id=${id}&type=${type}`);
          }
          break;
        case TransactionStatus.success:
          setTransInProgress(false);
          await claimPack(transaction?.pack?.id);
          onTransactionComplete();
          break;

        case TransactionStatus.fail:
          setTransInProgress(false);
          setError(transaction?.notes);
          break;

        default:
          break;
      }
    };
    fetch();
  }, [transaction]);

  React.useEffect(() => {
    if (chargeId) {
      setTimeout(() => {
        let button: HTMLElement = document.getElementsByClassName(
          "coinbase-button"
        )[0] as HTMLElement;
        if (button) {
          button.click();
        }
      }, 500);
    }
  }, [chargeId]);
  React.useEffect(() => {
    if (!transInProgress) return;
    const interval = setInterval(() => {
      refreshData();
    }, 3000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionId]);

  const buy = async (formData: { name: string; saveCard: boolean }) => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    setProcessing(true);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement)!,
      billing_details: {
        name: formData.name,
      },
    });

    if (error?.message) {
      setProcessing(false);
      setError(error.message);
    } else {
      try {
        let result;
        setPaymentType("card");
        if (type === "pack" && paymentMethod?.id) {
          result = await PacksService.buy(id, {
            sendFacebookEvent: false,
            paymentMethodVM: {
              paymentMethodId: paymentMethod.id,
              saveCard: formData.saveCard,
              isCrypto: false,
            },
          });
        } else if (type === "card" && paymentMethod?.id) {
          result = await CardsService.buy(id, {
            paymentMethodId: paymentMethod.id,
            saveCard: formData.saveCard,
            isCrypto: false,
          });
        }
        setTransInProgress(true);
        setTransactionId(result?.id);
      } catch (error: any) {
        setError(error?.error?.title || ErrorMessages.default);
      } finally {
        setProcessing(false);
      }
    }
  };

  const buyUsingSavedCard = async () => {
    try {
      setProcessing(true);
      let result;
      setPaymentType("card");
      if (type === "pack") {
        result = await PacksService.buy(id, {
          sendFacebookEvent: false,
          paymentMethodVM: null,
        });
      } else if (type === "card") {
        result = await CardsService.buy(id, null);
      }
      setTransInProgress(true);
      setTransactionId(result?.id);
    } catch (error: any) {
      setError(error?.error?.title || ErrorMessages.default);
    } finally {
      setProcessing(false);
    }
  };
  const buyUsingCrypto = async () => {
    try {
      setProcessing(true);
      setTransInProgress(true);
      let result;
      if (type === "pack") {
        result = await PacksService.buy(id, {
          sendFacebookEvent: false,
          paymentMethodVM: {
            paymentMethodId: "",
            saveCard: "",
            isCrypto: true,
          },
        });
      } else if (type === "card") {
        result = await CardsService.buy(id, {
          paymentMethodId: "",
          saveCard: "",
          isCrypto: true,
        });
      }
      setTransactionId(result?.id);
      setTransInProgress(true);
    } catch (error: any) {
      setError(error?.error?.title || ErrorMessages.default);
    } finally {
      setProcessing(false);
    }
  };
  const coinBaseError = (error: any) => {
    setError(error);
  };

  const onTransactionComplete = async () => {
    navigate(`/app/payment-confirmation?id=${id}&type=${type}`);
  };

  const onCloseCoinBase = () => {
    navigate(`/app/payment?id=${id}&type=${type}`);
  };

  return (
    <React.Fragment>
      {processing ? <LoadingSpinner /> : null}
      {transInProgress ? <LoadingSpinner /> : null}
      <MessagePopup
        show={!!error}
        title="Payment"
        text={error}
        btnText="Okay"
        children=""
        onClick={() => {
          setError("");
        }}
      />
      <div className="bgdark min-vh-100">
        <Container className="h-100 py-5">
          <Row className="txt-white txt-medium align-items-center pb-5">
            <Col lg={6} sm={6} xs={12} className="responsive-show">
              <Link
                to="/app/pack-sale"
                className="d-flex align-items-center txt-medium navbar-link"
              >
                <ChevronLeft className="txt-blue" />
                Back to Pack Sale
              </Link>
            </Col>
            <Col lg={6} sm={6} xs={12} className="responsive-left">
              <Heading
                type="main"
                text="Payment"
                color="white"
                className="txt-heading"
              />
            </Col>
            <Col lg={6} sm={6} xs={12} className="responsive-hide">
              <Link
                to="/app/pack-sale"
                className="d-flex align-items-center txt-medium navbar-link"
              >
                <ChevronLeft className="txt-blue" />
                Back to Pack Sale
              </Link>
            </Col>
          </Row>
          <Row className="justify-content-center align-items-center m-0">
            <Col lg={5} md={8}>
              <CustomCard bg="gray">
                {type === "pack" && details ? (
                  <div>
                    <Heading
                      type="subheading"
                      text={details?.name}
                      className="m-0 mb-2"
                    />
                    <div className="d-flex justify-content-between">
                      <span>Pack Price:</span>
                      <span>
                        {CommonUtility.currencyFormat(details.price / 100)}
                      </span>
                    </div>
                    <div className="d-flex justify-content-between">
                      <span>Tax:</span>
                      <span>
                        {taxLoading
                          ? "Loading"
                          : CommonUtility.currencyFormat(
                              (finalAmount - details.price) / 100
                            )}
                      </span>
                    </div>
                    <div className="d-flex justify-content-between">
                      <span>Total:</span>
                      <span>
                        {CommonUtility.currencyFormat((finalAmount || 0) / 100)}
                      </span>
                    </div>
                    <hr />
                  </div>
                ) : null}

                <div className="d-flex align-items-center ms-3 my-4">
                  <Heading
                    type="subheading"
                    text="Select Method"
                    className="m-0"
                  />
                </div>
                {branding?.displayCryptoPay === false ? null : (
                  <>
                    {type === "pack" && (
                      <>
                        <FormButton
                          type="button"
                          text="Pay using crypto"
                          className="formSubmit mb-2"
                          onClick={buyUsingCrypto}
                        />
                        <p className="align-items-center justify-content-center d-flex">
                          OR
                        </p>
                      </>
                    )}
                  </>
                )}

                <FormGroup className="d-flex w-100">
                  <div className="formInput bgwhite d-flex justify-content-center me-3">
                    <Visa width="80%" height="100%" />
                  </div>
                  <div className="formInput bgwhite d-flex justify-content-center me-3">
                    <MasterCard width="100%" height="100%" />
                  </div>
                  <div className="formInput bgwhite d-flex justify-content-center">
                    <Discover width="80%" height="100%" />
                  </div>
                </FormGroup>
                {!cardLoading && card?.last4 ? (
                  <React.Fragment>
                    <div className="d-flex align-items-center ms-3 my-4">
                      <Heading
                        type="subheading"
                        text="Use Existing Card"
                        className="m-0"
                      />
                    </div>
                    <FormGroup className="d-flex flex-column mt-2">
                      <FormButton
                        type="button"
                        text={
                          processing
                            ? "Processing..."
                            : `Use Saved Card ${card.last4}(${card.expMonth}/${card.expYear})`
                        }
                        className="formSubmit mb-2"
                        onClick={buyUsingSavedCard}
                      />
                      <FormButton
                        type="button"
                        text="Add New Card"
                        className="formSubmit"
                        onClick={() => setShowForm(true)}
                      />
                    </FormGroup>
                  </React.Fragment>
                ) : null}

                {showForm ? (
                  <React.Fragment>
                    <div className="d-flex align-items-center ms-3 my-4">
                      <Heading
                        type="subheading"
                        text="Payment Details"
                        className="m-0"
                      />
                    </div>
                    <Formik
                      initialValues={{ name: "", saveCard: false }}
                      validationSchema={paymentSchema}
                      onSubmit={(values) => {
                        buy(values);
                      }}
                    >
                      {({ errors, handleChange, handleSubmit }) => (
                        <Form onSubmit={handleSubmit}>
                          <FormGroup>
                            <CardNumberElement
                              className="textfield-1 mb-2"
                              options={options}
                            />
                            <CardExpiryElement
                              className="textfield-1 mb-2"
                              options={options}
                            />
                            <CardCvcElement
                              className="textfield-1 mb-2"
                              options={options}
                            />
                          </FormGroup>
                          <FormGroup>
                            <FormInput
                              type="text"
                              name="name"
                              placeholder="Card Holder Name"
                              border
                              required
                              onChange={handleChange}
                              error={errors.name}
                              children=""
                            />
                          </FormGroup>
                          <Form.Group
                            className="mb-3 ms-3"
                            controlId="formBasicCheckbox"
                          >
                            <Form.Check
                              type="checkbox"
                              name="saveCard"
                              label="Save card for future payment"
                              onChange={handleChange}
                            />
                          </Form.Group>
                          <FormGroup className="d-flex justify-content-center">
                            <FormButton
                              type="submit"
                              text={
                                processing || transInProgress
                                  ? "Processing...."
                                  : "NEXT"
                              }
                              disabled={processing || transInProgress}
                            />
                          </FormGroup>
                          {error ? (
                            <p className="text-center txt-medium text-danger mt-3 mb-0">
                              {error}
                            </p>
                          ) : null}
                        </Form>
                      )}
                    </Formik>
                  </React.Fragment>
                ) : null}
              </CustomCard>
            </Col>
          </Row>
        </Container>
        <CoinbaseCommerceButton
          styled={false}
          chargeId={chargeId}
          onChargeSuccess={(e: any) => onTransactionComplete()}
          onChargeFailure={(e: any) => coinBaseError(e.code)}
          onModalClosed={() => onCloseCoinBase()}
          className="coinbase-button"
        >
          Complete Crypto Transaction
        </CoinbaseCommerceButton>
      </div>
    </React.Fragment>
  );
};

export default Payment;
