import React from 'react';
import { Link } from 'react-router-dom';
import { Form, FormCheck } from 'react-bootstrap';

import 'src/common/styles/common.scss';

type TermsCheckboxType = {
  type: 'terms' | 'remember';
  name?: string;
  error?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
};

const TermsCheckbox: React.FC<TermsCheckboxType> = ({
  type,
  name,
  error,
  onChange,
}) => {
  if (type === 'terms') {
    return (
      <Form.Group
        className='mb-3 d-flex justify-content-center'
        controlId='formBasicCheckbox'
      >
        <FormCheck>
          <Form.Check.Input
            type='checkbox'
            name={name}
            onChange={onChange}
            required
            isInvalid={!!error}
          />
          <Form.Check.Label>
            By checking here and continuing, I agree to the{' '}
            <a href='https://motoclub.io/terms-of-service/' target="_blank" className='redirectBlue'>
              Terms of Use
            </a>
          </Form.Check.Label>
        </FormCheck>
      </Form.Group>
    );
  }
  return (
    <Form.Group className='mb-3 ms-3' controlId='formBasicCheckbox'>
      <Form.Check
        type='checkbox'
        name={name}
        label='Remember Me'
        onChange={onChange}
      />
    </Form.Group>
  );
};

export default TermsCheckbox;
