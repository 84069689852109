import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import 'src/common/styles/common.scss';

const SIGNUP_LABELS = {
  text: 'Already have an account?',
  link: '/login',
  button: 'Sign in',
};
const SIGNIN_LABELS = {
  text: 'Don’t have an account yet?',
  link: '/signup',
  button: 'Sign up now',
};

type CardRedirectTextType = {
  type: 'signup' | 'signin';
};

const CardRedirectText: React.FC<CardRedirectTextType> = ({ type }) => {
  const labels = React.useMemo(() => {
    return type === 'signup' ? SIGNUP_LABELS : SIGNIN_LABELS;
  }, [type]);

  return (
    <Card.Text className='d-flex justify-content-center mt-3'>
      <span className='me-2 customCard-footerTxt'>{labels.text}</span>
      <Link to={labels.link} className='redirectWhite customCard-footerTxt'>
        <span>{labels.button}</span>
      </Link>
    </Card.Text>
  );
};

export default CardRedirectText;
