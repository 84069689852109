import React, { PropsWithChildren } from "react";
import { Card } from "react-bootstrap";

import CardRedirectText from "src/common/CardRedirectText";
import Heading from "src/common/Heading";

import "src/common/CustomCard/style.scss";

type CustomCardType = {
  header?: string;
  type?: "signup" | "signin";
  bg?: "dark" | "gray";
  className?: string;
};

interface CustomCardType2 {
  children: any;
  header?: string;
  type?: "signup" | "signin";
  bg?: "dark" | "gray";
  className?: string;
}

const CustomCard: React.FC<PropsWithChildren<CustomCardType2>> = ({
  className,
  children,
  header,
  type,
  bg,
}) => {
  return (
    <Card
      className={`customCard ${bg ? `bg${bg}` : "bgdark"} ${
        className ? className : ""
      }`}
    >
      {header ? <Heading type="main" text={header} center /> : null}
      <Card.Body>
        {children}
        {type ? <CardRedirectText type={type} /> : null}
      </Card.Body>
    </Card>
  );
};

export default CustomCard;
