import React, { useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { AuthHook } from "src/context/auth";
import FormButton from "src/common/FormButton";
import Dropdown from "react-bootstrap/Dropdown";
import ProfileDropdown from "src/common/ProfileDropdown";
import { ReactComponent as MotoclubLogo } from "src/assets/images/motoclub-new-logo.svg";
import { ReactSVG } from "react-svg";
import caret from "../../assets/icons/caret.svg";
import "src/common/styles/common.scss";
import { UserService } from "src/utility/services";
import { useGoogleLogout } from "react-google-login";
import { REACT_APP_GOOGLE_CLIENT_ID } from "src/utility/constants/common";

const navLinks = [
  {
    text: "HOME",
    isExternal: true,
    link: "https://motoclub.io/",
  },
  {
    text: "DROPS",
    isExternal: true,
    link: "https://motoclub.io/web-6/drops-page/",
  },
  {
    text: "ABOUT",
    isExternal: true,
    link: "https://motoclub.io/web-6/about/",
  },
  {
    text: "NEWS",
    isExternal: true,
    link: "https://motoclub.io/web-6/news/",
  },
  {
    text: "SUPPORT",
    isExternal: true,
    link: "https://motoclub.io/support-faq-tab/support-customer-support-tab/",
  },
];

const NavBar = () => {
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);
  const { isAuthenticated, logout: logoutContext, user } = AuthHook();
  const mobileLinks = [
    {
      text: "MY PROFILE",
      link: "/app/my-profile",
      isVisible: true,
    },
    {
      text: "CHANGE PASSWORD",
      link: "/app/change-password",
      isVisible: user?.hasPassword,
    },
  ];

  const { signOut } = useGoogleLogout({
    jsSrc: "https://apis.google.com/js/api.js",
    clientId: REACT_APP_GOOGLE_CLIENT_ID,
    cookiePolicy: "single_host_origin",
  });

  const logoutClick = () => {
    UserService.logout();
    logoutContext();
    signOut();
    navigate("/login");
  };

  const NavLinks = () =>
    navLinks && navLinks.length > 0 ? (
      <React.Fragment>
        <a
          href={"https://motoclub.io/"}
          target={"_blank"}
          className="navbar-link txt-submit nav-link-item"
          rel="noreferrer"
        >
          HOME
        </a>
        <Dropdown
          onMouseLeave={() => setShowDropdown(false)}
          onMouseOver={() => setShowDropdown(true)}
        >
          <Dropdown.Toggle
            className="navbar-link nav-link-item txt-submit"
            id="dropdown-button-dark-example1"
            variant="secondary"
          >
            Drops
            <ReactSVG className="header_drop" src={caret} />
          </Dropdown.Toggle>
          <Dropdown.Menu variant="dark" show={showDropdown}>
            <Dropdown.Item href={"https://motoclub.io/barret-jackson/"} active>
              BARRETT-JACKSON
            </Dropdown.Item>
            <Dropdown.Item href={"https://motoclub.io/creative-bespoke/"}>
              CREATIVE BESPOKE
            </Dropdown.Item>
            <Dropdown.Item
              href={
                "https://motoclub.io/international-motorsports-hall-of-fame/"
              }
            >
              INTERNATIONAL MOTORSPORTS HALL OF FAME
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <a
          href={"https://motoclub.io/web-6/about/"}
          target={"_blank"}
          className="navbar-link nav-link-item txt-submit"
          rel="noreferrer"
        >
          ABOUT
        </a>
        <a
          href={"https://motoclub.io/web-6/news/"}
          target={"_blank"}
          className="navbar-link nav-link-item txt-submit"
          rel="noreferrer"
        >
          NEWS
        </a>
        <a
          href={
            "https://motoclub.io/support-faq-tab/support-customer-support-tab/"
          }
          target={"_blank"}
          className="navbar-link nav-link-item txt-submit"
          rel="noreferrer"
        >
          SUPPORT
        </a>

        <div className="flex-column align-items-center responsive-show-nav">
          {mobileLinks.map((item) => (
            <>
              {item?.isVisible && (
                <NavLink
                  key={item.text}
                  to={item.link}
                  className="navbar-link txt-submit"
                >
                  {item.text}
                </NavLink>
              )}
            </>
          ))}
          <div
            key="logout"
            className="navbar-link txt-submit"
            onClick={logoutClick}
          >
            LOGOUT
          </div>
        </div>
      </React.Fragment>
    ) : null;

  return (
    <Navbar expand="md" variant="dark">
      <Container>
        <Navbar.Brand>
          <Link to="/">
            <MotoclubLogo />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto text-white align-items-center">
            <NavLinks />
          </Nav>
          {isAuthenticated ? (
            <ProfileDropdown />
          ) : (
            <Link to="/login">
              <FormButton text="Login" className="w-auto px-5 formSubmit" />
            </Link>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
