import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';

import Heading from 'src/common/Heading';
import { AuthHook } from 'src/context/auth';
import { Roles } from 'src/utility/constants/common';
import Spark from 'src/screens/Dashboard/Tabs/Spark';
import Unopened from 'src/screens/Dashboard/Tabs/Unopened';
import { ReactComponent as Lightning } from 'src/assets/icons/lightning.svg';

const Dashboard = () => {
  const [activeTab, setActiveTab] = React.useState('spark');
  const { user } = AuthHook();
  let { search } = useLocation();

  const allowedMarketplace = React.useMemo(
    () => (user?.authorities || []).includes(Roles.marketplace),
    [user]
  );

  React.useEffect(() => {
    const tab = new URLSearchParams(search).get('tab');
    if (tab) {
      setActiveTab(tab);
    }
  }, [search]);

  return (
    <div className='bgdark min-vh-100'>
      <Container className='h-100 py-5'>
        <Row className='txt-white txt-medium align-items-center pb-5'>
          <Col lg={6} sm={6} xs={12} className='responsive-left'>
            <Heading
              type='main'
              text={activeTab === 'spark' ? 'My SparkNFTs' : 'Pack Sale'}
              color='white'
              className='txt-heading'
            />
          </Col>
          <Col lg={6} sm={6} xs={12} className='responsive-right'>
            <NavLink
              to='/app/dashboard'
              className='txt-medium navbar-link fw-bold'
            >
              My Wallet
            </NavLink>
            <NavLink to='/app/pack-sale' className='txt-medium navbar-link'>
              Pack Sale
            </NavLink>
            {allowedMarketplace ? (
              <NavLink to='/app/marketplace' className='txt-medium navbar-link'>
                Marketplace
              </NavLink>
            ) : null}
          </Col>
        </Row>
        {/* <hr className='txt-white m-0' /> */}
        <Tabs
          activeKey={activeTab}
          onSelect={(k) => {
            if (k) {
              setActiveTab(k);
            }
          }}
          id='tabs'
          className='mb-3 txt-medium custum-tabs'
        >
          <Tab
            eventKey='spark'
            title={
              <React.Fragment>
                <Lightning
                  className={`svg-tab-${
                    activeTab === 'spark' ? 'blue' : 'white'
                  }`}
                />
                <span
                id='fw-bold'
                className={`${activeTab === 'spark' ? 'fw-bold' : ''}`}>
                  SparkNFTs
                </span>
              </React.Fragment>
            }
          >
            <Spark />
          </Tab>
          <Tab
            eventKey='unopened'
            title={
              <React.Fragment>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='18.25'
                  height='20.575'
                  viewBox='0 0 18.25 20.575'
                  className='me-1'
                >
                  <path
                    id='package-24'
                    d='M12.924.822a1.6,1.6,0,0,0-1.6,0L3.8,5.167A1.6,1.6,0,0,0,3,6.55v8.692a1.6,1.6,0,0,0,.8,1.382l7.528,4.346a1.6,1.6,0,0,0,1.6,0l7.528-4.345a1.6,1.6,0,0,0,.8-1.383V6.55a1.6,1.6,0,0,0-.8-1.382L12.924.822Zm-.912,1.184a.228.228,0,0,1,.228,0L19.2,6.023l-7.072,4.082L5.055,6.023l6.958-4.017ZM4.37,7.21v8.033a.226.226,0,0,0,.114.2l6.958,4.017V11.293Zm8.44,12.246,6.958-4.017a.228.228,0,0,0,.114-.2V7.209l-7.072,4.082v8.164Z'
                    transform='translate(-3.001 -0.608)'
                    fill={`${activeTab === 'unopened' ? '#00a1ff' : 'white'}`}
                    fillRule='evenodd'
                  />
                </svg>

                <span
                id='fw-bold'
                  className={`${activeTab === 'unopened' ? 'fw-bold' : ''}`}
                >
                  Unopened Packs
                </span>
              </React.Fragment>
            }
          >
            <Unopened tab={setActiveTab} />
          </Tab>
        </Tabs>
      </Container>
    </div>
  );
};

export default Dashboard;
