import arraw1 from "../../assets/icons/arrow-up-solid.svg";
import arraw2 from "../../assets/icons/arrow-down-solid.svg";
import moment from "moment";
const Filters = ({ setFilteredData, allData, activeButton }) => {
  function sorting(value) {
    let copyData;
    if (activeButton === "all") {
      copyData = [...allData];
    } else {
      copyData = allData.filter(
        (item) => item.assetType.toLowerCase() === activeButton.toLowerCase()
      );
    }
    if (value === 1) {
      copyData.sort(function (a, b) {
        return a.sellPrice - b.sellPrice;
      });
      return copyData;
    } else {
      copyData.sort(function (a, b) {
        return b.sellPrice - a.sellPrice;
      });
      return copyData;
    }
  }
  function sortingByDate(value) {
    let copyData = [...allData];
    if (value === 1) {
      copyData.sort(
        (a, b) =>
          new moment(a.releaseDate).format("YYYYMMDD") -
          new moment(b.releaseDate).format("YYYYMMDD")
      );

      return copyData;
    } else {
      copyData.sort(
        (a, b) =>
          new moment(b.releaseDate).format("YYYYMMDD") -
          new moment(a.releaseDate).format("YYYYMMDD")
      );

      return copyData;
    }
  }
  return (
    <div className="row">
      <div className="col-12">
        <div className="filters">
          <div className="Filter">
            <h1>Sort by Price</h1>
            <div className="btns">
              <button onClick={() => setFilteredData(sorting(0))}>
                <img src={arraw1} />
              </button>
              <button onClick={() => setFilteredData(sorting(1))}>
                <img src={arraw2} />
              </button>
            </div>
          </div>
          <div className="Filter">
            <h1>Sort by Date</h1>
            <div className="btns">
              <button onClick={() => setFilteredData(sortingByDate(0))}>
                <img src={arraw1} />
              </button>
              <button onClick={() => setFilteredData(sortingByDate(1))}>
                <img src={arraw2} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filters;
