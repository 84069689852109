import { useMemo } from "react";
import { Calendar, Radio, X } from "react-feather";
import { Link, NavLink } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import Heading from "src/common/Heading";
import PackCard from "src/common/PackCard";
import LoadingSpinner from "src/common/LoadingSpinner";
import { PackTemplatesHook } from "src/hooks/pack-templates";
import { ReactComponent as Script } from "src/assets/icons/Script.svg";
import { Roles } from "src/utility/constants/common";
import { AuthHook } from "src/context/auth";

const PackSale = () => {
  const { user } = AuthHook();
  const { data, loading } = PackTemplatesHook();

  const allowedMarketplace = useMemo(
    () => (user?.authorities || []).includes(Roles.marketplace),
    [user]
  );
  console.log(data, "pack");
  return (
    <div className="bgdark min-vh-100">
      {loading ? <LoadingSpinner /> : null}
      <Container className="h-100 py-5">
        <Row className="txt-white txt-medium align-items-center pb-5">
          <Col lg={6} sm={6} xs={12} className="responsive-left">
            <Heading
              type="main"
              text="Pack Sale"
              color="white"
              className="txt-heading"
            />
          </Col>
          <Col lg={6} sm={6} xs={12} className="responsive-right">
            <NavLink to="/app/dashboard" className="txt-medium navbar-link">
              My Wallet
            </NavLink>
            <NavLink to="/app/pack-sale" className="txt-medium navbar-link">
              Pack Sale
            </NavLink>
            {allowedMarketplace ? (
              <NavLink to="/app/marketplace" className="txt-medium navbar-link">
                Marketplace
              </NavLink>
            ) : null}
          </Col>
        </Row>
        <Row>
          {data?.length > 0
            ? data.map((item) => (
                <>
                  {item?.quantity > 0 ? (
                    <Col
                      lg={6}
                      md={6}
                      sm={12}
                      className="mb-3 pack_cards"
                      key={item?.id}
                    >
                      <Link
                        to={`/app/payment?id=${item.id}&type=pack`}
                        className="txt-gray"
                      >
                        <div className="pack_container">
                          <img
                            className="img img-fluid"
                            src={item?.picture?.url}
                            alt="pack"
                          />
                          <h1>LIVE NOW</h1>
                        </div>
                      </Link>
                    </Col>
                  ) : null}
                  {item?.quantity === -21 ? (
                    <Col
                      lg={6}
                      md={6}
                      sm={12}
                      className="mb-3 pack_cards"
                      key={item?.id}
                    >
                      <div className="pack_container">
                        <img
                          className="img img-fluid"
                          src={item?.picture?.url}
                          alt="pack"
                        />
                        <h1>COMING SOON</h1>
                      </div>
                    </Col>
                  ) : null}
                  {item?.quantity === 0 ||
                  (item?.quantity !== -21 && item?.quantity < 0) ? (
                    <Col
                      lg={6}
                      md={6}
                      sm={12}
                      className="mb-3 pack_cards"
                      key={item?.id}
                    >
                    
                        <div className="pack_container">
                          <img
                            className="img img-fluid"
                            src={item?.picture?.url}
                            alt="pack"
                          />
                          <h1>SOLD OUT</h1>
                        </div>
                    </Col>
                  ) : null}
                </>
              ))
            : null}
        </Row>
      </Container>
    </div>
  );
};

export default PackSale;
