import { useEffect, useState } from 'react';

import { CardsService } from 'src/utility/services';
import { CommonConstant, ErrorMessages } from 'src/utility/constants/common';
import { Company, Owner } from 'src/types/user';

export interface CardsType {
  assetType: string;
  id: string;
  name: string;
  groupSequence: number;
  maxExisting: number;
  icon: { url?: string };
}

export const CardsHook = () => {
  const [data, setData] = useState<CardsType[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [hasMore, setHasMore] = useState(true);
  const [filter, setFilter] = useState({
    page: 0,
    size: CommonConstant.defaultPageSize,
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await CardsService.my(filter);
      setData(data.concat(result || []));
      setHasMore((result || []).length === filter.size);
    } catch (error) {
      setError(ErrorMessages.default);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (filter) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const pageChanged = () => {
    const temp = {
      ...filter,
      page: filter.page + 1,
    };
    setFilter({ ...temp });
  };

  return { data, loading, error, pageChanged, hasMore };
};

interface CardDetailsType {
  attachments: any[];
  id: string;
  name: string;
  text: string;
  icon?: { url: string };
  groupSequence?: number;
  maxExisting?: number;
  sellPrice?: number;
  owner?: { id: string };
  forSale?: boolean;
  series?: string;
  edition?: string;
  video?: any;
  logo?:any;
}

export const CardDetailsHook = (id: string | undefined) => {
  const [data, setData] = useState<CardDetailsType | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await CardsService.getById(id!);
      setData(result);
    } catch (error) {
      setError(ErrorMessages.default);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return { data, loading, error, setData };
};

interface CardHistoryType {
  card?: any;
  company?: Company;
  createdDate: Date | string;
  event: string;
  id: string;
  user?: Owner;
}

export const CardHistoryHook = (id: string | undefined) => {
  const [data, setData] = useState<CardHistoryType[] | []>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await CardsService.history(id!);
      setData(result);
    } catch (error) {
      setError(ErrorMessages.default);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return { data, loading, error };
};

export const CanSellHook = () => {
  const [data, setData] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    try {
      setLoading(true);
      await CardsService.canSell();
      setData(true);
    } catch (error) {
      setData(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { data, loading };
};
