import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { CheckCircle } from 'react-feather';
import { Link, useLocation } from 'react-router-dom';

import Heading from 'src/common/Heading';
import CustomCard from 'src/common/CustomCard';
import FormButton from 'src/common/FormButton';
import { DetailsHook } from 'src/hooks/payment';

function PaymentConfirmation() {
  const [id, setId] = React.useState('');
  const [type, setType] = React.useState('');
  let { search } = useLocation();

  React.useEffect(() => {
    const id = new URLSearchParams(search).get('id');
    if (id) {
      setId(id);
    }
    const type = new URLSearchParams(search).get('type');
    if (type) {
      setType(type);
    }
  }, [search]);

  const { data } = DetailsHook(id, type);

  return (
    <Row className='justify-content-center bgdark h-100 m-0'>
      <Col lg={4} md={6} className='m-5'>
        <CustomCard bg='gray' className='m-0'>
          {id && type ? (
            <React.Fragment>
              <div className='d-flex flex-column align-items-center'>
                <CheckCircle size={60} className='mb-3 txt-blue' />
                <Heading
                  type='subheading'
                  text='Thank you for your purchase!'
                  className='text-center'
                />
              </div>
              <p className='text-center mt-3'>
                Your order of {data?.name} is preparing, check status on
                transaction page.
              </p>
            </React.Fragment>
          ) : (
            <p className='text-center text-danger'>
              Invalid Link, Please contact administrator
            </p>
          )}

          <Link to='/app?tab=unopened'>
            <FormButton text='Go To Packs' className='bgblue' />
          </Link>
        </CustomCard>
      </Col>
    </Row>
  );
}

export default PaymentConfirmation;
