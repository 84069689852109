import { BaseService } from 'src/utility/services/base';
import { APIPath } from 'src/utility/constants/api';
import { CommonConstant } from 'src/utility/constants/common';
import { BrowserUtility } from 'src/utility/browser-utility';
import { CRUDService } from 'src/utility/services/crud';
import CommonUtility from 'src/utility/common';

class User extends CRUDService {
  constructor() {
    super(APIPath.account);
  }

  login = (reqData: {} | null) => {
    return BaseService.post(APIPath.login, reqData, false);
  };

  register = (reqData: {} | null) => {
    return BaseService.post(APIPath.register, reqData, true);
  };

  registerActivate = (key: string) => {
    return BaseService.get(`${APIPath.registerActivate}?key=${key}`);
  };

  forgotPassword = (reqData: {} | null) => {
    return BaseService.post(APIPath.forgotPassword, reqData, false);
  };

  resetPassword = (reqData: {} | null) => {
    return BaseService.post(APIPath.resetPassword, reqData, false);
  };

  changePassword = (reqData: {} | null) => {
    return BaseService.post(APIPath.changePassword, reqData);
  };

  storeUser = (user: {}) => {
    BrowserUtility.saveObj(CommonConstant.user, user);
  };

  storeToken = (token: string) => {
    BrowserUtility.save(CommonConstant.token, token);
  };

  getUser = () => {
    return BrowserUtility.getObj(CommonConstant.user);
  };

  getToken = () => {
    return BrowserUtility.get(CommonConstant.token) || '';
  };

  logout = () => {
    BrowserUtility.removeAll();
  };

  isAuthenticated = () => {
    const token = this.getToken();
    return !!token;
  };

  history(filter: {}) {
    const params = CommonUtility.objectToParams(filter);
    return BaseService.get(`${APIPath.userHistory}?${params}`);
  }

  sendMobileVerification() {
    return BaseService.post(APIPath.sendMobileVerification, null);
  }
  historyById(id:any | null) {
    return BaseService.get(`${APIPath.userHistory}/${id}`);
}

  verifyMobile(data: {} | null) {
    return BaseService.post(APIPath.verifyMobile, data);
  }

  branding() {
    return BaseService.get(APIPath.branding);
  }

  setupBank() {
    return BaseService.get(APIPath.bankSetup);
  }
}

const UserService = new User();
Object.freeze(UserService);
export { UserService };
