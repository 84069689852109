import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Col, Row, Spinner } from 'react-bootstrap';

import CustomCard from 'src/common/CustomCard';
import FormButton from 'src/common/FormButton';
import { UserService } from 'src/utility/services';
import { ErrorMessages } from 'src/utility/constants/common';
import carBg from 'src/assets/images/car-bg.jpg';
import BackgroundImage from 'src/common/BackgroundImage';

function RegisterActivation() {
  let { search } = useLocation();
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const onSubmit = async (key: string) => {
    try {
      setError('');
      setLoading(true);
      await UserService.registerActivate(key);
      setSuccess(true);
    } catch (err: any) {
      setError(err?.error?.detail || ErrorMessages.default);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    const key = new URLSearchParams(search).get('key');
    if (key) {
      onSubmit(key);
    } else {
      setError('Invalid Link, Please contact administrator.');
    }
  }, [search]);

  return (
    <React.Fragment>
      <BackgroundImage src={carBg} />
      <Row className='m-0 justify-content-center'>
        <Col lg={6} md={8} sm={10} className='mt-5'>
          <CustomCard header='ACCOUNT CONFIRMATION'>
            {loading ? (
              <div className='d-flex justify-content-center'>
                <Spinner animation='border' variant='light' />
              </div>
            ) : (
              <React.Fragment>
                {success && (
                  <p className='text-center mt-3'>
                    Your registration has been completed.
                  </p>
                )}
                {error && <p className='text-center mt-3'>{error}</p>}
                <Link to='/login'>
                  <FormButton text='Back to login' className='bgblue' />
                </Link>
              </React.Fragment>
            )}
          </CustomCard>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default RegisterActivation;
