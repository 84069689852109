import { useEffect, useState } from "react";
import { MarketplaceService } from "src/utility/services";
import { CommonConstant, ErrorMessages } from 'src/utility/constants/common';

interface FilterType {
  page: number;
  size: number;
}
export const MarketplaceHook = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [filter, setFilter] = useState<FilterType>({
    page: 0,
    size: CommonConstant.defaultPageSize,
  });

  const fetchData = async (fetchFilter: FilterType) => {
    try {
      setLoading(true);
      const result = await MarketplaceService.get(fetchFilter);
      setData(result.concat(data || []));
      setHasMore((result || []).length === fetchFilter.size);

    } catch (error) {
      setError(ErrorMessages.default);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(filter);
  }, []);

  const pageChanged = () => {
    const temp = {
      ...filter,
      page: filter.page + 1,
    };
    setFilter({ ...temp });
    fetchData({ ...temp });
  };
  return { data, loading, error,pageChanged,hasMore };
};
