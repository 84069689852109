import React from 'react';

type BackgroundImageType = {
  src: string;
};

const BackgroundImage: React.FC<BackgroundImageType> = ({ src }) => {
  const style: React.CSSProperties = {
    zIndex: -1,
    backgroundImage: `url(${src})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    transform: 'scaleX(-1)',
    width: '100%',
    height: '100%',
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  };

  return <div style={style}></div>;
};

export default BackgroundImage;
