import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { Card, Col, Form, FormGroup, Modal, Row } from "react-bootstrap";
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from "react-google-login";

import BackgroundImage from "src/common/BackgroundImage";
import CustomCard from "src/common/CustomCard";
import FormButton from "src/common/FormButton";
import FormInput from "src/common/FormInput";
import FormSepartor from "src/common/FormSeparator";
import LoadingSpinner from "src/common/LoadingSpinner";
import TermsCheckbox from "src/common/TermsCheckbox";
import { AuthHook } from "src/context/auth";
import { UserService } from "src/utility/services";
import {
  ErrorMessages,
  REACT_APP_GOOGLE_CLIENT_ID,
} from "src/utility/constants/common";

import carBg from "src/assets/images/car-bg.jpg";
import { ReactComponent as GoogleIcon } from "src/assets/icons/google.svg";
import { ReactComponent as MotoclubLogo } from "src/assets/images/motoclub-new-logo.svg";
import "src/screens/Login/style.scss";

const SignupSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "First name is too short!")
    .max(50, "First name is too long!")
    .required("First name is required"),
  lastName: Yup.string()
    .min(2, "Last name is too short!")
    .max(50, "Last name is too long!")
    .required("Last name is required"),

  email: Yup.string().email("Invalid email").required("Email is Required"),
  password: Yup.string()
    .min(4, "Password should be between 4 to 16 characters")
    .max(16, "Password should be between 4 to 16 characters")
    .required("Password is required"),
  birthDate: Yup.date().required("Birthday is required"),
  address: Yup.string()
    .min(2, "Address is too short!")
    .max(50, "Address is too long!")
    .required("Address is required"),
  mobileNumber: Yup.string().trim().required("Mobile number is required"),
  terms: Yup.boolean()
    .required("The terms and conditions must be accepted.")
    .oneOf([true], "The terms and conditions must be accepted."),
});

type FormType = {
  firstName: string;
  lastName: string;
  email: string;
  birthDate: string;
  address: string;
  mobileNumber: string;
  password: string;
  terms: boolean;
};

const SuccessModal = () => (
  <Modal show centered>
    <Modal.Dialog>
      <Modal.Body>
        <CustomCard header="SUCCESS">
          <p className="text-center">
            Email has been sent to your email address, please check your inbox
            to activate your account.
          </p>
          <Link to="/login">
            <FormButton text="Back to login" className="bgblue" />
          </Link>
        </CustomCard>
      </Modal.Body>
    </Modal.Dialog>
  </Modal>
);

const SignUp = () => {
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [authError, setAuthError] = React.useState("");
  const [showModal, setShowModal] = React.useState(false);
  const [googleToken, setGoogleToken] = React.useState("");
  const { login: loginContext } = AuthHook();
  const navigate = useNavigate();

  const afterLogin = (result: any) => {
    UserService.storeToken(result.id_token);

    setTimeout(async () => {
      try {
        const user = await UserService.get();
        UserService.storeUser(user);
        loginContext(user);
        navigate("/app/pack-sale");
      } catch (err: any) {
        setAuthError(err?.title || err?.error?.detail || ErrorMessages.default);
      }
    });
  };

  const googleLogin = async () => {
    try {
      setShowModal(false);
      setLoading(true);
      const result = await UserService.login({
        googleIdToken: googleToken,
      });
      afterLogin(result);
    } catch (err: any) {
      setAuthError(err?.error?.detail || ErrorMessages.default);
    } finally {
      setLoading(false);
      setGoogleToken("");
    }
  };

  const googleLoginInit = (
    data: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => {
    if ("tokenId" in data) {
      setGoogleToken(data.tokenId);
      setShowModal(true);
    }
  };

  const onSubmit = async (
    data: FormType,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      setAuthError("");
      setLoading(true);
      const requestBody = {
        ...data,
        login: "any",
      };
      await UserService.register(requestBody);
      setSuccess(true);
    } catch (err: any) {
      setSuccess(false);
      setAuthError(
        err?.error?.detail ||
          err?.error?.title ||
          "Invalid Username or Password"
      );
    } finally {
      setSubmitting(false);
      setLoading(false);
    }
  };
  const googleClient = REACT_APP_GOOGLE_CLIENT_ID || "";
  return (
    <React.Fragment>
      {loading ? <LoadingSpinner /> : null}
      {success ? SuccessModal() : null}
      <BackgroundImage src={carBg} />
      <Row className="m-0 align-items-center h-100">
        <Col lg={4} md={6} sm={8} className="mt-4">
          <div className="d-flex justify-content-center mb-4">
            <a href="https://motoclub.io/">
              <MotoclubLogo className="motoclub-logo" />
            </a>
          </div>
          <CustomCard header="Sign Up" type="signup" className="m-0">
            <Card.Body className="d-flex justify-content-center p-0">
              <GoogleLogin
                clientId={googleClient}
                cookiePolicy="single_host_origin"
                responseType="code,token"
                onSuccess={googleLoginInit}
                render={(renderProps) => (
                  <FormButton
                    icon={
                      <GoogleIcon width="20px" height="100%" className="mx-2" />
                    }
                    text="Sign up with Google"
                    onClick={renderProps.onClick}
                  />
                )}
              />
            </Card.Body>

            <FormSepartor text="Or" type="dark" />

            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                birthDate: "",
                address: "",
                mobileNumber: "",
                terms: false,
              }}
              validationSchema={SignupSchema}
              onSubmit={(values, { setSubmitting }) => {
                onSubmit(values, setSubmitting);
              }}
            >
              {({
                errors,
                handleChange,
                handleSubmit,
                isSubmitting,
                values,
              }) => (
                <Form
                  onSubmit={handleSubmit}
                  className="d-flex justify-content-center flex-column"
                >
                  <FormGroup className="d-flex flex-column align-items-center">
                    <FormInput
                      type="text"
                      name="firstName"
                      children=""
                      placeholder="First Name"
                      onChange={handleChange}
                      error={errors.firstName && values.firstName}
                      required
                    />
                    <FormInput
                      type="text"
                      name="lastName"
                      children=""
                      placeholder="Last Name"
                      onChange={handleChange}
                      error={errors.lastName && values.lastName}
                      required
                    />
                    <FormInput
                      type="email"
                      name="email"
                      children=""
                      placeholder="Email Address"
                      onChange={handleChange}
                      error={errors.email && values.email}
                      required
                    />
                    <FormInput
                      type="password"
                      name="password"
                      children=""
                      placeholder="Password"
                      onChange={handleChange}
                      error={errors.password && values.password}
                      required
                    />
                    <FormInput
                      type="date"
                      children=""
                      name="birthDate"
                      placeholder="birthday"
                      calendar={true}
                      onChange={handleChange}
                      error={errors.birthDate && values.birthDate}
                      required
                    />
                    <FormInput
                      type="text"
                      name="address"
                      placeholder="Address"
                      children=""
                      location={true}
                      onChange={handleChange}
                      error={errors.address && values.address}
                      required
                    />
                    <FormInput
                      type="number"
                      name="mobileNumber"
                      children=""
                      placeholder="Mobile Number"
                      phone={true}
                      onChange={handleChange}
                      error={errors.mobileNumber && values.mobileNumber}
                      required
                      maxLength={11}
                    />
                  </FormGroup>

                  <TermsCheckbox
                    type="terms"
                    name="terms"
                    error={
                      errors.terms &&
                      values.firstName &&
                      values.lastName &&
                      values.email &&
                      values.password
                    }
                    onChange={handleChange}
                  />

                  <Form.Group className="d-flex justify-content-center">
                    <FormButton
                      type="submit"
                      text="SIGN UP"
                      disabled={isSubmitting}
                    />
                  </Form.Group>
                  {authError ? (
                    <p className="text-center txt-medium text-danger mt-3 mb-0">
                      {authError}
                    </p>
                  ) : null}
                  {(errors.firstName && values.firstName) ||
                  (errors.lastName && values.lastName) ||
                  (errors.email && values.email) ||
                  (errors.password && values.password) ? (
                    <p className="text-center txt-medium text-danger mt-3 mb-0">
                      {errors.firstName ||
                        errors.lastName ||
                        errors.email ||
                        errors.password}
                    </p>
                  ) : null}
                </Form>
              )}
            </Formik>
          </CustomCard>
        </Col>
      </Row>
      <Modal show={showModal} className="ms-2 termsModal">
        <CustomCard header="Terms">
          <p className="text-center">
            By proceeding you accept our{" "}
            <a
              href="https://motoclub.io/privacy-policy/"
              target="_blank"
              className="redirectWhite"
            >
              privacy policy
            </a>{" "}
            and our{" "}
            <a
              href="https://motoclub.io/terms-of-service/"
              target="_blank"
              className="redirectWhite"
            >
              terms of service
            </a>
            .
          </p>
          <FormButton type="submit" text="ACCEPT" onClick={googleLogin} />
        </CustomCard>
      </Modal>
    </React.Fragment>
  );
};

export default SignUp;
