import React from "react";
import { Col, Container, ListGroup, ListGroupItem, Row } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import Heading from "src/common/Heading";
import footerBg from "src/assets/images/footer-bg.png";
import discord from "src/assets/icons/discord.svg";
import metaWorksLogo from "src/assets/images/metaworks-logo.png";

import "src/common/styles/common.scss";

const footerLinks = [
  {
    text: "ABOUT",
    isExternal: true,
    link: "https://motoclub.io/about/",
  },
  {
    text: "NEWS",
    isExternal: true,
    link: "https://motoclub.io/news/",
  },
  {
    text: "SUPPORT",
    isExternal: true,
    link: "https://motoclub.io/support-faq-tab/support-customer-support-tab/",
  },
  {
    text: "CONTACT",
    isExternal: true,
    link: "https://motoclub2dev.wpengine.com/web-6/contact/",
  },
];

const FooterLinks = () =>
  footerLinks && footerLinks.length > 0 ? (
    <ListGroup as="ul">
      {footerLinks.map((item) => (
        <ListGroupItem
          key={item.text}
          className="bg-transparent mb-2 p-0 border-0"
        >
          {item.isExternal ? (
            <a
              href={item.link}
              key={item.text}
              target={"_blank"}
              className="txt-medium navbar-link-ft p-0"
            >
              {item.text}
            </a>
          ) : (
            <NavLink to={item.link} className="txt-medium navbar-link-ft p-0">
              {item.text}
            </NavLink>
          )}
        </ListGroupItem>
      ))}
    </ListGroup>
  ) : null;

const SocialIcons = () => {
  const style: React.CSSProperties = {
    fill: "white",
  };
  return (
    <React.Fragment>
      <a
        href="https://www.facebook.com/MotoclubDigital"
        target="_blank"
        className="socials"
        rel="noreferrer"
      >
        <i className="fa-brands  fa-facebook"></i>
      </a>
      <a
        href="https://twitter.com/MotoClubDigital"
        target="_blank"
        className="socials"
        rel="noreferrer"
      >
        <i className="fa-brands fa-twitter"></i>
      </a>
      <a
        href="https://www.instagram.com/motoclubdigital"
        target="_blank"
        className="socials"
        rel="noreferrer"
      >
        <i className="fa-brands fa-instagram"></i>
      </a>
      <a
        href="https://www.youtube.com/channel/UCrUFUgGuIA1qR6qIECNquOA"
        target="_blank"
        className="socials"
        rel="noreferrer"
      >
        <i className="fa-brands fa-youtube"></i>
      </a>
      <a
        href="https://www.linkedin.com/company/motoclubdigital"
        target="_blank"
        className="socials"
        rel="noreferrer"
      >
        <i className="fa-brands fa-linkedin"></i>
      </a>
      <a
        href="https://www.reddit.com/user/MotoclubDigital"
        target="_blank"
        className="socials"
        rel="noreferrer"
      >
        <i className="fa-brands fa-reddit"></i>
      </a>
      <a
        href="https://discord.com/invite/gU2dsQ8erF"
        target="_blank"
        className="socials d-flex"
        rel="noreferrer"

      >
        {/* <i className="fab fa-discord"></i> */}
          <img src={discord} alt="discord" className="discord_icon"/>
      </a>

      <a href="" target="_blank" className="socials" rel="noreferrer">
        <i className="fa-brands fa-twitch"></i>
      </a>
    </React.Fragment>
  );
};

const Footer = () => {
  return (
    <footer
      className="w-100 py-4 flex-shrink-0 bgImage"
      style={{
        backgroundImage: `url(${footerBg})`,
      }}
    >
      <Container className="py-4">
        <Row>
          <Col lg={2} md={6} className="mb-5">
            <Heading
              type="subheading"
              text="COMPANY"
              color="white"
              className="mb-4"
            />
            <FooterLinks />
          </Col>
          <Col lg={3} md={6}>
            <Heading
              type="subheading"
              text="ABOUT US"
              color="white"
              className="mb-4"
            />
            <p className="txt-white txt-medium w-60">
              The world’s premier space for car enthusiasts and fans of
              automotive memorabilia.
            </p>
            <Heading
              type="subheading"
              text="CONTACT US"
              color="white"
              className="mb-4"
            />
            <p className="txt-white txt-medium">
              <a
                href="mailto:contact@motoclub.io"
                className="navbar-link-ft p-0"
              >
                Contact@motoclub.io
              </a>
            </p>
          </Col>
          <Col lg={3} md={6}>
            <Heading
              type="subheading"
              text="RECENT POSTS"
              color="white"
              className="mb-4"
            />

            <ListGroup as="ul">
              <ListGroupItem className="bg-transparent mb-3 p-0 border-0 ">
                <Link to="/" className="txt-medium navbar-link-ft p-0 ">
                  <p className="txt-white txt-medium w-60">
                    Motoclub’s Barrett-Jackson 50th Anniversary Collection
                    Levels Up at Las Vegas Auction
                  </p>
                </Link>
              </ListGroupItem>
              <ListGroupItem className="bg-transparent mb-3 p-0 border-0">
                <Link to="/" className="txt-medium navbar-link-ft p-0">
                  <p className="txt-white txt-medium w-60">
                    Motoclub Reveals Newest Pack in Barrett-Jackson Scottsdale
                    Collector Series
                  </p>
                </Link>
              </ListGroupItem>
            </ListGroup>
          </Col>

          <Col lg={4} md={6}>
            <Heading
              type="subheading"
              text="POWERED BY"
              color="white"
              className="mb-4"
            />
            <a href="https://currencyworks.io/" target="_blank">
              <img src={metaWorksLogo} alt="Metaworks" className="mb-5" />
            </a>
            <div className="d-flex justify-content-between w-75">
              <SocialIcons />
            </div>
          </Col>
        </Row>

        <hr className="txt-white" />
        <Row className="txt-white txt-medium mt-3">
          <Col lg={6} sm={6} xs={12} className="responsive-left">
            Copyright © {new Date().getFullYear()} Motoclub. All Rights
            Reserved.
          </Col>
          <Col lg={6} sm={6} xs={12} className="responsive-right">
            <a
              href="https://motoclub.io/privacy-policy/"
              className="txt-medium navbar-link-ft"
              target="_blank"
            >
              Privacy Policy
            </a>
            |
            <a
              href="https://motoclub.io/terms-of-service/"
              className="txt-medium navbar-link-ft"
              target="_blank"
            >
              Terms of Service
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
