import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import NavBar from 'src/common/NavBar';
import Footer from 'src/common/Footer';
import Heading from 'src/common/Heading';
import ScrollToTop from 'src/common/ScrollToTop';

const TermsOfUse = () => {
  return (
    <ScrollToTop>
      <NavBar />
      <div className='bgdark min-vh-100 pb-5'>
        <div className='txt-white pt-5'>
          <Container>
            <Heading
              type='main'
              text='Terms of Service'
              color='white'
              className='mb-5'
            />
            <p>
              Last updated:{' '}
              <i className='text-italic fw-bold'>September 13, 2021</i>
            </p>
            <p>
              Welcome to <strong>Motoclub.io</strong>, which is provided by
              CurrencyWorks USA Inc. (“<strong>CurrencyWorks</strong>”, “
              <strong>we</strong>”, “<strong>our</strong>” or “
              <strong>us</strong>”).
            </p>
            <p>
              These Terms of Service (the “<b>Terms</b>”) govern the
              relationship, and form a legally binding agreement, between you
              and us and set forth the terms and conditions on which you may
              access and use (a) the website operated at motoclub.io, and other
              locations from time to time (the “<b>Website</b>”), (b) any
              applications or other software we provide you (the “
              <b>Application</b>”), excluding third party software that may
              interact with the Service as defined below, and (c) the services
              we provide through the Website and the Application, including any
              offering in respect of Collectibles (as this term is defined
              herein) (Website, Application, and Collectibles shall be
              collectively referred to as the “<b>Service</b>”). Any reference
              to the “<b>Services</b>” includes a reference to any part of the
              Services.
            </p>
            <p>
              For purposes of these Terms, “<b>you</b>” and “<b>your</b>” means
              you as the user of the Services. If you are agreeing to these
              Terms and using the Service on behalf of a corporation,
              partnership, or other legal entity, you represent that you have
              all required legal rights and authority to bind that entity to
              these terms. In such a case, any further reference to “<b>you</b>”
              in these terms means that other legal entity.
            </p>
            <p>
              The Service enables you to purchase, collect, showcase, and
              possibly sell digital collectibles (“<b>Collectibles</b>”) which
              are created by us or our business partners (each a “<b>Seller</b>
              ”). Each Collectible is a non-fungible token (“<b>NFT</b>”) minted
              on a CurrencyWorks Blockchain (the “<b>blockchain</b>”) and tied
              to content hosted on the Service as well as a license to use such
              content as represented by the Collectible in certain ways. Sales
              are subject to the creation of any secondary marketplace, which we
              cannot guarantee will exist.
            </p>
            <p>
              <b>PLEASE READ THESE TERMS OF SERVICE CAREFULLY,</b> as they
              contain important information regarding your legal rights,
              remedies and obligations, including, but not limited to, various
              limitations and exclusions on damages you may claim against us and
              indemnification obligations you owe to us. If you do not agree to
              these Terms, you may not access or use the Services. By clicking
              “I agree to the Terms of Service”, or by using or accessing the
              Services, you represent that you understand, accept, and agree to
              be bound by and comply with these Terms and our Privacy Policy,
              available here motoclub.io/privacypolicy, which forms an essential
              part of these Terms.
            </p>
            <p>
              <b>
                PLEASE NOTE THAT DIFFERENT TERMS MAY APPLY TO YOU BASED ON WHERE
                YOU LIVE. MAKE SURE YOU READ THE JURISDICTION-SPECIFIC TERMS FOR
                YOUR COUNTRY OF RESIDENCE, AS APPLICABLE.{' '}
              </b>
              These specific terms concern important aspects of our agreement,
              including how you consent to these Terms, your consumer
              cancellation rights, your privacy, our rights to terminate your
              account or access to the Services, our warranties and liability,
              and the law applicable to these Terms.
            </p>
            <p>
              <b>ARBITRATION NOTICE FOR USERS IN THE UNITED STATES</b>: THESE
              TERMS CONTAIN AN ARBITRATION CLAUSE AND A WAIVER OF RIGHTS TO
              BRING A CLASS ACTION AGAINST US. EXCEPT FOR CERTAIN TYPES OF
              DISPUTES MENTIONED IN THAT ARBITRATION CLAUSE, YOU AND
              CURRENCYWORKS AGREE THAT DISPUTES BETWEEN US WILL BE RESOLVED BY
              MANDATORY BINDING ARBITRATION, AND YOU WAIVE ANY RIGHT TO
              PARTICIPATE IN A CLASS-ACTION LAWSUIT, CLASS-WIDE ARBITRATION, OR
              ANY OTHER COLLECTIVE OR REPRESENTATIVE ACTION.
            </p>
            <ol>
              <li>
                <b>Age and Acceptance of Terms.</b>
                <p>
                  Access to certain Services or features of the Services may be
                  subject to age restrictions and not available to all users of
                  the Services. The Services are only for users 18 years of age
                  and older. By accessing or using our Services, you confirm
                  that: (a) you are over 18 and can form a binding contract with
                  us; (b) you accept these Terms; and (c) you agree to comply
                  with these Terms. If you are over 18 but under the legal age
                  to enter into a contract in your jurisdiction, your parents or
                  legal guardian must read and agree to these Terms, as well as
                  supervise your use of the Services. If you do not have consent
                  from your parents or legal guardians, you must cease accessing
                  or using the Services. If we learn that someone under the
                  relevant age is using the Services, we will terminate access
                  to the Services for such user.
                </p>
                <p>
                  You understand and agree that we will treat your access or use
                  of the Services or any part thereof, including your creation
                  of an account, as acceptance of the Terms and the Privacy
                  Policy. You should print off or save a local copy of the Terms
                  for your records.
                </p>
              </li>
              <li>
                <b>Supplemental Terms for Specific Jurisdictions</b>
                <p>
                  If you access or use the Services from within a jurisdiction
                  for which there are separate supplemental terms, you also
                  agree to the supplemental terms applicable to you in the
                  applicable jurisdiction as outlined below. If there is a
                  conflict between the provisions of the supplemental terms that
                  are applicable to you, and the rest of these Terms, your
                  jurisdictions’ supplemental terms will supersede and control.
                </p>
              </li>
              <li>
                <b>Changes to the Terms</b>
                <p>
                  We reserve the right in our sole and absolute discretion to
                  revise and update these Terms from time to time. All changes
                  are effective immediately upon posting and apply to your
                  continued access to and use of the Services. We may post
                  reminders and summary information about material changes to
                  these Terms, including where there are substantial amendments
                  that affect your rights and obligations, but it is your
                  responsibility to review them. You agree to periodically
                  review these Terms in order to be aware of any such changes
                  and your continued use shall mean your acceptance of any such
                  changes, whether given notice or not.
                </p>
                <p>
                  You agree that we will not be liable if, for any reason, all
                  or any part of the Service or these Terms is changed,
                  withdrawn, terminated, restricted to users, or unavailable at
                  any time or for any period or if such restriction or
                  unavailability impacts the use, functionality, or value of
                  Collectibles.
                </p>
                <p>
                  For clarity, if any of the terms and conditions described in
                  these Terms, or any future modifications thereto, are
                  unacceptable to you, you should: (i) discontinue your use of
                  the Services; and (ii) if you have set up an account, delete
                  your account from your account management page. Your use of
                  the Services now, or your continued use of the Services
                  following the implementation of updated Terms, will indicate
                  acceptance by you of such Terms or modifications. If you do
                  not agree to the Terms as applicable from time to time, you
                  must stop accessing or using the Services.
                </p>
              </li>
              <li>
                <b>Your Account and Security</b>
                <p>
                  In consideration of your use of the Services, you hereby agree
                  to: (a) provide true, accurate, current and complete
                  information about yourself as requested by any registration
                  forms on the Service (“<b>Registration Data</b>”); and (b)
                  maintain and promptly update the Registration Data to keep it
                  true, accurate, current and complete. By providing
                  Registration Data to us through the Services, you will provide
                  personal account information. You hereby agree to be fully
                  responsible for: (w) all use of your User Account; (x) any
                  action that takes place using your account by any person or
                  automated process; (y) maintaining the confidentiality and
                  security of your account and passwords; and (z) immediately
                  notifying us upon any unauthorized use of your password or
                  account, or if you know of any other breach of security in
                  relation to the Services. You are responsible for any password
                  misuse or any unauthorized access. You agree that your
                  disclosure of login information to third parties may negate
                  our user authentication measures and allow unauthorized access
                  to your account. We will not be liable for any loss or damage
                  arising from any unauthorized use of your account(s).
                </p>
                <p>
                  We have provided ways for you to contact us about various
                  issues on our website at privacy@motoclub.io You acknowledge
                  and understand that these are the only authorized ways to
                  contact us. Third parties may advertise or publish alternative
                  ways to contact us or use other measures to pose as us. We
                  cannot verify the authenticity of any such alternative
                  contacts, and they pose a risk of fraud and other malfeasance,
                  so we strongly recommend you do not attempt to contact us
                  using these methods. You agree that we cannot be held liable
                  for unauthorized access to your account or other loss
                  resulting from such fraud or other malfeasance by third
                  parties.
                </p>
              </li>
              <li>
                <b>
                  Purchasing Collectible and Conducting Transactions on the
                  Service
                </b>
                <ol>
                  <li>
                    <p>
                      Payment Information and Your Wallet: You will need to use
                      your Motoclub wallet in order to purchase and store
                      Collectibles via the Service. You can use your electronic
                      wallet to purchase and store Collectibles and to engage in
                      transactions using your credit card, or via one or more
                      cryptocurrencies that we may elect to accept from time to
                      time. Transactions that take place on the Service are
                      managed and confirmed via the CurrencyWorks Blockchain
                      network. You must provide and at all times must maintain
                      accurate, complete, and current billing information,
                      including your postal/zip code, credit card number, and
                      credit card expiration date. We may suspend or terminate
                      your account and access to the Services if your offered
                      payment method (e.g., credit card) cannot be processed. In
                      addition, orders may not be accepted from certain
                      jurisdictions. By providing a payment method, you
                      expressly authorize us and our third-party payment
                      processors to charge the applicable fees on said payment
                      method, as well as taxes and other charges related
                      thereto, all of which depend on the Services you utilize.
                      You agree that we may charge any unpaid amounts to your
                      provided payment method and send you a bill for such
                      unpaid fees. If you provide a payment method and our
                      charge results in an overdraft, chargeback, or other fee
                      from your bank, you alone are responsible for that fee.
                      You are responsible for the security of your account for
                      the Service and for your electronic wallet. If you become
                      aware of a potential breach of security to your wallet or
                      billing information (such as credit card loss or theft),
                      you must notify us immediately.
                    </p>
                  </li>
                  <li>
                    <p>
                      Acquiring Collectibles: The Service allows you to
                      purchase, collect and showcase Collectibles that are
                      created on the Service by us and our Sellers. You can
                      purchase Collectibles in two ways: (a) by buying specific
                      Collectibles that are posted for sale on the Service; or
                      (b) by buying packs of Collectibles from us or our Sellers
                      on the Service (each, a “<b>Pack</b>”). There are
                      different types of Packs available for purchase on the
                      Service, and we reserve the right to modify the types,
                      prices and numbers of Packs available at our discretion.
                      Depending on the type of Pack you buy, you will receive
                      Collectibles of varying levels of scarcity. Before you buy
                      a Pack, we will let you know the types of Collectibles and
                      how many of each type of Collectible (but not the exact
                      Collectibles) that are contained in that Pack. If you buy
                      an individual Collectible, you will know the exact
                      Collectible that you are purchasing. We do not offer or
                      sell Collectibles outside of the Service. We thus strongly
                      discourage purchase of Collectibles that are offered for
                      sale outside of the Service. If you decide to purchase
                      Collectibles from third party websites or services, you
                      understand that we do not and cannot verify or vouch for
                      the authenticity or reliability of such Collectibles, and
                      such purchases will be entirely at your sole risk.
                    </p>
                  </li>
                  <li>
                    <p>
                      Description of Collectibles: Collectibles are designed as
                      motorsports commemorative memorabilia and collectibles to
                      enhance the experience of motorsport enthusiasts. They are
                      not intended to be investments or used for investment
                      purposes. Collectibles can vary in composition and Sellers
                      have the sole and absolute discretion as to what to
                      include in each Collectible, including any rights
                      associated with that Collectible. Generally, however,
                      Collectibles can be associated with various types of
                      content including statistics, photographs, videos,
                      designs, audio recordings, artwork and other forms of
                      media and information (collectively, “
                      <b>Collectible Content</b>”). See “Intellectual Property
                      Rights in and to the Collectibles and Collectible Content”
                      below where we explain what rights you do and do not
                      obtain by purchasing a Collectible; it is important to
                      know that the association between the Collectible and the
                      Collectible Content does not necessarily give you any
                      rights in and to the Collectible Content itself. The value
                      of each Collectible is inherently subjective, in the same
                      way the value of other sports memorabilia is inherently
                      subjective. Each Collectible has no inherent or intrinsic
                      value. Some collectors might prefer to have a Collectible
                      featuring a certain vehicle, while another might prefer an
                      equivalent Collectible featuring a different vehicle.
                      Therefore, when buying Collectibles you are strongly
                      encouraged to carefully read the details for each
                      Collectible before you make a purchase, as well as any
                      additional terms and conditions that may go along with it.
                      You accept full responsibility to comply with the scope of
                      the rights granted to you by each Collectible.
                    </p>
                    <p>
                      All purchases of Collectibles on the Service are subject
                      to these Terms as well as the terms and conditions of sale
                      presented to you by the Seller at the time of purchase.
                      Please note that some transactions and Collectibles you
                      purchase are subject to additional restrictions, including
                      those that relate to age and residency as well as those
                      that impact your rights in and to the Collectible and the
                      Collectible Content. By completing a purchase, you are
                      thereby agreeing to any such additional terms and
                      conditions.
                    </p>
                  </li>
                  <li>
                    <p>
                      Modifications to Services and Pricing: We reserve the
                      right at any time and from time to time to change, modify,
                      add, remove, suspend, cancel, or discontinue any aspect of
                      the Services in our sole discretion, including the
                      technologies used and even including the blockchain and
                      other fundamental blockchain or NFT technologies. In
                      addition, we may modify our fees from time to time.
                    </p>
                  </li>
                  <li>
                    <p>
                      Identifying the Seller: In each case, we are not
                      necessarily the Seller of a given Collectible. When
                      purchasing Collectibles we will indicate who the Seller is
                      of each Collectible or Pack. For those transactions where
                      we are not the Seller, we are not a broker, financial
                      institution, or creditor and provide the Services as an
                      administrative platform only to facilitate transactions of
                      Collectibles.{' '}
                      <b>
                        YOU BEAR FULL RESPONSIBILITY FOR VERIFYING THE IDENTITY,
                        LEGITIMACY, AND AUTHENTICITY OF COLLECTIBLES YOU
                        PURCHASE ON THE SERVICE. WE MAKE NO CLAIMS ABOUT THE
                        IDENTITY, LEGITIMACY, OR AUTHENTICITY OF COLLECTIBLE
                        CONTENT ON THE PLATFORM.
                      </b>
                    </p>
                  </li>
                  <li>
                    <p>
                      Payment Processors: All fiat currency (i.e. government
                      issued currency) transactions that occur on the Service
                      are managed, processed, and confirmed by <b>Stripe</b> and
                      as such, all fiat currency transactions are subject to
                      their terms of service. We reserve the right to change the
                      method by which we process fiat currency transactions at
                      any time.{' '}
                      <b>
                        DUE TO THE NATURE OF BLOCKCHAIN TECHNOLOGY, WE HAVE NO
                        CONTROL OVER CRYPTOCURRENCY OR COLLECTIBLE PAYMENTS OR
                        TRANSACTIONS, NOR DO WE HAVE THE ABILITY TO REVERSE ANY
                        PAYMENTS OR TRANSACTIONS.
                      </b>
                    </p>
                  </li>
                  <li>
                    <p className='fw-bold'>
                      REGARDLESS OF WHETHER PAID FOR WITH FIAT OR
                      CRYPTOCURRENCY, WE DO NOT PROVIDE REFUNDS FOR ANY
                      PURCHASES THAT YOU MIGHT MAKE ON OR THROUGH THE SERVICE –
                      WHETHER FOR COLLECTIBLES, PACKS, OR ANYTHING ELSE.
                    </p>
                  </li>
                  <li>
                    <p>
                      Transaction Fees: Buying, selling (if possible) or
                      transferring (if possible) Collectibles may be subject to
                      fees, commissions, royalties and other charges (“
                      <b>Fees</b>
                      ”) established from time to time in our sole discretion.
                      Fees include: (a) service fees established by and payable
                      to CurrencyWorks; and (b) commissions on secondary sales
                      (if available) of Collectibles, established by and payable
                      to the creators of such Collectibles. Service fees may be
                      adjusted from time to time in our sole discretion.
                    </p>
                  </li>
                  <li>
                    <p>
                      Gas Fees: Every transaction on the{' '}
                      <b>CurrencyWorks Blockchain</b> requires the payment of a
                      transaction fee (each, a “<b>Gas Fee</b>”). The Gas Fees
                      fund the network of computers that run the decentralized{' '}
                      <b>CurrencyWorks Blockchain</b>. This means that you will
                      need to pay a Gas Fee for each transaction that you
                      instigate via the Service regarding Collectibles. Except
                      as otherwise expressly set forth in these Terms, you will
                      be solely responsible to pay any Gas Fee for any
                      transaction that you instigate via the Service.
                    </p>
                  </li>
                  <li>
                    <p>
                      Responsibility for Taxes: Except as may be collected by us
                      as required by applicable law and as indicated on the
                      order page where you make purchases, you will be solely
                      responsible to pay any and all sales, use, value-added and
                      other taxes, duties, and assessments (except taxes on our
                      net income) now or hereafter claimed or imposed by any
                      governmental authority (collectively, the “<b>Taxes</b>”)
                      associated with your use of the Service and all purchases
                      of Collectibles.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <b>Intellectual Property Rights; Limited License</b>
                <ol>
                  <li>
                    <p>
                      Intellectual Property Rights in and to the Service and Our
                      Content: As between you and us, we and our third party
                      licensors own any and all right, title, and interest in
                      and to the Services and any and all data and content made
                      available in and through the Services, including all
                      software, computer code, tools, patches, updates, images,
                      text, graphics, illustrations, logos, photographs, images,
                      pictures, audio, sound effects, sound recordings,
                      features, functionality, design, presentation videos,
                      visual effects, music, music composition, user accounts,
                      and “look and feel” of the Services, and all intellectual
                      property rights related to the foregoing and the Services
                      (the “<b>Our Content</b>”). Put simply, the Services and
                      the Our Content (including all components thereof) are the
                      property of their respective owners and are protected by
                      copyright, trademark, patent, trade secret, and any other
                      proprietary rights.
                    </p>
                  </li>
                  <li>
                    <p>
                      Limited License to the Service: You acknowledge and agree
                      that you have no ownership rights in the Services or Our
                      Content. Subject to all of the Terms, we grant you a
                      non-exclusive, personal, non-transferable,
                      non-sublicensable, revocable, limited license to access
                      and use the Services and to access the Our Content solely
                      for your personal use. We reserve all rights not expressly
                      granted herein in the Services and Our Content. You
                      acknowledge that your use of Our Content for any purpose
                      not expressly permitted by these Terms is strictly
                      prohibited. Without limiting the restrictions in Section
                      11: (a) neither Our Content nor the design or layout of
                      the Services, nor any part or component of any of the
                      foregoing, may be downloaded, copied, reproduced,
                      distributed, transmitted, broadcast, displayed, sold,
                      licensed, or otherwise exploited for any purpose
                      whatsoever without our or, where applicable, our
                      licensors’ prior written consent; and (b) you cannot
                      create any work of authorship or proprietary right based
                      on the Services (including Our Content). You will only use
                      the Services as may be expressly provided in this
                      Agreement or to the extent permitted by law or (if
                      applicable) relevant open source licenses. We and our
                      licensors reserve all rights not expressly granted in and
                      to their content.
                    </p>
                  </li>
                  <li>
                    Intellectual Property Rights in and to the Collectibles and
                    Collectible Content:
                    <ol>
                      <li>
                        <p>
                          <i>Ownership of Collectible</i>. When you purchase a
                          Collectible, you are purchasing an NFT that is
                          associated with certain Collectible Content. To
                          clarify, when you purchase a Collectible, you are not
                          purchasing the associated Collectible Content but only
                          the unique NFT specifically associated with that
                          Collectible Content. All Collectible Content is
                          licensed under these Terms and not sold and you do not
                          have any legal ownership, right, or title to any
                          copyrights, trademarks, or other intellectual property
                          rights to the Collectible Content, excepting the
                          limited license to the Collectible Content granted by
                          these Terms and as modified by the additional terms
                          and conditions that may apply to a given Collectible
                          as noted above. Your ownership of Collectibles will
                          only be recognized by us if you have purchased or
                          otherwise rightfully acquired such Collectibles from
                          us and is subject to both our or the Seller’s full
                          receipt of the full purchase price of the Collectible
                          and your full compliance with these Terms.
                        </p>
                      </li>
                      <li>
                        <p>
                          <i>License to Collectible Content</i>. Subject at all
                          times to your full compliance with these Terms, when
                          you purchase a Collectible from us, we grant you a
                          worldwide and non-exclusive license to display the
                          Collectible Content associated with your purchased
                          Collectible for only your own personal, non-commercial
                          use as part of your personal collection for display on
                          the Service. You agree that you may not, nor may you
                          permit any third party to do or attempt to do any of
                          the foregoing without our (or, as applicable, our
                          licensors’) express prior written consent in each
                          case: (a) modify the Collectible Content for your
                          purchased Collectibles in any way, including, without
                          limitation, the shapes, designs, drawings, attributes,
                          or color schemes; (b) use the Collectible Content for
                          your purchased Collectible to advertise, market, or
                          sell any third-party product or service; (c) use the
                          Collectible Content for your purchased collectible in
                          connection with images, videos, or other forms of
                          media that depict hatred, intolerance, violence,
                          cruelty, or anything else that could reasonably be
                          found to constitute hate speech or otherwise infringe
                          upon the rights of others; (d) use the Collectible
                          Content for your purchased Collectible in print media,
                          photographs, videos, or any other forms of media, now
                          known or hereinafter devised, except to the limited
                          extent that such use is expressly permitted in these
                          Terms; (e) sell, distribute for commercial gain
                          (including, without limitation, giving away in the
                          hopes of eventual commercial gain), or otherwise
                          commercialize merchandise that includes, contains, or
                          consists of the Collectible Content for your purchased
                          Collectibles; (f) attempt to trademark, copyright, or
                          otherwise acquire additional intellectual property
                          rights in or to the Collectible Content for your
                          purchased Collectibles; or (g) otherwise utilize the
                          Collectible Content for your purchased Collectibles
                          for your or any third party’s commercial benefit.
                        </p>
                      </li>
                      <li>
                        <p>
                          <i>Third Party IP</i>. If the Collectible Content
                          associated with your purchased Collectible contains “
                          <b>Third Party IP</b>” (e.g., licensed intellectual
                          property from any of our licensors), you understand
                          and agree as follows: (a) that you will not have the
                          right to use such Third Party IP in any way except as
                          incorporated in the purchased Collectible, and subject
                          to the license and restrictions contained herein; (b)
                          that, depending on the nature of the license granted
                          from the owner of the Third Party IP, we may need to
                          (and reserve every right to) pass through additional
                          restrictions on your ability to use the Collectible
                          Content; and (c) to the extent that we inform you of
                          such additional restrictions in writing, you will be
                          responsible for complying with all such restrictions
                          from the date that you receive the notice, and that
                          failure to do so will be deemed a breach of the
                          license contained in this Section 6.
                        </p>
                      </li>
                      <li>
                        <p>
                          <i>Other Terms of License</i>. The license granted in
                          this Section 6 above applies only to the extent that
                          you continue to own the applicable purchased
                          Collectible. If at any time you sell, swap, donate,
                          give away, transfer, or otherwise dispose of your
                          purchased Collectible for any reason, the license
                          granted in this Section 6 will immediately expire with
                          respect to that Collectible without the requirement of
                          notice, and you will have no further rights in or to
                          the Collectible Content for that Collectible. The
                          terms of this Section 6 will survive the expiration or
                          termination of these Terms for any reason.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p>
                      Links: Where our Services may contain links to other sites
                      and resources provided by third parties, these links are
                      provided for your information only. We have no control
                      over the contents of those sites or resources. Such links
                      should not be interpreted as approval or endorsement by us
                      of those linked websites or information you may obtain
                      from them, and we are not responsible for any
                      consequences, losses, or damages that may arise from your
                      access or use of such sites or resources. For clarity, if
                      you choose to access any such sites or resources, you do
                      so at your own risk.
                    </p>
                  </li>
                  <li>
                    <p>
                      Advertising: We have the right, without notice, to insert
                      advertising data into the Services, so long as this does
                      not involve our transmission of any of your personal
                      information in contravention of the Privacy Policy. If you
                      elect to have any business dealings with any party whose
                      products or services may be advertised on the Services,
                      you acknowledge and agree that such dealings are solely
                      between you and such advertiser and we will not be a party
                      to, or have any responsibility or liability related
                      thereto. You acknowledge and agree that no such
                      advertising may be construed as an endorsement by us of
                      any such products or services advertised.
                    </p>
                  </li>
                  <li>
                    <p>
                      Trademarks, Brands and Logos: Other than trademarks,
                      brands and logos that we expressly indicate belong to us,
                      all other registered or unregistered trademarks, brands
                      and logos found on the Service or in any Collectible
                      Content belong to their respective owners. Nothing
                      contained on the Service or in any Collectible Content
                      should be construed as granting, by implication, estoppel,
                      or otherwise, any license or right to use any such
                      trademarks, brands or logos without the written permission
                      of us or such third party that owns the them. Furthermore,
                      no third-party trademarks, brands or logos imply any
                      endorsement of, or association with, the Service by such
                      respective owners thereof.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <b>Feedback and User Submissions</b>
                <p>
                  While we are continually working to develop and evaluate our
                  own product ideas and features, we also pay attention to the
                  interests, feedback, comments, and suggestions we receive from
                  our user community. You may choose to contribute by sending us
                  any ideas for products, services, features, modifications,
                  enhancements, content, refinements, technologies, content
                  offerings (such as audio, visual or other types of content),
                  promotions, strategies, or product or feature names, or any
                  related documentation, artwork, computer code, diagrams, or
                  other materials (collectively, “<b>Feedback</b>”). In
                  addition, the Service may contain interactive functionality
                  such as applications, features, promotions, games, contests,
                  chat functionality, e-mail, message boards, personal, or
                  interest group web pages, profiles, forums, bulletin boards
                  and other such functions (collectively, “
                  <b>Interactive Functions</b>“) allowing content, material, or
                  information you submit, post, publish, display, or transmit
                  (collectively, “<b>submit</b>“) to the Service, to be viewed
                  or used by us, other users or other persons via the Service (“
                  <b>User Content</b>”, and together with Feedback, “
                  <b>User Submissions</b>“). Regardless of what your
                  accompanying communication may say, in order to avoid any
                  misunderstandings the following terms will apply to all User
                  Submissions. Accordingly, by submitting User Submissions to
                  us, you agree that:
                </p>
                <ol>
                  <li>
                    We have no obligation to review, consider, or implement your
                    Feedback, or to return to you all or part of any User
                    Submissions for any reason;
                  </li>
                  <li>
                    User Submissions are provided on a non-confidential and
                    non-proprietary basis, and we are not under any obligation
                    to keep any User Submissions you send confidential or to
                    refrain from using or disclosing it in any way;
                  </li>
                  <li>
                    You irrevocably grant us an irrevocable, non-exclusive,
                    worldwide, perpetual, royalty-free, sub-licensable
                    (including via multiple tiers of sublicensing),
                    transferrable license to reproduce, distribute, create
                    derivative works of, modify, publicly perform, communicate
                    to the public, make available, publicly display, and
                    otherwise use and exploit the User Submissions and
                    derivatives thereof for any purpose and without restriction,
                    free of charge and without attribution of any kind,
                    including by making, using, selling, offering for sale,
                    importing, and promoting commercial products and services
                    that incorporate or embody User Submissions, whether in
                    whole or in part, and whether as provided or as modified;
                  </li>
                  <li>
                    You have been deemed to have warranted to us that you have
                    or own all the necessary legal rights to upload, post, or
                    submit such User Submissions and grant us (and our
                    affiliates and service providers, and each of their and our
                    respective licensees, successors, and assigns) the license
                    to the User Submissions, and that the User Submissions do
                    not and will not violate any law or the intellectual
                    property, privacy, publicity, or other rights of any person;
                  </li>
                  <li>
                    You understand and agree that you are fully responsible for
                    any User Submissions you submit or contribute, and you are
                    fully responsible and legally liable, including to any third
                    party, for such content, its accuracy, and your rights to
                    use it; and
                  </li>
                  <li>
                    <p>
                      You waive any moral rights or other rights of authorship
                      in and to any User Submissions in favor of us, including
                      any rights you may have in the altered or changed User
                      Submissions even if it is no longer agreeable to you.
                    </p>
                    <p>
                      We have the right, without provision of notice to remove
                      or refuse to post on the Service any User Content for any
                      or no reason in our sole and absolute discretion; monitor,
                      filter, revise, edit, remove or refuse content using
                      manual or automated third-party content moderation
                      services; at all times, take such actions with respect to
                      any User Submission deemed necessary or appropriate in our
                      sole and absolute discretion, including for violating
                      these Terms; take appropriate legal action, including
                      referral to law enforcement or regulatory authority, or
                      notifying the harmed party of any illegal or unauthorized
                      use of the Service. Without limiting the foregoing, we
                      have the right to fully cooperate with any law enforcement
                      authorities or court order requesting or directing us to
                      disclose the identity or other information of anyone
                      posting any materials on or through the Service; and
                      terminate or suspend your access to all or part of the
                      Service for any or no reason, including any violation of
                      these Terms.
                    </p>
                    <p>
                      While we will take steps to monitor content and User
                      Content in the Service, we make no representations or
                      guarantees that we will be able to review all material
                      that you or other users submit to the Service or to do so
                      in a timely manner. We cannot ensure prompt removal of
                      objectionable material after it has been posted and we
                      have no liability for any action or inaction regarding
                      transmissions, communications, or content provided by any
                      user or third party, subject to applicable laws.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <b>Equipment Requirement</b>
                <p>
                  To use the Services, you may need certain hardware, software,
                  capabilities and other resources (including a suitable
                  connection to the Internet). You are solely responsible for
                  procuring and maintaining such resources at your own cost.
                </p>
              </li>
              <li>
                <b>Updates, Interruption, and Termination</b>
                <ol>
                  <li>
                    Updates: From time to time, we have the right (but not
                    obligation) to provide updates (patches, new features, etc.)
                    to the Services for free or for a fee. You understand that
                    your access to certain features of the Services might be
                    affected by such updates. You also understand that such
                    updates may affect the necessary system specifications
                    required to use the Services. In such case, you are
                    responsible for any necessary equipment to continue to
                    access the Services.
                  </li>
                  <li>
                    Interruption: You acknowledge that the Services or any part
                    thereof may be interrupted for maintenance or reasons beyond
                    our control, and we in general cannot guarantee that the
                    Services will be uninterrupted. We will not be liable for
                    any interruption of the Services, delay, or failure to
                    perform resulting from any causes whatsoever. Additionally,
                    the Services may be unavailable depending on geographic
                    location. To the maximum extent permitted by applicable law,
                    we reserve the right to discontinue the Services at any time
                    in our sole discretion, for any reason, or for no reason,
                    with or without notice.
                  </li>
                  <li>
                    Suspension or Termination of Access: To the extent allowable
                    by applicable law, we reserve the right to suspend or
                    terminate your access to the Services at any time for any
                    reason, including if you have failed to comply with any of
                    the provisions of these Terms, or if activities occur on
                    your account which would or might (i) cause damage to or
                    impair the Services or infringe or violate any third party
                    rights (including intellectual property rights), or (ii)
                    violate any applicable laws or regulations. Termination or
                    suspension of your account also entails the termination or
                    suspension of your license to use the Services, or any part
                    thereof. If we suspend or terminate your account, we will
                    notify you by email.
                  </li>
                  <li>
                    <p>
                      Termination of the Service: If we decide, in our sole
                      discretion, to shut down the Service, we will use
                      commercially reasonable efforts to provide you with
                      reasonable advance notice via the contact information you
                      provided to us and posted on the Service itself.{' '}
                      <b>
                        IN THE EVENT THAT WE TERMINATE THE SERVICE, FOR ANY
                        REASON, YOUR COLLECTIBLES WILL BE PERMANENTLY AND
                        IRREVOCABLY DELETED.
                      </b>
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <b>Code of Conduct</b>
                <p>
                  As a condition of your access to and use of the Services, you
                  agree that you will use the Services only for lawful purposes
                  in accordance with these Terms and all applicable laws and
                  regulations. You may not:
                </p>
                <ol>
                  <li>
                    access or use the Services if you are not fully able and
                    legally competent to agree to these Terms;
                  </li>
                  <li>
                    violate, or promote the violation of, any applicable
                    federal, provincial, local, foreign, or international law or
                    regulation, including laws prohibiting transactions with or
                    investments in specified individuals, entities, governments,
                    states, and nationals thereof and any laws regarding the
                    export of data or software, patent, trademark, trade secret,
                    copyright, or other intellectual property or legal rights
                    (including the rights of publicity and privacy of others);
                  </li>
                  <li>
                    violate the terms of use of any third-party website or
                    service that is linked to the Services, including any
                    third-party social media websites or payment processors;
                  </li>
                  <li>
                    except as expressly permitted by these Terms or applicable
                    law, directly or indirectly make unauthorized copies,
                    modify, adapt, translate, reverse engineer, disassemble,
                    decompile, publicly display, republish, download, store,
                    transmit or create any derivative works of the Services or
                    any content included therein, including any software (except
                    that your computer and browser may temporarily store or
                    cache copies of materials being accessed and viewed), or
                    determine or attempt to determine any source code,
                    algorithms, methods, or techniques embodied by the Services
                    or any derivative works thereof;
                  </li>
                  <li>
                    distribute, license, transfer, or sell, in whole or in part,
                    any of the Services or any derivative works thereof;
                  </li>
                  <li>
                    modify copies of any materials from the Services nor delete
                    or alter any copyright, trademark, or other proprietary
                    rights notices from copies of materials from the Services;
                  </li>
                  <li>
                    market, rent, or lease the Services for a fee or charge, or
                    use the Services to advertise or perform any commercial
                    solicitation;
                  </li>
                  <li>
                    use the Services or any part thereof, without our express
                    written consent, for any commercial, political, or
                    unauthorized purpose, including communicating or
                    facilitating any commercial advertisement or solicitation or
                    spamming;
                  </li>
                  <li>
                    purchase, sell, or otherwise trade any Collectibles using,
                    operating, employing any computer program, including without
                    limitation, computer programs designed to simulate the
                    behavior of a user or to otherwise automate the purchasing,
                    sale, or trading of Collectibles (a “Bot”);
                  </li>
                  <li>
                    purchase or sell your Service account or the wallet paired
                    with your Service account;
                  </li>
                  <li>
                    engage in wash trading or other deceptive or manipulative
                    trading activities;
                  </li>
                  <li>
                    use the Service to participate in fundraising for a
                    business, protocol, or platform, including but not limited
                    to creating, listing, or buying assets that are redeemable
                    for financial instruments, assets that give owners rights to
                    participate in a securities offering, or assets that entitle
                    owners to financial rewards, including but not limited to,
                    yield bonuses, staking bonuses, and burn discounts;
                  </li>
                  <li>
                    interfere with or attempt to interfere with the proper
                    working of the Services, disrupt our website (if any), or
                    any networks connected to the Services, or bypass any
                    measures we may use to prevent or restrict access to the
                    Services;
                  </li>
                  <li>
                    incorporate the Services or any portion thereof into any
                    other program or product;
                  </li>
                  <li>
                    use automated scripts, software, code, or systems to collect
                    information from or otherwise interact with the Services;
                  </li>
                  <li>
                    impersonate any person or entity, or falsely state or
                    otherwise misrepresent you or your affiliation with any
                    person or entity, including giving the impression that any
                    content you upload, post, transmit, distribute, or otherwise
                    make available emanates from the Services;
                  </li>
                  <li>
                    encourage any conduct that restricts or inhibits anyone’s
                    use or enjoyment of the Services, or which, as determined by
                    us, may harm us or users of the Services or expose us or
                    them to liability;
                  </li>
                  <li>
                    involve, provide, or contribute any false, inaccurate, or
                    misleading information;
                  </li>
                  <li>
                    impersonate or attempt to impersonate us, one of our
                    employees, another user, or any other person or entity
                    (including by using email addresses, or screen names
                    associated with any of the foregoing);
                  </li>
                  <li>
                    involve stalking, attempting to exploit or harm any
                    individual (including minors) in any way by exposing them to
                    inappropriate content or otherwise or ask for personal
                    information as prohibited under applicable laws,
                    regulations, or code;
                  </li>
                  <li>
                    promote sexually explicit material, violence, or
                    discrimination based on race, sex, religion, nationality,
                    disability, sexual orientation, or age;
                  </li>
                  <li>
                    use or attempt to use another user’s account, service, or
                    system without authorization from us;
                  </li>
                  <li>
                    use the Services in a manner that may create a conflict of
                    interest or undermine the purposes of the Services;
                  </li>
                  <li>
                    use or exploit any bots, hacks, bugs, errors, or design
                    flaws to obtain unauthorized access to the Services;
                  </li>
                  <li>
                    use the Services to upload, transmit, distribute, store, or
                    otherwise make available in any way:
                    <ol>
                      <li>
                        files that contain viruses, trojans, worms, logic bombs,
                        or other material that is malicious or technologically
                        harmful; any unsolicited or unauthorized advertising,
                        solicitations, promotional materials, “junk mail,”
                        “spam,” “chain letters,” “pyramid schemes,” or any other
                        prohibited form of solicitation; any private information
                        of any third party, including addresses, phone numbers,
                        email addresses, number and feature in the personal
                        identity document (e.g., social security numbers,
                        passport numbers, etc.), or credit card numbers; any
                        material which does or may infringe any copyright, trade
                        mark, or other intellectual property or privacy rights
                        of any other person; any material which is defamatory of
                        any person, obscene, violent, sexually explicit,
                        offensive, pornographic, hateful, or inflammatory; any
                        material that would constitute, encourage, or provide
                        instructions for a criminal offence, dangerous
                        activities, or self-harm; any material that is
                        deliberately designed to provoke or antagonize people,
                        especially trolling and bullying, or is intended to
                        harass, harm, intimidate, hurt, scare, distress,
                        embarrass, or upset people; any material that contains a
                        threat of any kind, including threats of physical
                        violence; any material that is racist or discriminatory,
                        including discrimination on the basis of someone’s race,
                        religion, age, gender, disability, or sexuality;
                      </li>
                      <li>
                        any answers, responses, comments, opinions, analysis, or
                        recommendations that you are not properly licensed or
                        otherwise qualified to provide;
                      </li>
                      <li>
                        any material that could give rise to any civil or
                        criminal liability under applicable laws or regulations
                        or that otherwise may be in conflict with these Terms
                        and our Privacy Policy available here{' '}
                        <Link to='/privacy-policy'>
                          <b>motoclub.io/privacypolicy</b>
                        </Link>
                        ; and/or
                      </li>
                      <li>
                        material that restricts or inhibits any other person
                        from using the Services, or which may expose
                        CurrencyWorks, the Services, or its users to any harm or
                        liability of any type.
                        <p>
                          We reserve the right, at any time and without prior
                          notice, to remove or disable your access to the
                          Services, including the Our Content, at our discretion
                          for any reason or no reason. Some of the reasons for
                          which we may remove or disable your access to the
                          Services may include finding that you have violated
                          these Terms, or that your actions are harmful to the
                          Services or our users. You agree and understand that
                          your continued use of the Services is at our sole and
                          absolute discretion and that you will not seek to hold
                          us liable for any suspension, restriction, or
                          termination of our use of the Services.
                        </p>
                        <p>
                          While we will take steps to monitor content and
                          Feedback in the Services, we make no representations
                          or guarantees that we will be able to review all
                          material that you or other users submit to the
                          Services or to do so in a timely manner. We cannot
                          ensure prompt removal of objectionable material after
                          it has been posted and we have no liability for any
                          action or inaction regarding transmissions,
                          communications, or content provided by any user or
                          third party, subject to applicable laws.
                        </p>
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                <p className='fw-bold'>Exports and Location</p>
                <p>
                  You agree that you will not export, re-export, sell, or
                  transfer, directly or indirectly the Services and other
                  information or materials provided by us hereunder, to any
                  country for which the United States, or any other relevant
                  jurisdiction requires any export license or other governmental
                  approval at the time of export without first obtaining such
                  license or approval. In particular, but without limitation,
                  the Services may not be exported or re-exported: (a) into any
                  comprehensively embargoed countries or any country that your
                  jurisdiction’s government has included on any official
                  terrorism or terrorism-related lists; (b) to any governments
                  of such countries; or (c) to anyone listed on your
                  jurisdiction’s list of prohibited or restricted parties,
                  including the U.S. Treasury Department’s list of Specially
                  Designated Nationals, the U.S. Department of Commerce Denied
                  Person’s List or Entity List, or Public Safety Canada’s
                  terrorist entities list.We administer and operate the Services
                  from the United States. Although the Services are accessible
                  in many territories throughout the world, not all features,
                  products or Services discussed, referenced, provided or
                  offered are available to all persons or in all geographic
                  locations, or appropriate or available for use outside the
                  United States. We reserve the right to limit, in our sole
                  discretion, the provision and quantity of any feature, product
                  or Service to any person or geographic area. Any offer for any
                  feature, product or Service made is void where prohibited. If
                  you choose to access the Services from outside the United
                  States you do so on your own initiative and you are solely
                  responsible for complying with applicable local laws.
                </p>
              </li>
              <li>
                <p className='fw-bold'>Indemnity</p>
                <p>
                  To the maximum extent permitted by law, you agree to, at your
                  sole cost, defend, indemnify, and hold harmless CurrencyWorks,
                  our parents, subsidiaries, and affiliates, and each of their
                  respective officers, directors, employees, agents, suppliers,
                  and advisors, from and against any and all claims,
                  liabilities, costs, fines, penalties, and expenses, including
                  legal fees and expenses, arising out of or in any way
                  connected with, whether alleged or actual: (i) a breach by
                  you, or any user of your account, of any applicable
                  obligation, representation, or warranty under these Terms;
                  (ii) the content of or any inaccuracy in your Feedback; (iii)
                  our use or publication of your Feedback, including if such
                  infringes any third party intellectual property rights; (iv)
                  your unauthorized access to or use of, or activities in
                  connection with, the Services; (v) your violation of any
                  applicable laws, rules, regulations, or contracts; or (vi) any
                  misrepresentation made by you (all of the foregoing,
                  “Indemnified Claims”). You will cooperate as fully required by
                  us in the defense of any Indemnified Claims. Notwithstanding
                  the foregoing, we retain the exclusive right to settle,
                  compromise, and pay any and all Indemnified Claims. We reserve
                  the right to assume the exclusive defense and control of any
                  Indemnified Claims at our own cost. You will not settle any
                  Indemnified Claims without, in each instance, our prior
                  written consent or the consent of an individual whom we
                  authorize, in writing, to approve such settlement.
                </p>
              </li>
              <li>
                <p className='fw-bold'>Jurisdiction Specific Disclaimers</p>
                <p>
                  <b>CONSUMERS IN QUEBEC, CANADA</b>: QUEBEC’S CONSUMER
                  PROTECTION ACT (CQLR C P-40.1) PROVIDES YOU WITH CERTAIN
                  RIGHTS, INCLUDING WARRANTIES AS TO ACCEPTABLE QUALITY, SAFETY,
                  DURABILITY, ACCURATE DESCRIPTION AND AGAINST HIDDEN DEFECTS.
                  NOTHING IN THIS SECTION IS INTENDED TO LIMIT OR REPLACE ANY OF
                  YOUR RIGHTS UNDER THE CONSUMER PROTECTION ACT (CQLR C P-40.1),
                  AND TO THE EXTENT THAT IT IS PROHIBITED BY LAW, THE EXCLUSION
                  HEREUNDER DOES NOT APPLY TO QUEBEC CONSUMERS.
                </p>
                <p>
                  <strong>IN ADDITION TO THE LIMITATIONS IN QUEBEC</strong>,
                  CERTAIN JURISDICTIONS DO NOT ALLOW LIMITATIONS ON IMPLIED
                  WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES.
                  IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE BELOW
                  DISCLAIMERS, EXCLUSIONS OR LIMITATIONS IN “EXCLUSION OF
                  WARRANTIES” AND “LIMITATION OF LIABILITY” MAY NOT APPLY TO
                  YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS. NOTHING IN THESE
                  TERMS AND CONDITIONS IS INTENDED TO EXCLUDE OR LIMIT ANY
                  CONDITION, WARRANTY, RIGHT OR LIABILITY THAT MAY NOT BE
                  LAWFULLY EXCLUDED OR LIMITED. HOWEVER, TO THE MAXIMUM
                  PERMITTED EXTENT UNDER APPLICABLE LAWS, YOU AGREE THAT ALL
                  PROVISIONS OF THESE ARE INTENDED TO APPLY TO YOU.
                </p>
              </li>
              <li>
                <b>Risks</b>
                <ol>
                  <li>
                    <p>
                      Use of Blockchain Technology. We may use experimental
                      cryptographic technologies and blockchain technologies,
                      including tokens, cryptocurrencies, stablecoins, “smart
                      contracts,” consensus algorithms, voting systems and
                      distributed, decentralized or peer-to-peer networks or
                      systems in performing the Service. You hereby acknowledge
                      and agree that such technologies are novel, experimental,
                      and speculative, and that therefore there is significant
                      uncertainty regarding the operation and effects and risks
                      thereof and the application of existing law thereto.
                    </p>
                  </li>
                  <li>
                    <p>
                      Certain Risks of Blockchain Technology. The technology
                      utilized in delivering the Service may depend on public
                      peer-to-peer networks such as the Parachain that are not
                      under our control or influence and are subject to many
                      risks and uncertainties. You are solely responsible for
                      the safekeeping of the private key associated with any
                      blockchain address used by you to participate in the
                      Service.{' '}
                      <b>
                        WE WILL NOT BE ABLE TO RESTORE OR ISSUE ANY REFUND IN
                        RESPECT OF ANY COLLECTIBLES LOST DUE TO LOST PRIVATE
                        KEYS
                      </b>
                      .
                    </p>
                  </li>
                  <li>
                    <p>
                      Certain Risks of Smart Contract Technology. Collectibles
                      and other digital assets relevant to the Service depend on
                      smart contracts deployed to the Parachain, some of which
                      may be coded or deployed by persons other than us. Once
                      deployedParachain, the code of smart contracts, cannot be
                      modified.{' '}
                      <b>
                        IN THE EVENT THAT THE SMART CONTRACTS ARE ADVERSELY
                        AFFECTED BY MALFUNCTIONS, BUGS, DEFECTS, MALFUNCTIONS,
                        HACKING, THEFT, ATTACKS, NEGLIGENT CODING OR DESIGN
                        CHOICES, OR CHANGES TO THE PROTOCOL RULES OF THE
                        PARACHAIN, YOU MAY BE EXPOSED TO A RISK OF TOTAL LOSS
                        AND FORFEITURE OF ALL COLLECTIBLES
                      </b>
                      . We assume no liability or responsibility for any of the
                      foregoing matters, except as otherwise expressly provided
                      by these Terms or required by applicable law.
                    </p>
                  </li>
                  <li>
                    <p>
                      Collectible Value. The fiat-denominated prices and value
                      in public markets of assets such as Collectibles have
                      historically been subject to dramatic fluctuations and are
                      highly volatile. As relatively new products and
                      technologies, blockchain-based assets are not widely
                      accepted as a means of payment for goods and services. A
                      significant portion of demand for these assets is
                      generated by speculators and investors seeking to profit
                      from the short- or long-term holding of blockchain assets.
                      The market value of any Collectible may decline below the
                      price for which you acquired such asset through the
                      Service or on any other platform. You acknowledge and
                      agree that the costs and speeds of transacting with
                      cryptographic and blockchain-based systems such as the
                      Parachain are variable and may increase or decrease
                      dramatically at any time, resulting in prolonged inability
                      to access or use any Collectibles or other digital assets
                      associated with the Service.
                    </p>
                  </li>
                  <li>
                    <p>
                      Regulatory Uncertainty. Blockchain technologies and
                      digital assets are subject to many legal and regulatory
                      uncertainties, and the Service and the Collectibles could
                      be adversely impacted by one or more regulatory or legal
                      inquiries, actions, suits, investigations, claims, fines
                      or judgments, which could impede or limit your ability to
                      continue the use and enjoyment of such assets and
                      technologies.
                    </p>
                  </li>
                  <li>
                    <p>
                      Cryptography Risks. Cryptography is a progressing field.
                      Advances in code cracking or technical advances such as
                      the development of quantum computers may present risks to
                      the Parachain, the Service, Collectibles and NFTs
                      generally, including the theft, loss or inaccessibility
                      thereof.
                    </p>
                  </li>
                  <li>
                    <p>
                      Changes to or Termination of the Service. As set out in
                      “Updates, Interruption and Termination” and “Modifications
                      to Services or Pricing” above, we reserve the right, and
                      may in fact, make changes to the Services from time to
                      time. Furthermore, there is always a risk that we may
                      elect to terminate the Service in whole or in part, or in
                      particular relation to any group of users, at any time as
                      set out in such provisions. These changes or that
                      termination may significantly affect any Collectibles or
                      Collectible Content.
                    </p>
                  </li>
                  <li>
                    WITHOUT LIMITING THE FOREGOING, YOU ACCEPT AND ACKNOWLEDGE:
                    <ol>
                      <li>
                        <p>
                          THE PRICES OF COLLECTIBLES MAY BE EXTREMELY VOLATILE,
                          AND FLUCTUATIONS IN THE PRICE OF OTHER DIGITAL ASSETS
                          COULD MATERIALLY AND ADVERSELY AFFECT THE
                          COLLECTIBLES, WHICH MAY ALSO BE SUBJECT TO SIGNIFICANT
                          PRICE VOLATILITY. WHEN YOU ACQUIRE A COLLECTIBLE, YOU
                          ARE RECEIVING THE FULL VALUE THEREFOR BY THE TRANSFER
                          OF THE COLLECTIBLE, AND NO PART OF ANY TRANSACTION
                          INVOLVING A COLLECTIBLE INCLUDES ANY GUARANTEE FROM US
                          OR ANY OTHER PERSON THAT ANY HOLDER OF A COLLECTIBLE
                          WILL NOT LOSE MONEY OR THAT THE COLLECTIBLE WILL
                          INCREASE IN VALUE.
                        </p>
                      </li>
                      <li>
                        <p>
                          THE COLLECTIBLES ARE INTANGIBLE, DIGITAL ASSETS. THEY
                          EXIST ONLY BY VIRTUE OF THE OWNERSHIP RECORD
                          MAINTAINED IN THE PARACHAIN OR ANY BLOCKCHAIN NETWORK.
                          ANY TRANSFER OF TITLE THAT MIGHT OCCUR IN ANY UNIQUE
                          DIGITAL ASSET OCCURS ON THE DECENTRALIZED LEDGER
                          WITHIN THE PARACHAIN OR ITS ASSOCIATED BLOCKCHAIN
                          PLATFORM. EXCEPT TO THE EXTENT WE ARE ABLE TO CONTROL
                          THE PARACHAIN, WE DO NOT GUARANTEE THAT WE OR ANY
                          SELLER CAN EFFECT THE TRANSFER OF TITLE OR RIGHT IN
                          ANY COLLECTIBLES.
                        </p>
                      </li>
                      <li>
                        <p>
                          YOU ARE SOLELY RESPONSIBLE FOR DETERMINING WHAT, IF
                          ANY, TAXES APPLY TO YOUR COLLECTIBLES TRANSACTIONS,
                          AND NEITHER THE COMPANY NOR ANY OTHER PERSON BESIDES
                          YOU ARE RESPONSIBLE FOR DETERMINING THE TAXES THAT
                          APPLY TO YOUR TRANSACTIONS.
                        </p>
                      </li>
                      <li>
                        <p>
                          APPLICABLE LAWS, REGULATIONS, OR POLICIES
                          (COLLECTIVELY, “<b>LAWS</b>”) OR LAWS PASSED ON THE
                          FUTURE GOVERNING BLOCKCHAIN TECHNOLOGIES,
                          CRYPTOCURRENCIES, NFTS AND COLLECTIBLES ARE UNCERTAIN,
                          AND NEW APPLICATIONS OF EXISTING LAWS OR NEW LAWS MAY
                          MATERIALLY ADVERSELY AFFECT THE DEVELOPMENT OF THE
                          MARKETPLACE, THE SERVICE, OR THE UTILITY OF
                          COLLECTIBLES.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p>
                      Secondary Trading. The Services do not currently include a
                      marketplace for the secondary trading of Collectibles.
                      While we intend to provide such a marketplace shortly, we
                      cannot guarantee if or when such marketplace will exist,
                      and you bear the risk that there may never exist a
                      marketplace to resell any Collectibles.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <b>
                  <b>
                    EXCLUSION OF WARRANTIES
                    <br />
                  </b>
                </b>
                <p>
                  TO THE MAXIMUM EXTENT PERMITTED BY LAW: YOU UNDERSTAND AND
                  AGREE THAT YOUR USE OF AND ACCESS TO THE SERVICE, INCLUDING
                  THE PARACHAIN, IS AT YOUR OWN RISK AND THAT (I) THE SERVICES,
                  INCLUDING THE PARACHAIN, ARE PROVIDED ON AN “AS IS” AND “AS
                  AVAILABLE” BASIS FOR YOUR USE; AND (II) WE EXPRESSLY DISCLAIM
                  AND EXCLUDE ALL WARRANTIES, INCLUDING ALL CONDITIONS,
                  WARRANTIES, OR OTHER TERMS, WHETHER STATUTORY, EXPRESS, OR
                  IMPLIED (INCLUDING ANY IMPLIED WARRANTIES AS TO
                  MERCHANTABILITY, NON-INFRINGEMENT, SATISFACTORY QUALITY,
                  FITNESS FOR A PARTICULAR PURPOSE, OR CONFORMANCE WITH
                  DESCRIPTION), EXCEPT TO THE EXTENT THAT THEY ARE EXPRESSLY SET
                  OUT IN THESE TERMS.
                </p>
                <p>
                  IN PARTICULAR NONE OF THE CURRENCYWORKS PARTIES REPRESENT OR
                  WARRANT TO YOU THAT:
                </p>
                <ul>
                  <li>YOUR USE OF THE SERVICES WILL MEET YOUR REQUIREMENTS;</li>
                  <li>
                    YOUR USE OF THE SERVICES WILL BE UNINTERRUPTED, TIMELY,
                    SECURE, OR FREE FROM ERROR;
                  </li>
                  <li>
                    THE SERVICES ARE FREE OF VIRUSES OR OTHER HARMFUL
                    COMPONENTS;
                  </li>
                  <li>
                    ANY INFORMATION OBTAINED BY YOU AS A RESULT OF YOUR USE OF
                    THE SERVICES WILL BE ACCURATE OR RELIABLE;
                  </li>
                  <li>
                    DEFECTS IN THE OPERATION OR FUNCTIONALITY OF ANY SOFTWARE
                    PROVIDED TO YOU AS PART OF THE SERVICES WILL BE CORRECTED;
                    OR
                  </li>
                  <li>
                    <p>
                      THE CONTENT OBTAINED THROUGH THE SERVICES IS ACCURATE,
                      COMPLETE, OR RELIABLE.
                    </p>
                  </li>
                </ul>
                <p>
                  WE MAY CHANGE, SUSPEND, WITHDRAW, OR RESTRICT THE AVAILABILITY
                  OF ALL OR ANY PART OF THE SERVICES FOR BUSINESS AND
                  OPERATIONAL REASONS AT ANY TIME WITHOUT NOTICE. YOU ARE
                  RESPONSIBLE FOR VERIFYING ANY INFORMATION OBTAINED THROUGH THE
                  SERVICES BEFORE RELYING ON IT. YOU FREELY ACCEPT AND
                  VOLUNTARILY AGREE TO ASSUME ALL RISKS, INCLUDING OF PERSONAL
                  INJURY, DEATH, AND PROPERTY DAMAGE OR LOSS, CONNECTED WITH
                  YOUR USE OF THE SERVICES HOWSOEVER ARISING
                </p>
                <p>
                  YOUR USE OF THE SERVICES DEPENDS ON THE INTERNET, INCLUDING
                  NETWORKS, CABLING, FACILITIES, AND EQUIPMENT THAT IS NOT IN
                  OUR CONTROL. ACCORDINGLY: (I) WE CANNOT GUARANTEE ANY MINIMUM
                  LEVEL REGARDING SUCH PERFORMANCE, SPEED, RELIABILITY,
                  AVAILABILITY, USE, OR CONSISTENCY; AND (II) YOU ACKNOWLEDGE
                  AND AGREE THAT DATA, MESSAGES, INFORMATION, OR MATERIALS SENT
                  OVER THE INTERNET MAY NOT BE COMPLETELY PRIVATE, AND YOUR
                  ANONYMITY IS NOT GUARANTEED.
                </p>
                <p>
                  TO THE FULLEST EXTENT PROVIDED BY LAW, THE CURRENCYWORKS
                  PARTIES WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY
                  USER ERROR INCLUDING YOUR FAILURE TO REMEMBER OR LOSS OF
                  PASSWORDS OR SIMILAR AUTHENTICATORS SUCH AS PRIVATE KEYS,
                  FAILURES OF THE PUBLIC INTERNET, FAILURES OF THE UNDERLYING
                  BLOCKCHAIN TECHNOLOGY INCLUDING FORKS, TECHNICAL NODE ISSUES,
                  OR ANY OTHER BLOCKCHAIN FAILURES WHICH MAY RESULT IN YOU
                  INCURRING A LOSS, DENIAL-OF-SERVICE ATTACK, DISTRIBUTED
                  DENIAL-OF-SERVICE ATTACK, OVERLOADING, FLOODING, FAILURE OF
                  YOUR WALLET INCLUDING BUT NOT LIMITED TO CORRUPTED WALLET
                  FILES, MAILBOMBING, OR CRASHING, VIRUSES, TROJAN HORSES,
                  WORMS, LOGIC BOMBS, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL
                  THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS,
                  DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE
                  SERVICES, THE PARACHAIN OR ANY SERVICES, COLLECTIBLES, OR
                  ITEMS FOUND OR ATTAINED THROUGH THE SERVICES, THE PARACHAIN OR
                  TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY
                  SERVICES LINKED TO IT.
                </p>
              </li>
              <li>
                <b>NO RELIANCE</b>
                <p>
                  All information provided by or on behalf of us is for
                  informational purposes only and should not be construed as
                  professional, accounting or legal advice. You should not take
                  or refrain from taking any action in reliance on any
                  information contained in these Terms or provided by or on
                  behalf of us. Before you make any financial, legal, or other
                  decisions involving the Service or any Collectible, you should
                  seek independent professional advice from persons licensed and
                  qualified in the area for which such advice would be
                  appropriate.
                </p>
              </li>
              <li>
                <b>LIMITATION OF LIABILITY</b>
                <p>
                  NOTHING IN THESE TERMS WILL EXCLUDE OR LIMIT OUR LIABILITY FOR
                  LOSSES WHICH MAY NOT BE LAWFULLY EXCLUDED OR LIMITED BY
                  APPLICABLE LAW. SUBJECT TO THE FOREGOING, IN NO EVENT WILL THE
                  CURRENCYWORKS PARTIES BE LIABLE (JOINTLY OR SEVERALLY) TO YOU
                  OR ANY OTHER PERSON FOR INDIRECT, CONSEQUENTIAL, SPECIAL,
                  INCIDENTAL, PUNITIVE, OR EXEMPLARY DAMAGES, LOST PROFITS
                  (WHETHER INCURRED DIRECTLY OR INDIRECTLY), LOST SAVINGS, LOST
                  REVENUES, LOST GOODWILL, BUSINESS INTERRUPTION, OR LOST
                  OPPORTUNITY (COLLECTIVELY, THE “<b>EXCLUDED DAMAGES</b>“).
                  THESE LIMITATIONS APPLY WHETHER THE ALLEGED LIABILITY IS BASED
                  ON TORT (INCLUDING NEGLIGENCE), CONTRACT, OR OTHER THEORY OF
                  LIABILITY, EVEN IF ANY OF THE CURRENCYWORKS PARTIES HAVE BEEN
                  ADVISED OF THE POSSIBILITY OF OR COULD HAVE FORESEEN ANY OF
                  THE EXCLUDED DAMAGES, AND IRRESPECTIVE OF ANY FAILURE OF AN
                  ESSENTIAL PURPOSE OF A LIMITED REMEDY, AND YOU HEREBY WAIVE,
                  RELEASE, AND FOREVER DISCHARGE THE CURRENCYWORKS PARTIES FROM
                  AND AGAINST ALL OF THE EXCLUDED DAMAGES. IF ANY APPLICABLE
                  AUTHORITY HOLDS ANY PORTION OF THIS SECTION TO BE
                  UNENFORCEABLE, THEN THE CURRENCYWORKS PARTIES’ LIABILITY WILL
                  BE LIMITED TO THE FULLEST POSSIBLE EXTENT PERMITTED BY
                  APPLICABLE LAW.
                </p>
                <p>
                  WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, THE EXCLUDED
                  DAMAGES INCLUDE ANY LOSSES OR DAMAGES WHICH MAY BE INCURRED BY
                  YOU AS A RESULT OF:
                </p>
                <ul>
                  <li>
                    ANY RELIANCE PLACED BY YOU ON THE COMPLETENESS, ACCURACY, OR
                    EXISTENCE OF ANY ADVERTISING, OR AS A RESULT OF ANY
                    RELATIONSHIP OR TRANSACTION BETWEEN YOU AND ANY ADVERTISER
                    OR SPONSOR WHOSE ADVERTISING APPEARS ON THE SERVICES;
                  </li>
                  <li>
                    DUE TO VULNERABILITY OR ANY KIND OF FAILURE, ABNORMAL
                    BEHAVIOR OF SOFTWARE (SUCH AS WALLETS OR SMART CONTRACTS),
                    BLOCKCHAINS OR ANY OTHER FEATURES OF THE COLLECTIBLES;
                  </li>
                  <li>
                    LATE REPORTING OR NON-REPORTING BY DEVELOPERS OR MAINTAINERS
                    OF ANY ISSUES WITH THE BLOCKCHAINS SUPPORTING THE
                    COLLECTIBLES INCLUDING FORKS, TECHNICAL NODE ISSUES OR ANY
                    OTHER ISSUES
                  </li>
                  <li>
                    ANY CHANGES WHICH WE MAY MAKE TO THE SERVICES, OR FOR ANY
                    PERMANENT OR TEMPORARY CESSATION IN THE PROVISION OF THE
                    SERVICES (OR ANY FEATURES WITHIN THE SERVICES);
                  </li>
                  <li>
                    THE DELETION OF, CORRUPTION OF, OR FAILURE TO STORE ANY
                    COLLECTIBLE, COLLECTIBLE CONTENT, OR OTHER COMMUNICATIONS
                    DATA MAINTAINED OR TRANSMITTED BY OR THROUGH YOUR USE OF THE
                    SERVICES;
                  </li>
                  <li>
                    YOUR FAILURE TO PROVIDE US WITH ACCURATE ACCOUNT
                    INFORMATION; OR
                  </li>
                  <li>
                    <p>
                      YOUR FAILURE TO KEEP YOUR PASSWORD, PRIVATE KEY, OR
                      ACCOUNT DETAILS SECURE AND CONFIDENTIAL.
                    </p>
                  </li>
                </ul>
                <p>
                  YOU ARE RESPONSIBLE FOR ANY INTERNET OR MOBILE CHARGES THAT
                  MAY APPLY TO YOUR USE OF OUR SERVICES, INCLUDING
                  TEXT-MESSAGING AND DATA CHARGES. IF YOU’RE UNSURE WHAT THOSE
                  CHARGES MAY BE, YOU SHOULD ASK YOUR SERVICE PROVIDER BEFORE
                  USING THE SERVICE.
                </p>
                <p>
                  IN NO EVENT WILL CURRENCYWORKS PARTIES’ TOTAL, AGGREGATE
                  LIABILITY EXCEED, WITH RESPECT TO THE SERVICES, THE LESSER OF
                  (i) THE TOTAL AMOUNT PAID BY YOU TO US IN THE SIX-MONTH PERIOD
                  IMMEDIATELY PRIOR TO THE EVENT GIVING RISE TO THE CLAIM; AND
                  (ii) USD$100.00.
                </p>
              </li>
              <li>
                <b>Privacy Policy</b>
                <p>
                  You also acknowledge that our Privacy Policy, available here{' '}
                  <Link to='/privacy-policy'>
                    <b>
                      <i>motoclub.io/privacypolicy</i>
                    </b>
                  </Link>
                  , will also apply to your use of our Services. Our Privacy
                  Policy can be found directly on the Services, or, if
                  applicable, where the Services are made available for download
                  on your mobile device’s applicable app store, and are
                  incorporated herein by reference.
                </p>
              </li>
              <li>
                <b>Copyright Claims</b>
                <p>
                  If you believe that anything on the Services infringes upon
                  any copyright which you own or control, you may file a
                  notification of such infringement to the following contact
                  information:{' '}
                  <b>
                    <i>info@motoclub.io </i>
                  </b>
                  The notification must be a written communication that includes
                  the following:
                </p>
                <ol>
                  <li>
                    a physical or electronic signature of a person authorized to
                    act on behalf of the owner of an exclusive right that is
                    allegedly infringed;
                  </li>
                  <li>
                    if the complaint is about copyright or other intellectual
                    property rights, identification of the copyrighted work or
                    other intellectual property right claimed to have been
                    infringed, or, if multiple infringements are covered by a
                    single notification, a representative list of such
                    infringements;
                  </li>
                  <li>
                    if the complaint is about objectionable content, the reasons
                    for the objection;
                  </li>
                  <li>
                    identification of the material that you are objecting to and
                    that is to be removed or access to which is to be disabled,
                    and information reasonably sufficient to permit us to locate
                    the material;
                  </li>
                  <li>
                    information reasonably sufficient to permit us to contact
                    the complaining party, such as an address, telephone number
                    and, if available, an electronic mail address at which the
                    complaining party may be contacted;
                  </li>
                  <li>
                    a statement that the information in the notification is
                    accurate; and
                  </li>
                  <li>
                    <p>
                      if the complaint is about copyright or other intellectual
                      property rights, a statement under penalty of perjury,
                      that (i) the complaining party is authorized to act on
                      behalf of the owner of an exclusive right that is
                      allegedly infringed, and (ii) the complaining party has a
                      good-faith belief that use of the material in the manner
                      complained of is not authorized by the owner thereof, its
                      agent or the law.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p className='fw-bold'>Jurisdiction</p>
                <p>
                  These Terms, their subject matter and their formation, are
                  governed by the laws of the State of <b>California</b> and the
                  federal laws of the United States applicable therein, without
                  giving effect to any choice or conflict of law provisions or
                  rules. You and we agree that the UN Convention on Contracts
                  for the International Sale of Goods (Vienna, 1980) will not
                  apply to these Terms, the Services, or to any dispute or
                  transaction arising out of these Terms or the use of the
                  Services.
                  <b>USERS IN QUEBEC</b>: The provisions set out in this section
                  are prohibited by Quebec’s Consumer Protection Act (CQLR c
                  P-40.1) and therefore do not apply to users in Quebec, Canada.
                </p>
              </li>
              <li>
                <p className='fw-bold'>
                  Dispute Resolution; Mandatory Arbitration and Class Action
                  Waiver
                </p>
                <p>
                  This section includes an agreement to arbitrate and an
                  agreement that all such claims will be brought in arbitration
                  only in your individual capacity (and not as a class action or
                  other representative proceeding). Please read it carefully.
                  You may opt out of the arbitration agreement by following the
                  opt out procedure described below.
                </p>
                <ol>
                  <li>
                    <p>
                      Informal Process First. You agree that in the event of any
                      dispute between you and us, you will first contact us and
                      make a good faith sustained effort to resolve the dispute
                      before resorting to more formal means of resolution,
                      including without limitation any court action. This is a
                      condition precedent to proceeding in arbitration.
                    </p>
                  </li>
                  <li>
                    <p>
                      Arbitration Agreement. After the informal dispute
                      resolution process, any remaining dispute, controversy, or
                      claim (collectively, “<b>Dispute</b>”) arising out of or
                      relating in any way to this Agreement or your use of our
                      services or products, including the Services, or relating
                      in any way to the communications between you and us or any
                      other user of the Services, will be finally resolved by
                      binding arbitration. This mandatory arbitration agreement
                      applies equally to you and us. However, this arbitration
                      agreement does not (a) govern any Dispute by us for
                      infringement of its intellectual property or access to the
                      Services that is unauthorized or exceeds authorization
                      granted in these Terms; or (b) bar you from making use of
                      applicable small claims court procedures in appropriate
                      cases. If you are an individual you may opt out of this
                      arbitration agreement within thirty (30) days of the first
                      of the date you access or use this Services by following
                      the procedure described below.
                    </p>
                    <p>
                      You agree that the U.S. Federal Arbitration Act governs
                      the interpretation and enforcement of this provision, and
                      that you and us are each waiving the right to a trial by
                      jury or to participate in a class action. This arbitration
                      provision will survive any termination of these Terms.
                    </p>
                    <p>
                      If you wish to begin an arbitration proceeding, after
                      following the informal dispute resolution procedure, you
                      must send a letter requesting arbitration and describing
                      your Dispute to:
                    </p>
                    <p>
                      <b>CurrencyWorks USA Inc., </b>
                    </p>
                    3250 Oakland Hills Court
                    <br />
                    Fairfield, California 94534
                  </li>
                </ol>
              </li>
            </ol>
            <p>
              Email Address:{' '}
              <b>
                <i>info@motoclub.io</i>
              </b>
            </p>
            <p>
              The arbitration will be administered by the American Arbitration
              Association (AAA) under its rules including, if you are an
              individual, the AAA’s Consumer Arbitration Rules. If you are not
              an individual or have used the Services on behalf of an entity,
              the AAA’s Consumer Arbitration Rules will not be used. The AAA’s
              rules are available at{' '}
              <a href='http://www.adr.org/' target='_blank' rel='noreferrer'>
                www.adr.org
              </a>{' '}
              or by calling 1-800-778-7879.
            </p>
            <p>
              Payment of all filing, administration and arbitrator fees will be
              governed by the AAA’s rules. If you are an individual and have not
              accessed or used the Services on behalf of an entity, we will
              reimburse those fees for claims where the amount in dispute is
              less than $10,000, unless the arbitrator determines the claims are
              frivolous, and we will not seek attorneys’ fees and costs in
              arbitration unless the arbitrator determines the claims are
              frivolous.
            </p>
            <p>
              The arbitrator, and not any federal, state, or local court, will
              have exclusive authority to resolve any dispute relating to the
              interpretation, applicability, unconscionability, arbitrability,
              enforceability, or formation of this arbitration agreement,
              including any claim that all or any part of this arbitration
              agreement is void or voidable. However, the preceding sentence
              will not apply to the “Class Action Waiver” section below.
              <br />
              <b>
                IF YOU DO NOT WANT TO ARBITRATE DISPUTES WITH US AND YOU ARE AN
                INDIVIDUAL, YOU MAY OPT OUT OF THIS ARBITRATION AGREEMENT BY
                SENDING AN EMAIL TO{' '}
              </b>
              <b>info@motoclub.io</b>
              <b>
                {' '}
                WITHIN THIRTY (30) DAYS OF THE FIRST OF THE DATE YOU ACCESS OR
                USE THE SERVICES.
              </b>
            </p>
            <p>
              Class Action Waiver. To the maximum extent permitted by the
              consumer protection or other applicable laws in your jurisdiction
              of residence, all Disputes must be brought in the respective
              party’s individual capacity, and not as a plaintiff or class
              member in any purported class, collective, representative,
              multiple plaintiff, or similar proceeding (“Class Action”) except
              that an individual may seek in the arbitration public injunctive
              relief, and the AAA may include such relief in the award, where
              applicable. The parties expressly waive any ability to maintain
              any Class Action in any forum. If the Dispute is subject to
              arbitration, the arbitrator will not have authority to combine or
              aggregate similar claims or conduct any Class Action nor make an
              award to any person or entity not a party to the arbitration. Any
              claim that all or part of this Class Action Waiver is
              unenforceable, unconscionable, void, or voidable may be determined
              only by a court of competent jurisdiction and not by an
              arbitrator. The parties understand that any right to litigate in
              court, to have a judge or jury decide their case, or to be a party
              to a class or representative action, is waived, and that any
              Dispute must be decided individually, through arbitration.
            </p>
            <p>
              This class action waiver does not apply to the strict extent that
              the laws of your jurisdiction would not permit it. If this class
              action waiver is found to be unenforceable or if this class action
              waive does not apply to you due to local laws in your
              jurisdiction, then the entirety of the arbitration agreement, if
              otherwise effective, will be null and void. If for any reason a
              Dispute proceeds in court rather than in arbitration, you and us
              each waive any right to a jury trial.
            </p>
            <ol>
              <li>
                <p>
                  <b>Other Terms</b>
                </p>
                <ol>
                  <li>
                    <p>
                      Entire Agreement. These Terms and our Privacy Policy
                      constitute the whole legal agreement between you and us
                      and govern your use of the Services and completely replace
                      any prior agreements between you and us in relation to the
                      Services.
                    </p>
                  </li>
                  <li>
                    <p>
                      Links. You may link to our home page or page of the
                      Services, provided you do so in a way that is fair and
                      legal and does not damage our reputation or take advantage
                      of it. You must not establish a link in such a way as to
                      suggest any form of association, approval or endorsement
                      on our part where none exists. You must not establish a
                      link to our Services in any website that is not owned by
                      you. The website in which you are linking must comply in
                      all respects with the content standards set out at “Your
                      Access to and Use of Our Services” above. We reserve the
                      right to withdraw linking permission without notice.
                    </p>
                  </li>
                  <li>
                    <p>
                      Interpretation. In these Terms, (a) the captions and
                      headings are for convenience only and do not constitute
                      substantive matter and are not to be construed as
                      interpreting the contents of these Terms, (b) the word
                      “including”, the word “includes,” the phrase “such as”,
                      and similar words and phrases, when following a general
                      statement or term (whether or not non-limiting language
                      such as “without limitation” or “but not limited to” or
                      other words of similar import are used with reference
                      thereto), is not to be construed as limiting, and the word
                      “or” between two or more listed matters does not imply an
                      exclusive relationship between the matters being
                      connected, and (c) all references to Services will also
                      include any successor or replacement applications,
                      websites, content, or services containing substantially
                      similar information as the referenced Service(s).
                    </p>
                  </li>
                  <li>
                    <p>
                      Assignment. We may at any time assign our rights and
                      obligations under these Terms, in whole or in part,
                      without notice to you. You may not assign these Terms
                      without our prior, written consent. These Terms will inure
                      to the benefit of and bind you and us and our respective
                      personal and legal representatives, successors and
                      permitted assigns.
                    </p>
                  </li>
                  <li>
                    <p>
                      No Waiver. Our failure to insist upon or enforce any
                      provision of these Terms will not be construed as a waiver
                      of any provision or right.
                    </p>
                  </li>
                  <li>
                    <p>
                      Security. The transmission of information via the internet
                      is inherently not completely secure and we do not
                      guarantee that our Services will be secure or free from
                      bugs or viruses. You are responsible for configuring your
                      information technology and computer programs to access our
                      Services. You should use your own virus protection
                      software.
                    </p>
                  </li>
                  <li>
                    <p>
                      Severability. If any court of law, having jurisdiction to
                      decide on this matter, rules that any provision of these
                      Terms is invalid, then that provision will be removed from
                      the Terms without affecting the rest of the Terms, and the
                      remaining provisions of the Terms will continue to be
                      valid and enforceable.
                    </p>
                  </li>
                  <li>
                    <p>
                      Social Networks. If at any time the Services include
                      features that operate in conjunction with certain third
                      party social networking websites that you visit (“Social
                      Network Features”), your use of the Social Network
                      Features is governed by these Terms, but your access and
                      use of third party social networking websites and the
                      Services provided through these websites is governed by
                      the terms and conditions as well as any other agreements
                      posted on these websites. You agree that you alone are
                      responsible for your use of the social network features
                      and that we will not be liable to you or anyone else for
                      your violation or breach of any terms of use or other
                      agreement that may result from your use of the Social
                      Network Features.
                    </p>
                  </li>
                  <li>
                    <p>
                      Force Majeure. We will not be liable for delays, failures
                      in performance, or interruptions of the Services that
                      result directly or indirectly from any cause or condition
                      beyond our reasonable control, including significant
                      market volatility, any delay or failure due to any act of
                      God, act of civil or military authorities, act of
                      terrorism, civil disturbance, war, strike or other labor
                      dispute, fire, interruption in telecommunications or
                      internet services or network provider services, failure of
                      equipment and/or software, epidemic, outbreak of illness
                      or disease, declaration of public health emergency, other
                      catastrophe or any other occurrence which is beyond our
                      reasonable control and shall not affect the validity and
                      enforceability of any remaining provisions.
                    </p>
                  </li>
                  <li>
                    Translations. In the event of any conflict or inconsistency
                    between the Terms in the English and any translation thereof
                    in any other language,{' '}
                    <b>the English version will prevail</b>.
                  </li>
                </ol>
              </li>
            </ol>
          </Container>
        </div>
      </div>
      <Footer />
    </ScrollToTop>
  );
};

export default TermsOfUse;
