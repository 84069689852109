import { APIPath } from 'src/utility/constants/api';
import { BaseService } from 'src/utility/services/base';

class Payment {
  add(token: string) {
    return BaseService.post(`${APIPath.addPayment}?token=${token}`);
  }

  savedCard() {
    return BaseService.get(APIPath.savedCard);
  }

  cashout(amount: number) {
    return BaseService.get(`${APIPath.cashout}?amount=${amount}`);
  }

  cryptoCharge(chargeId: string) {
    return BaseService.get(`${APIPath.cryptoCharge}/${chargeId}`);
  }
}

const PaymentService = new Payment();
Object.freeze(PaymentService);
export { PaymentService };
