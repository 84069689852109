import { useEffect, useState } from "react";
import { Branding } from "src/types/branding";
import { UserService } from "src/utility/services";
import { CommonConstant, ErrorMessages } from "src/utility/constants/common";

export const HistoryHook = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    page: 0,
    size: CommonConstant.defaultPageSize,
    sort: "createdDate,desc",
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await UserService.history(filter);
      setData(data.concat(result || []));
      setHasMore((result || []).length === filter.size);
    } catch (error) {
      setError(ErrorMessages.default);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (filter) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const refreshData = () => {
    fetchData();
  };

  const pageChanged = () => {
    const temp = {
      ...filter,
      page: filter.page + 1,
    };
    setFilter({ ...temp });
  };

  return { data, loading, error, pageChanged, hasMore, refreshData };
};

export const BrandingHook = () => {
  const [data, setData] = useState<any>({
    stipePublishableKey: "",
    packOpeningAnimation: {},
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchData = async () => {
    try {
      setLoading(true);
      const result: Branding = await UserService.branding();
      setData(result);
    } catch (error) {
      setError(ErrorMessages.default);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { data, loading, error };
};

interface LatestHistoryType {
  transactionStatus: string;
  cryptoChargeCode: string;
  notes: string;
  pack: any;
}

export const LatestHistoryHook = () => {
  const [data, setData] = useState<LatestHistoryType | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchData = async () => {
    try {
      setLoading(true);
      const filter = {
        page: 0,
        size: 1,
        sort: "createdDate,desc",
      };
      const result = await UserService.history(filter);
      setData((result || []).length > 0 ? result[0] : null);
    } catch (error) {
      setError(ErrorMessages.default);
    } finally {
      setLoading(false);
    }
  };

  const refreshCardData = () => {
    fetchData();
  };

  return { data, loading, error, refreshCardData };
};

export const HistoryByIdHook = (transactionId:any) => {

  const [data,setData] = useState<LatestHistoryType | null>(null);
  const [loading,setLoading] = useState(false);
  const [error,setError] = useState('');

  const fetchData = async () => {
      try {
          setLoading(true);
          const result = await UserService.historyById(transactionId);
          setData(result);
      } catch (error:any) {
          setError(error.message || ErrorMessages.default);
      } finally {
          setLoading(false);
      }
  }

  const refreshData = () => {
      if (transactionId) {
          fetchData();
      }
  }

  return { data,loading,error,refreshData };
}