import React from 'react';
import { Container } from 'react-bootstrap';
import Footer from 'src/common/Footer';
import NavBar from 'src/common/NavBar';

const About = () => {
  return (
    <React.Fragment>
      <NavBar />
      <div className='bgdark h-100'>
        <div className='d-flex justify-content-center pt-5'>
          <div className='display-5 fw-bold mb-4 txt-white'>About Us</div>
        </div>
        <p className='text-center txt-white'>
          The world’s premier space for car enthusiasts and fans of automotive
          memorabilia.
        </p>
        <div className='txt-white bggray h-100 pt-5 mt-5'>
          <Container className='pt-5'>
            <p>
              Motoclub is the premier marketplace for digital automotive
              collectibles and the home of SparkNFTs. We collaborate with the
              leading automotive names to bring our members the hottest
              automobilia on the planet.
            </p>
            <p>
              Secure your pole position at the world’s premier automotive
              memorabilia platform where Motoclub members can hook up with the
              most exclusive #sparkNFTs on show.
            </p>
            <p>
              Our partnership with the leading brands in the world of
              automotive, like Barrett-Jackson and International Motorsport Hall
              of Fame, grant Motoclub members a key advantage to be at the front
              of the pack to experience the hottest field of #sparkNFTs on the
              circuit today.
            </p>
            <p>
              Reserve your spot at the start line to join the growing number of
              automotive enthusiasts and collectors to shakedown drops as we
              roll ‘em out.
            </p>
          </Container>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default About;
