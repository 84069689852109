import React from "react";
import {
  Card,
  Col,
  FormGroup,
  Modal,
  ModalBody,
  Row,
  Spinner,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import Heading from "src/common/Heading";
import FormButton from "src/common/FormButton";
import MessagePopup from "src/common/MessagePopup";
import { PacksHook, PackType } from "src/hooks/packs";
import { PacksService } from "src/utility/services";
import { CardsContextHook } from "src/context/cards";
import { BrandingContextHook } from "src/context/branding";
import DefaultCard from "src/assets/images/default-card.png";
import { ReactComponent as Package } from "src/assets/icons/package.svg";

const PackDetail = ({
  item,
  claimPack,
}: {
  item: PackType;
  claimPack: () => void;
}) => (
  <Col lg={6} className="mb-3">
    <Card className="bggray txt-white h-100">
      <Row className="h-100">
        <Col sm={7}>
          <Card.Img
            className="packImg"
            src={item.packTemplate?.picture?.url || DefaultCard}
            alt={item.packTemplate?.name}
          />
        </Col>
        <Col sm={5}>
          <Card.Body className="d-flex flex-column text-center h-100 justify-content-between">
            <div className="text-container">
              <Heading
                type="main"
                text={item.packTemplate?.name || "Pack Name"}
              />
              {/* <Card.Text>{item.packTemplate?.description}</Card.Text> */}
            </div>
            <FormButton
              text={item.claimed ? "Claimed" : "Open"}
              className="bgblue"
              onClick={() => !item.claimed && claimPack()}
            />
          </Card.Body>
        </Col>
      </Row>
    </Card>
  </Col>
);

const Unopened = (props: any) => {
  const [selectedPack, setSelectedPack] = React.useState<PackType | null>(null);
  const [confirmClaiming, setConfirmClaiming] = React.useState(false);
  const [showOpeningModal, setShowOpeningModal] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [error, setError] = React.useState("");
  const [videoTest, setVideoTest] = React.useState(false);
  const { data, loading, setData } = PacksHook();
  const { addCard } = CardsContextHook();
  const { data: branding } = BrandingContextHook();

  const claimPack = async () => {
    try {
      setVideoTest(false);
      setConfirmClaiming(false);
      setShowOpeningModal(true);
      if (selectedPack?.id) {
        const newCard = await PacksService.open(selectedPack.id);
        addCard(newCard[0].id);
        const temp = data.filter((pack) => pack.id !== selectedPack.id);
        setData(temp);
      }
      setShowOpeningModal(false);
      setShowModal(true);
      props.tab("spark");
    } catch (error) {
      setShowOpeningModal(false);
      setShowModal(true);
      setError("It's not possible to open pack now, please check back later");
    }
  };

  const confirmClaimPack = (item: PackType) => {
    setSelectedPack(item);
    setConfirmClaiming(true);
  };

  return (
    <React.Fragment>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" variant="light" />
        </div>
      ) : null}
      {!loading && data?.length > 0 ? (
        <Row>
          {data.map((item) => (
            <PackDetail
              key={item.id}
              item={item}
              claimPack={() => confirmClaimPack(item)}
            />
          ))}
        </Row>
      ) : null}
      {data?.length === 0 && !loading ? (
        <React.Fragment>
          <div className="d-flex flex-column justify-content-center align-items-center my-5 py-5">
            <Package color="white" className="mb-4" />
            <Heading text="All packs are opened." type="main" color="white" />
            <div className="txt-white">
              Buy new packs from{" "}
              <Link to="/app/pack-sale" className="navbar-link p-0 fw-bold">
                Pack Sales.
              </Link>
            </div>
          </div>
        </React.Fragment>
      ) : null}
      <MessagePopup
        show={confirmClaiming}
        title="Pack Claim"
        text="Are you sure you want to open this pack?"
      >
        <FormGroup className="d-flex">
          <FormButton
            text="Yes"
            className="me-2 bgblue"
            onClick={() => setVideoTest(true)}
          />
          <FormButton
            text="No"
            onClick={() => {
              setConfirmClaiming(false);
            }}
          />
        </FormGroup>
      </MessagePopup>
      <MessagePopup show={showOpeningModal} title="Opening Pack...">
        <FormGroup className="d-flex justify-content-center align-item-center">
          <Spinner animation="border" variant="light" />
        </FormGroup>
      </MessagePopup>
      <MessagePopup
        show={showModal}
        title={error ? "ERROR" : "Pack Claimed"}
        text={error ? error : "Your cards are now available on your dashboard."}
        btnText="Okay"
        onClick={() => {
          setShowModal(false);
          setError("");
        }}
        children=""
      />

      <Modal show={videoTest} fullscreen dialogClassName="pack-animation">
        <ModalBody>
          <video autoPlay muted onEnded={claimPack}>
            <source src={branding.packOpeningAnimation?.url} type="video/mp4" />
          </video>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Unopened;
