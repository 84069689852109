import React, { PropsWithChildren } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { Eye, EyeOff, Phone, Calendar, MapPin } from "react-feather";
import "src/common/styles/common.scss";

type FormInputType = {
  type: "text" | "email" | "password" | "number" | "date";
  border?: boolean;
  className?: string;
  error?: string;
  name?: string;
  placeholder?: string;
  required?: boolean;
  value?: string | number;
  phone?: boolean;
  calendar?: boolean;
  location?: boolean;
  maxLength?: number;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  children: any;
};

const FormInput: React.FC<PropsWithChildren<FormInputType>> = ({
  type,
  border,
  className,
  error,
  name,
  placeholder,
  required,
  value,
  phone,
  calendar,
  maxLength,
  location,
  onChange,
  children,
}) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [isFocus, setIsFocus] = React.useState(false);
  return (
    <InputGroup
      className={`formInput align-items-center mb-3 px-3 ${
        isFocus ? "inputActive" : ""
      } ${border ? "formInput-border" : ""} ${className ? className : ""}`}
    >
      {phone ? <Phone className="txt-lightgray" /> : null}
      {calendar ? <Calendar className="txt-lightgray" /> : null}
      {location ? <MapPin className="txt-lightgray" /> : null}
      <Form.Control
        name={name}
        className="formField"
        type={showPassword ? "text" : type}
        placeholder={placeholder}
        required={required}
        value={value}
        onFocus={() => {
          setIsFocus(true);
        }}
        onBlur={() => {
          setIsFocus(false);
        }}
        onChange={onChange}
        isInvalid={!!error}
        autoComplete="off"
        maxLength={maxLength}
      />
      {type === "password" ? (
        <Button
          className="bg-transparent showPass"
          onClick={() => {
            setShowPassword(!showPassword);
          }}
        >
          {!showPassword ? <Eye /> : <EyeOff />}
        </Button>
      ) : null}
      {children}
    </InputGroup>
  );
};

export default FormInput;
