import { useEffect, useState } from 'react';

import {
  CardsService,
  PackTemplatesService,
  PaymentService,
} from 'src/utility/services';
import { ErrorMessages } from 'src/utility/constants/common';
import { PackTemplateType } from 'src/hooks/pack-templates';

export const SavedCardHook = () => {
  const [data, setData] = useState<any | {}>({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await PaymentService.savedCard();
      setData(result);
    } catch (error) {
      setError(ErrorMessages.default);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { data, loading, error };
};

export const DetailsHook = (id: string, type: string) => {
  const [data, setData] = useState<PackTemplateType | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const fetchPackData = async () => {
    try {
      setLoading(true);
      const result = await PackTemplatesService.getById(id);
      setData(result);
    } catch (error) {
      setError(ErrorMessages.default);
    } finally {
      setLoading(false);
    }
  };

  const fetchCardData = async () => {
    try {
      setLoading(true);
      const result = await CardsService.getById(id);
      setData(result);
    } catch (error) {
      setError(ErrorMessages.default);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      if (type === 'pack') {
        fetchPackData();
      } else if (type === 'card') {
        fetchCardData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type]);

  return { data, loading, error };
};

interface CryptoChargeType {
  code: string | number | boolean;
}

export const CryptoChargeHook = (id: string) => {
  const [data, setData] = useState<CryptoChargeType | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await PaymentService.cryptoCharge(id);
      setData(result.data);
    } catch (error) {
      setError(ErrorMessages.default);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return { data, loading, error };
};
