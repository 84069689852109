import { useEffect, useState } from 'react';

import { PacksService } from 'src/utility/services';
import { ErrorMessages } from 'src/utility/constants/common';
import { Company, Owner } from 'src/types/user';
import { PackTemplateType } from 'src/hooks/pack-templates';

export interface PackType {
  buyPrice?: number | string;
  claimed?: boolean;
  company?: Company;
  fixed?: boolean;
  forSale?: boolean;
  id: number | string;
  owner?: Owner;
  packTemplate?: PackTemplateType;
  paid?: boolean;
  sellPrice: string | number;
}

export const PacksHook = () => {
  const [data, setData] = useState<PackType[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await PacksService.my();
      setData(result);
    } catch (error) {
      setError(ErrorMessages.default);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { data, loading, error, setData };
};

export const PackDetailsHook = (id: string) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await PacksService.getById(id);
      setData(result);
    } catch (error) {
      setError(ErrorMessages.default);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return { data, loading, error };
};
