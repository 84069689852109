export const APIPath = {
  branding: 'branding',

  // User
  login: 'authenticate',
  register: 'register',
  registerActivate: 'activate',
  forgotPassword: 'account/reset-password/init',
  resetPassword: 'account/reset-password/finish',
  changePassword: 'account/change-password',
  sendMobileVerification: 'account/mobile-verification/ask',
  verifyMobile: 'account/mobile-verification/verify',
  account: 'account',
  userHistory: 'card-transactions',

  // Cards
  cards: 'cards',
  myCards: 'cards/mine',
  sellCard: 'cards/market/sell',
  canSell: 'cards/market/can-sell',
  cardHistory: 'card-events/by-card',
  bankSetup: 'cash-out-requests/login-link',

  // Marketplace
  marketplace: 'cards/market',
  buyCard: 'cards/market/buy',

  // Packs
  packs: 'packs',
  buyPack: 'packs/buy/v2',
  myPacks: 'packs/mine',
  packTemplates: 'pack-templates',
  openPack: 'packs/open',

  // Payment
  addPayment: 'payment/add',
  savedCard: 'payment/get',
  cashout: 'payouts/cashout',
  cryptoCharge: 'crypto/charge',
};
