import { Container } from 'react-bootstrap';
import NavBar from 'src/common/NavBar';
import Footer from 'src/common/Footer';
import ScrollToTop from 'src/common/ScrollToTop';

const PrivacyPolicy = () => {
  return (
    <ScrollToTop>
      <NavBar />
      <div className='bgdark min-vh-100 pb-5'>
        <div className='txt-white pt-5'>
          <Container>
            <div className='d-flex justify-content-center'>
              <div className='display-5 fw-bold mb-4 txt-white'>
                Privacy Policy
              </div>
            </div>
            <div className='u-align-justify u-blog-control u-post-content u-text u-text-2'>
              <h2>
                <strong>HIGHLIGHTS </strong>
              </h2>
              <p>
                <strong>CurrencyWorks USA Inc. </strong>and our corporate
                affiliates (the “<strong>Company</strong>”, “<strong>we</strong>
                ”, “<strong>us</strong>”, “<strong>our</strong>”) understand
                that your time is valuable. Therefore, we have set out below
                some important highlights of our Privacy Policy. You can obtain
                more information about how we collect, use, disclose and protect
                your personal information by reviewing our full Privacy Policy
                below.
              </p>
              <p>
                For purposes of this Policy, “<strong>you</strong>” and “
                <strong>your</strong>” means you as the user of the Services.
              </p>
              <p>
                <strong>PLEASE READ THIS PRIVACY POLICY CAREFULLY</strong>, as
                it contains important information regarding your legal rights.
                If you do not consent to the collecting and processing of your
                personal information in accordance with this Privacy Policy, you
                may not access or use the Services. By clicking “I consent to
                the Privacy Policy”, or by using or accessing the Services, you
                represent that you understand and consent to the collection and
                processing of your personal information in accordance with this
                Privacy Policy, which forms an essential part of our Terms of
                Service; which are available here for reference{' '}
                <a
                  href='https://motoclubstg.wpengine.com/terms-of-service/'
                  data-type='URL'
                  data-id='https://motoclubstg.wpengine.com/terms-of-service/'
                >
                  motoclubstg.wpengine.com/terms-of-service
                </a>
                .
              </p>
              <h4>
                <strong>What personal information do we collect?</strong>
              </h4>
              <ul>
                <li style={{ fontWeight: '400' }} aria-level={1}>
                  <span style={{ fontWeight: '400' }}>
                    We collect personal information directly from users of our
                    website located at motoclubstg.wpengine.com (the “
                  </span>
                  <b>Website</b>
                  <span style={{ fontWeight: '400' }}>
                    ”), and such other related software, websites and services
                    (collectively, the “
                  </span>
                  <b>Service</b>
                  <span style={{ fontWeight: '400' }}>”).</span>{' '}
                  <span style={{ fontWeight: '400' }}>
                    The personal information we collect includes your name,
                    email address, billing information, site usage through the
                    use of tracking pixels to better serve advertising. You may
                    also provide additional information by choosing to fill out
                    free-form fields on our Service, if any, and we may also
                    collect and generate information from your access and use of
                    the Service such as performance data of the Service (the “
                  </span>
                  <b>Usage Data</b>
                  <span style={{ fontWeight: '400' }}> ”). </span>
                </li>
                <li style={{ fontWeight: '400' }} aria-level={1}>
                  <span style={{ fontWeight: '400' }}>
                    By using our Service you are choosing to provide us with
                    your personal information and you acknowledge and consent to
                    the processing of your personal information in the United
                    States, and Canada.
                  </span>
                </li>
                <li style={{ fontWeight: '400' }} aria-level={1}>
                  <span style={{ fontWeight: '400' }}>
                    We also use cookies, and tracking pixels on our Website and
                    automatically collect certain information about the way our
                    Service is used.
                  </span>
                </li>
              </ul>
              <h4>
                <strong>
                  Why do we collect personal information and how is it used?
                </strong>
              </h4>
              <ul>
                <li>
                  We collect personal information to facilitate your use of our
                  Service.
                </li>
                <li>
                  We also use the Usage Data in aggregate and de-identified form
                  for research and evaluation purposes.
                </li>
              </ul>
              <h4>
                <strong>Is personal information shared or disclosed?</strong>
              </h4>
              <ul>
                <li>
                  Personal information provided to us is never sold or rented to
                  other organizations.
                </li>
                <li>
                  We also use certain third party service providers who may
                  access and process the personal information we collect in
                  order to provide services which are integral to the operation
                  of our Service.
                </li>
                <li>
                  We may also disclose or share personal information for fraud
                  prevention, and other permitted disclosures under applicable
                  law.
                </li>
              </ul>
              <h4>
                <strong>How do we protect personal information?</strong>
              </h4>
              <ul>
                <li>
                  We have implemented safeguards to protect personal information
                  under our control, and regularly review our practices to
                  ensure they align with reasonable practices appropriate to the
                  level of sensitivity of the information, in order to safeguard
                  personal information against loss or theft, unauthorized
                  access, alteration or disclosure.
                </li>
              </ul>
              <h4>
                <strong>What are the risks?</strong>
              </h4>
              <ul>
                <li>
                  Although we take steps to safeguard the personal information
                  under our control, &#8220;perfect security&#8221; does not
                  exist online or elsewhere. In particular, we cannot guarantee
                  the security of information posted or transmitted using our
                  Service or via email. It is possible that third parties may
                  unlawfully intercept or access such information.
                </li>
              </ul>
              <h4>
                <strong>What are your choices?</strong>
              </h4>
              <ul>
                <li>
                  You may request to access and review your personal information
                  in our possession, subject to certain limitations.
                </li>
                <li>
                  You may also request that we correct your personal information
                  in our possession. We will make best efforts to comply with
                  such requests, subject to certain limitations.
                </li>
              </ul>
              <h4>
                <strong>What else should you know?</strong>
              </h4>
              <ul>
                <li>
                  We may use cloud services provided by third party service
                  providers who may be outside the United States, or Canada, to
                  store the personal information that we collect. This means
                  that your personal information may be transferred to, or
                  stored in, another country and accessible to foreign courts,
                  law enforcement and national security authorities.
                </li>
                <li>
                  We may amend our Privacy Policy from time-to-time. Although we
                  will notify you of major changes, we encourage you to check
                  for updates.
                </li>
                <li>
                  You can contact us with questions, complaints or to access
                  your personal information at{' '}
                  <a
                    href='/cdn-cgi/l/email-protection#7b12151d143b16140f1418170e19551214'
                    target='_blank'
                    rel='noopener'
                  >
                    <span
                      className='__cf_email__'
                      data-cfemail='6f060109002f02001b000c031a0d410600'
                    >
                      [email&#160;protected]
                    </span>
                  </a>
                  .
                </li>
              </ul>
              <h2>
                <strong>PRIVACY POLICY</strong>
              </h2>
              <p>
                <strong>CurrencyWorks USA Inc. </strong>and our corporate
                affiliates (the “<strong>Company</strong>”, “<strong>we</strong>
                ”, “<strong>us</strong>”, “<strong>our</strong>”) respect and
                uphold individual rights to privacy and the protection of
                personal information while balancing such rights with our need
                to process your personal information to manage our business and
                deliver our services to you. Based on applicable privacy and
                data protection principles common to the United States and other
                jurisdictions to which we are subject to, we have developed this
                privacy policy (the “<strong>Policy</strong>”) to explain our
                practices for processing your personal information. This Policy
                does not apply to our employees’ personal information or any
                anonymized information.
              </p>
              <p>
                This Policy describes our practices with respect to how we may
                collect, use, disclose, retain, dispose and otherwise process
                (collectively, “<strong>process</strong>”) and how you may
                correct, update and access your personal information that you
                provide as a user of (a) the website operated at
                motoclubstg.wpengine.com, and other locations from time to time
                (the “<strong>Website</strong>”, (b) any applications or other
                software we provide you (the “<strong>Application</strong>”),
                excluding third party software that may interact with the
                Service as defined below, and (c) the services we provide
                through the Website and the Application (collectively, the “
                <strong>Service</strong>”). This Policy references the general
                Terms of Service for the Service available at{' '}
                <a href='https://motoclubstg.wpengine.com/terms-of-service/'>
                  motoclubstg.wpengine.com/terms-of-service
                </a>
                , and forms an integral part of such Terms of Service. All
                capitalized terms not otherwise defined herein have the meaning
                provided in our Terms of Service.
              </p>
              <p>
                Our Service may contain links to other applications or Internet
                resources which are provided solely for your convenience and
                information. When you click on one of those links you are
                contacting another Internet resource. We have no responsibility
                or liability for, or control over, those other Internet
                resources or their collection, use and disclosure of your
                personal information. We encourage you to read the privacy
                policies of those other Internet resources to learn how they
                collect and use your personal information.
              </p>
              <p>
                <strong>PLEASE READ THIS PRIVACY POLICY CAREFULLY</strong>, as
                it contains important information regarding your legal rights.
                If you do not consent to the processing of your personal
                information in accordance with this Privacy Policy, you may not
                access or use the Services. By clicking “I consent to the
                Privacy Policy”, or by using or accessing the Services, you
                represent that you understand and consent to the processing of
                your personal information in accordance with this Privacy
                Policy.
              </p>
              <p>
                If you do not consent to the processing of your personal
                information in accordance with this Policy, please do not access
                or continue to use any of the Service or otherwise provide any
                personal information to us.
              </p>
              <h4>
                <strong>1. Personal Information</strong>
              </h4>
              <p>
                For the purposes of this Policy, “
                <strong>personal information</strong>” means any identifiable
                information about an individual, including but not limited to an
                individual’s name, home address, telephone number, email
                address, except any other information otherwise exempted by
                applicable privacy laws.
              </p>
              <p>
                When you use our Service, we may collect the following personal
                information from you:
              </p>
              <ul>
                <li>
                  Contact information such as name, email address, mailing
                  address, and phone number;
                </li>
                <li>Billing information such as credit card details;</li>
                <li>
                  Unique identifiers such as username, account number, and
                  password;
                </li>
                <li>Usage Data such as cookies; </li>
              </ul>
              <p>Your personal information may be collected when: </p>
              <ul>
                <li>You register for an account with us;</li>
                <li>You connect with us through social media;</li>
                <li>
                  We collect data from third parties or publicly available
                  sources.
                </li>
              </ul>
              <p>
                We only collect the personal information that we need. We
                encourage you to not provide us with any personal information
                beyond what is necessary and as requested by us.
              </p>
              <p>
                <em>Collection from Third Parties</em>
              </p>
              <p>
                We do not knowingly collect your personal information from a
                third party unless you consent or we are otherwise exempted,
                required or permitted by applicable laws to do so.
              </p>
              <p>
                If we collect your personal information from a third party, we
                will only process that information for the specific purpose for
                which it was provided to us in accordance with this Policy and
                the policy under which that information was collected.
              </p>
              <p>
                <em>Links to Other Sites</em>
              </p>
              <p>
                To the extent that our Service contains links to other sites,
                the owners of those sites are responsible for the privacy
                practices or content of those other sites. We do not endorse and
                will not be responsible for the privacy practices on third party
                applications.
              </p>
              <p>
                <em>Information about Minors</em>
              </p>
              <p>
                This site is intended solely for users who are at least eighteen
                years of age or older.
              </p>
              <p>
                We do not knowingly collect personal information about any
                minor. No such minor, nor any parent or guardian as it relates
                to such minor, should submit such minor’s personal information
                to us through the Service for any reason and under any
                circumstances.
              </p>
              <h4>
                <strong>
                  2. Purpose for Which Personal Information is Processed
                </strong>
              </h4>
              <p>
                We may process your personal information for the following
                purposes (the <strong>“Purposes</strong>”):
              </p>
              <ul>
                <li>To verify and authenticate your identity;</li>
                <li>
                  To ensure that the Service is optimized for your use and
                  benefit;
                </li>
                <li>To analyze user experience and improve the Service;</li>
                <li>To operate, maintain and provide to you the Service;</li>
                <li>
                  To communicate with you to provide you services, contacts,
                  materials or recommendations for your needs as identified by
                  you through phone, email, or the Service;
                </li>
                <li>
                  To carry out our obligations arising from any contracts
                  entered into between you and us;
                </li>
                <li>
                  To comply with internal policies and procedures and other
                  legal, accounting, or security requirements;
                </li>
                <li>
                  To share your personal information with our employees,
                  contractors, consultants and other third party service
                  providers such as application hosting providers, advertising
                  agencies, payment processors or customer service agencies (“
                  <strong>Third Party Processors</strong>”) who require this
                  information to assist us with establishing, maintaining and
                  managing our relationship with you and optimizing and
                  providing the Service for your use and benefit. Please note
                  that we may change or add Third Party Processors at any time,
                  in our sole discretion, either in Canada, the United States,
                  or elsewhere. We encourage you to reference this Policy from
                  time-to-time, to obtain updated information.
                </li>
              </ul>
              <p>
                We will only process your personal information for the Purposes
                for which we intend to process such information. Otherwise, we
                will not process your personal information without your consent.
              </p>
              <h4>
                <strong>3. Disclosure of Your Personal Information </strong>
              </h4>
              <p>
                We may disclose your personal information for the Purposes as
                described in this Policy in the following ways:
              </p>
              <ul>
                <li>
                  To our employees, contractors, and agents on an as-needed
                  basis;
                </li>
                <li>To our business partners;</li>
                <li>
                  To our service providers including application hosting
                  providers, advertising agencies, payment processors and
                  customer service agencies; and
                </li>
                <li>
                  To law enforcement, government or regulatory bodies, or other
                  lawful authorities.
                </li>
              </ul>
              <p>
                Your personal information that we collect may be processed
                outside of the United States but only in relation to the
                Purposes. As a result, your personal information may be
                accessible to law enforcement and regulatory authorities in
                accordance with other jurisdictions’ applicable laws.
              </p>
              <h4>
                <strong>
                  4. Legal Basis for Processing Your Personal Information
                </strong>
              </h4>
              <p>
                <em>Consent</em>
              </p>
              <p>
                We will process your personal information only with your
                knowledge and consent, except where exempted, required or
                permitted by applicable laws. The form of consent may vary
                depending on the circumstances and the type of information being
                requested. Your consent may be express with clear options to say
                “yes” or “no”, such as by being asked to check a box to indicate
                your consent, or implied, such as when you provide us with your
                address through a form or email seeking information and we use
                those means to respond to your request. Your consent can also be
                provided by your authorized representative. Taking into account
                the sensitivity of your personal information, Purposes of
                collection, and your reasonable expectations, we will obtain the
                form of consent that is appropriate to the personal information
                being processed. By using our Service or otherwise by choosing
                to provide us with your personal information, you acknowledge
                and consent to the processing of your personal information in
                accordance with this Policy and as may be further identified
                when the personal information is collected. When we process your
                personal information for a new purpose, we will document that
                new purpose and ask for your consent again.
              </p>
              <p>
                If you do not consent to the processing of your personal
                information in accordance with this Policy, please do not access
                or continue to use any of the Service or otherwise provide any
                personal information to us.
              </p>
              <p>
                You may refuse to provide consent or may notify us at any time
                that you wish to withdraw or change your consent to the
                processing of your personal information without penalty, subject
                to legal or contractual restrictions and reasonable notice by
                (i) changing your privacy preferences through the Service or by
                (ii) deleting your account with the Service and stopping use of
                the Service. However, if you withdraw or change your consent, we
                may not be able to provide you with the Service through the
                Service.
              </p>
              <p>
                <em>Other Legal Bases </em>
              </p>
              <p>
                Aside from consent, we may also process your personal
                information under other legal bases, as permitted by applicable
                laws.
              </p>
              <h4>
                <strong>5. Security of Personal Information</strong>
              </h4>
              <p>
                The security of your personal information is important to us. We
                protect personal information using physical, technological and
                organizational safeguards. We regularly review our practices to
                ensure they align with reasonable industry practices appropriate
                to the level of sensitivity to safeguard personal information
                against loss or theft, unauthorized access, alteration or
                disclosure.
              </p>
              <p>
                However, no method of transmission over the Internet, or method
                of electronic storage, is completely secure; as such, despite
                our safeguards and protocols, we cannot fully guarantee the
                security of your personal information and you should always
                exercise caution when disclosing personal information over the
                Internet.
              </p>
              <h4>
                <strong>
                  6. Requests for Access to and Correction of Personal
                  Information
                </strong>
              </h4>
              <p>
                Applicable privacy laws allow, to varying degrees, individuals
                the right to access or request the correction of errors or
                omissions in their personal information that is in our custody
                or under our control. You may request access to and review of
                your personal information in our possession. However, access may
                be declined where permitted or required by applicable law.
              </p>
              <p>
                You may request that we correct your personal information in our
                possession. We reserve the right not to change any personal
                information if we do not agree that it is inaccurate or
                outdated, but will append any alternative text the individual
                concerned believes appropriate.
              </p>
              <p>
                If access cannot be provided, we will notify the individual
                making the request within 30 days, in writing, of the reasons
                for the refusal.
              </p>
              <h4>
                <strong>7. CASL Policy </strong>
              </h4>
              <p>
                We are committed to compliance with{' '}
                <a href='http://laws-lois.justice.gc.ca/eng/acts/E-1.6/index.html'>
                  Canada’s Anti-Spam Legislation
                </a>{' '}
                (“<strong>CASL</strong>”). Any electronic communication we send
                to outside parties is protected by a range of business
                procedures, processes and policies to ensure that such
                communication is done in compliance with CASL. In our electronic
                communications with outside parties, we comply with the rules
                established by CASL and enforced by various Canadian authorities
                including the Canadian Radio-television and Telecommunications
                Commission. CASL regulates, and our policies generally apply to,
                each commercial electronic message (a “<strong>CEM</strong>”)
                that we send. A CEM is an electronic message sent to an
                electronic address that, among its purposes, encourages
                participation in a commercial activity.
              </p>
              <p>
                In addition to adopting and updating this Policy, we undertake
                various transparency initiatives to ensure we comply with CASL,
                which include:
              </p>
              <ul>
                <li>
                  <strong>Consent</strong>— we do not send you CEMs without your
                  consent. This consent typically must be “express” (expressly
                  acknowledged by you), but in certain circumstances can be
                  “implied”. In other limited circumstances, consent is not
                  required. We modified or adopted our sign-up, registration and
                  consent forms in order to ensure that your consent is
                  meaningful (i.e. informed and freely given) as required by
                  CASL. When we collect your electronic contact information, you
                  will know the exact purposes behind the collection.
                </li>
                <li>
                  <strong>Content</strong>— we adopted processes to ensure that
                  our CEMs contain the following requirements prescribed under
                  CASL, which will usually be in the footer of the CEM. We will:
                  <ul>
                    <li>
                      Identify ourselves as the party sending the CEM, and
                      whether we are sending the message on our own behalf or on
                      behalf of someone else;
                    </li>
                    <li>Provide you with our contact information; and</li>
                    <li>
                      Set out a clear, working unsubscribe mechanism or
                      preference centre that is easy to use, automatic, and at
                      no cost to you (other than your own cost of connecting to
                      the Internet).
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>Clarity</strong>— we ensured that each aspect of a
                  CEM, including its header, content, or any links or URLs in
                  the CEM) conveys the appropriate information, whether viewed
                  individually or taken as a whole, so that you always know what
                  you are clicking on.
                </li>
              </ul>
              <p>
                If you receive a CEM from us but believe that you should not
                have or no longer wish to receive CEMs, we will aim to respect
                your preferences in a timely manner once you update them through
                our unsubscribe mechanism. CASL requires us to process
                unsubscribe requests within 10 business days. If you have any
                questions or concerns about our unsubscribe options, you may
                contact us at the address indicated in Section 10 below.
              </p>
              <h4>
                <strong>8. Retention of Your Personal Information</strong>
              </h4>
              <p>
                We generally keep personal information for only as long as it is
                needed to accomplish the Purposes for which it was collected, or
                as needed for authorized or legitimate purposes. More
                specifically, we retain personal information as long as
                necessary for the fulfillment of the identified Purposes for its
                collection or as otherwise necessary to comply with applicable
                laws or protect our interests. When personal information is no
                longer necessary or relevant for the identified Purposes, or is
                required to be retained by applicable laws, we will take steps
                to have it deleted, destroyed, erased, aggregated or made
                anonymous. We use reasonable industry practices to ensure we
                have adequate controls, schedules and practices for information
                and records retention and destruction which apply to personal
                information.
              </p>
              <h4>
                <strong>9. Updates or Changes to this Policy</strong>
              </h4>
              <p>
                This Policy was last updated on June 6, 2021. We will
                occasionally update this Policy and revise the &#8220;last
                updated&#8221; date appearing in this paragraph.
              </p>
              <p>
                If we make any material changes we will either (a) notify you by
                email (sent to the email address listed in your account), or (b)
                provide a notice on the Service or otherwise through the Service
                before the change becomes effective. Any change to this Policy
                will apply to existing information, as well as information
                collected onwards from the date that this Policy is posted or on
                the date as specified in the notification. We encourage you to
                periodically review this page for the latest information on our
                privacy practices to ensure you are aware of any changes. Your
                continued use of the Service signifies your acceptance of any
                changes to this Policy.
              </p>
              <h4>
                <strong>10. Contact Information for Privacy Officer</strong>
              </h4>
              <p>
                You can direct any questions or concerns regarding our
                compliance with this Policy and our processing of your personal
                information to our Privacy Officer by emailing info@motoclub.io
              </p>
              <p>
                If you are not satisfied with our Privacy Officer’s response to
                your question or concern, you may be able to file a complaint
                under applicable privacy laws. Our Privacy Officer will provide
                you with the contact information to do so if requested. We
                strive to offer an accessible and simple complaint procedure. We
                will promptly investigate all complaints received, and if a
                complaint is justified, we will take the necessary steps to
                resolve the issue in question.
              </p>
              <p>
                <em>End of Policy </em>
              </p>
            </div>
          </Container>
        </div>
      </div>
      <Footer />
    </ScrollToTop>
  );
};

export default PrivacyPolicy;
