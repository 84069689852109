import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import { Col, Container, Form, FormGroup, Row } from "react-bootstrap";
import Heading from "src/common/Heading";
import FormInput from "src/common/FormInput";
import CustomCard from "src/common/CustomCard";
import FormButton from "src/common/FormButton";
import { UserService } from "src/utility/services";
import MessagePopup from "src/common/MessagePopup";
import LoadingSpinner from "src/common/LoadingSpinner";
import { ReactComponent as Key } from "src/assets/icons/key.svg";

const passwordSchema = Yup.object().shape({
  currentPassword: Yup.string()
    .min(4, "Password should be between 4 to 16 characters")
    .max(16, "Password should be between 4 to 16 characters")
    .required("Current password is required"),

  newPassword: Yup.string()
    .min(4, "Password should be between 4 to 16 characters")
    .max(16, "Password should be between 4 to 16 characters")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf(
      [Yup.ref("newPassword"), null],
      "New and Confirm Password must match"
    )
    .required(),
});

type FormType = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

const ChangePassword = () => {
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [oldPassword, setOldPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [error, setError] = React.useState("");

  const onSubmit = async (data: FormType) => {
    try {
      setError("");
      setLoading(true);
      const requestBody = {
        currentPassword: data.currentPassword,
        newPassword: data.newPassword,
      };
      await UserService.changePassword(requestBody);
      setSuccess(true);
    } catch (err: any) {
      setError(err?.error?.title || "Invalid Password");
    } finally {
      setLoading(false);
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
    }
  };
  return (
    <React.Fragment>
      {loading ? <LoadingSpinner /> : null}
      <MessagePopup
        show={success}
        title="SUCCESS"
        text="Your Password has been changed."
        btnText="OK"
        onClick={() => {
          setSuccess(false);
        }}
        children=""
      />
      <div className="bgdark min-vh-100">
        <Container className="h-100 py-5">
          <Row className="txt-white txt-medium align-items-center pb-5">
            <Col lg={6} sm={6} xs={12} className="responsive-left">
              <Heading
                type="main"
                text="My Password"
                color="white"
                className="txt-heading"
              />
            </Col>
            <Col lg={6} sm={6} xs={12} className="responsive-right">
              <Link to="/app/dashboard" className="txt-medium navbar-link">
                My Wallet
              </Link>
              <Link to="/app/pack-sale" className="txt-medium navbar-link ps-5">
                Pack Sale
              </Link>
            </Col>
          </Row>
          <Row className="justify-content-center align-items-center m-0">
            <Col lg={5} md={8}>
              <CustomCard bg="gray">
                <div className="d-flex align-items-center ms-3 my-4">
                  <Key className="mx-2" />
                  <Heading
                    type="subheading"
                    text="Change Password"
                    className="m-0"
                  />
                </div>
                <Formik
                  initialValues={{
                    currentPassword: "",
                    newPassword: "",
                    confirmPassword: "",
                  }}
                  validationSchema={passwordSchema}
                  onSubmit={(values, { resetForm }) => {
                    onSubmit(values);
                    resetForm();
                  }}
                >
                  {({ errors, handleChange, handleSubmit, values }) => (
                    <Form onSubmit={handleSubmit} className="my-4">
                      <FormGroup className="d-flex flex-column align-items-center">
                        <FormInput
                          type="password"
                          name="currentPassword"
                          placeholder="Old Password"
                          value={oldPassword}
                          border
                          required
                          error={errors.currentPassword && oldPassword}
                          onChange={(e) => {
                            handleChange(e);
                            setOldPassword(e?.target?.value);
                          }}
                          children=""
                        />
                        <FormInput
                          type="password"
                          name="newPassword"
                          placeholder="New Password"
                          value={newPassword}
                          border
                          required
                          error={errors.newPassword && newPassword}
                          onChange={(e) => {
                            handleChange(e);
                            setNewPassword(e?.target?.value);
                          }}
                          children=""
                        />
                        <FormInput
                          type="password"
                          name="confirmPassword"
                          placeholder="Confirm Password"
                          value={confirmPassword}
                          border
                          required
                          error={errors.confirmPassword && confirmPassword}
                          onChange={(e) => {
                            handleChange(e);
                            setConfirmPassword(e?.target?.value);
                          }}
                          children=""
                        />
                      </FormGroup>
                      <FormGroup className="d-flex justify-content-center">
                        <FormButton type="submit" text="SAVE" />
                      </FormGroup>
                      {error ? (
                        <p className="text-center txt-medium text-danger m-0 mt-3">
                          {error}
                        </p>
                      ) : null}
                      {(errors.currentPassword && values.currentPassword) ||
                      (errors.newPassword && values.newPassword) ||
                      (errors.confirmPassword && values.confirmPassword) ? (
                        <p className="text-center txt-medium text-danger m-0 mt-3">
                          {errors.currentPassword ||
                            errors.newPassword ||
                            errors.confirmPassword}
                        </p>
                      ) : null}
                    </Form>
                  )}
                </Formik>
              </CustomCard>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ChangePassword;
