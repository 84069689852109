import React from 'react';
import { Button, ButtonProps } from 'react-bootstrap';

import 'src/common/styles/common.scss';

interface FormButtonType extends ButtonProps {
  type?: 'button' | 'submit';
  icon?: React.ReactNode;
  text?: string;
  className?: string;
}

const FormButton: React.FC<FormButtonType> = ({
  type = 'button',
  icon,
  text,
  className,
  children,
  ...props
}) => {
  return (
    <Button
      type={type}
      variant='outline-secondary'
      className={`formInput d-flex justify-content-center align-items-center ${
        type === 'submit' ? 'formSubmit txt-submit' : 'formButton'
      } ${className ? className : ''}`}
      {...props}
    >
      {icon ? icon : null}
      {text ? text : null}
      {children}
    </Button>
  );
};

export default FormButton;
