import axios, {
  AxiosResponse,
  AxiosError,
  AxiosRequestConfig,
  AxiosRequestHeaders,
} from 'axios';
import { BaseURL } from 'src/utility/constants/common';
import { UserService } from 'src/utility/services/user';

const baseURL = BaseURL;

const onSuccess = (response: AxiosResponse) => {
  return response.data;
};

const onError = async (error: AxiosError) => {
  console.debug('Request Failed:', error);

  console.debug('Request Failed:', error.config);

  if (error.response) {
    // Request was made but server responded with something
    // other than 2xx
    console.debug('Status:', error.response.status);
    console.debug('Data:', error.response.data);
    console.debug('Headers:', error.response.headers);

    if (
      error.response.status === 401 &&
      error.response.config?.url?.indexOf('api/authenticate') === -1
    ) {
      window.localStorage.clear();
      // window.location.href = '/login';
      return Promise.reject(error);
    }
  }

  return Promise.reject({
    error: error?.response?.data?.error || error.response?.data,
    status: error?.response?.status,
  });
};

const request = async (options: AxiosRequestConfig, isSecure: boolean) => {
  let headers = { 'Content-Type': 'application/json', tenant: '1' };

  if (isSecure) {
    const token = UserService.getToken();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    headers.Authorization = `Bearer ${token}`;
  }

  const client = axios.create({
    baseURL,
    headers: { ...headers },
  });

  client.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

  return client(options).then(onSuccess).catch(onError);
};

const uploadFiles = (
  url: string,
  data: {} | null,
  headers: AxiosRequestHeaders
) => {
  const client = axios({
    url,
    method: 'PUT',
    headers: { ...headers },
    data,
  });

  return client.then(onSuccess).catch(onError);
};

export class BaseService {
  static get = (url: string, isSecure = true) => {
    return request(
      {
        url,
        method: 'GET',
      },
      isSecure
    );
  };

  static post = (url: string, data?: {} | null, isSecure = true) => {
    return request(
      {
        url,
        method: 'POST',
        data,
      },
      isSecure
    );
  };

  static put = (url: string, data: {} | null, isSecure = true) => {
    return request(
      {
        url,
        method: 'PUT',
        data,
      },
      isSecure
    );
  };

  static remove = (url: string, isSecure = true) => {
    return request(
      {
        url,
        method: 'DELETE',
      },
      isSecure
    );
  };

  static upload = (url: string, data: {}, header: AxiosRequestHeaders) => {
    return uploadFiles(url, data, header);
  };
}
