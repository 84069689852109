import React from 'react';

import 'src/common/styles/common.scss';

type HeadingType = {
  type: 'main' | 'subheading';
  text: string;
  center?: boolean;
  color?: 'white' | 'gray';
  className?: string;
};

const Heading: React.FC<HeadingType> = ({
  type,
  text,
  center,
  color,
  className,
}) => {
  return (
    <h2
      className={`text-break ${
        type === 'main' ? 'heading txt-large' : 'txt-subheading'
      } ${center ? 'text-center' : ''} ${color ? `txt-${color}` : ''} ${
        className ? className : ''
      }`}
    >
      {text}
    </h2>
  );
};

export default Heading;
