import React, { PropsWithChildren } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

interface ScrollToTopProps {
  children: any;
  header?: string;
  type?: "signup" | "signin";
  bg?: "dark" | "gray";
  className?: string;
}

const ScrollToTop: React.FC<PropsWithChildren<ScrollToTopProps>> = ({
  children,
}) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <React.Fragment>{children}</React.Fragment>;
};

export default ScrollToTop;
