import React from 'react';
import 'src/common/FormSeparator/style.scss';

type FormSeparatorType = {
  text?: string;
  type?: 'dark' | 'gray';
};
const FormSepartor: React.FC<FormSeparatorType> = ({ text, type }) => {
  return (
    <div className='d-flex justify-content-center'>
      <div
        className={`formSeparator ${
          type === 'gray' ? 'color-white' : 'color-gray'
        }`}
      >
        {text ? (
          <span className={`px-2 py-2 ${type ? `bg${type}` : ''}`}>{text}</span>
        ) : null}
      </div>
    </div>
  );
};

export default FormSepartor;
