/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { Card, Col, Form, FormGroup, Modal, Row } from "react-bootstrap";
import { Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from "react-google-login";
import * as Yup from "yup";

import BackgroundImage from "src/common/BackgroundImage";
import CustomCard from "src/common/CustomCard";
import FormButton from "src/common/FormButton";
import FormInput from "src/common/FormInput";
import FormSepartor from "src/common/FormSeparator";
import LoadingSpinner from "src/common/LoadingSpinner";
import TermsCheckbox from "src/common/TermsCheckbox";
import { UserService } from "src/utility/services";
import { AuthHook } from "src/context/auth";
import {
  ErrorMessages,
  REACT_APP_GOOGLE_CLIENT_ID,
} from "src/utility/constants/common";

import carBg from "src/assets/images/car-bg.jpg";
import { ReactComponent as GoogleIcon } from "src/assets/icons/google.svg";
import { ReactComponent as MotoclubLogo } from "src/assets/images/motoclub-new-logo.svg";
import "src/screens/Login/style.scss";

const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Email Address is Incomplete").required("Required"),
  password: Yup.string()
    .min(4, "Password should be between 4 to 16 characters")
    .max(16, "Password should be between 4 to 16 characters")
    .required("Required"),
  rememberMe: Yup.boolean(),
});

interface FormType {
  email: string;
  password: string;
  rememberMe: boolean;
}

const Login = () => {
  const { login: loginContext } = AuthHook();
  const navigate = useNavigate();

  const [loading, setLoading] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [authError, setAuthError] = React.useState("");
  const [token, setToken] = React.useState("");

  const afterLogin = async () => {
    setTimeout(async () => {
      try {
        UserService.storeToken(token);
        const user = await UserService.get();
        UserService.storeUser(user);
        loginContext(user);
        navigate("/app/pack-sale");
      } catch (err: any) {
        setAuthError(err?.title || err?.detail || ErrorMessages.default);
      }
    });
  };

  const googleLogin = async (googleToken: string) => {
    try {
      setShowModal(false);
      setLoading(true);
      const result = await UserService.login({
        googleIdToken: googleToken,
      });
      console.log(result);
      setToken(result.id_token);
      setShowModal(true);
    } catch (err: any) {
      setAuthError(
        err?.title ||
          err?.error?.detail ||
          err?.error?.title ||
          ErrorMessages.default
      );
    } finally {
      setLoading(false);
    }
  };

  const googleLoginInit = (
    data: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => {
    if ("tokenId" in data) {
      setToken(data.tokenId);
      googleLogin(data.tokenId);
    }
  };

  const onSubmit = async (values: {
    email: string;
    password: string;
    rememberMe: boolean;
  }) => {
    try {
      setAuthError("");
      setLoading(true);
      const requestBody = {
        username: values.email,
        password: values.password,
        rememberMe: values.rememberMe,
      };
      const result: { id_token: string } = await UserService.login(requestBody);
      setToken(result.id_token);
      setShowModal(true);
    } catch (err: any) {
      setAuthError(err?.data?.detail || ErrorMessages.badCredentials);
    } finally {
      setLoading(false);
    }
  };

  const googleClient = REACT_APP_GOOGLE_CLIENT_ID || "";
  return (
    <React.Fragment>
      <Modal show={showModal} className="ms-2 termsModal">
        <CustomCard header="Terms">
          <p className="text-center">
            By proceeding you accept our{" "}
            <a
              target="_blank"
              className="redirectWhite"
              href="https://motoclub.io/privacy-policy/"
            >
              privacy policy
            </a>{" "}
            and our{" "}
            <a
              target="_blank"
              className="redirectWhite"
              href="https://motoclub.io/terms-of-service/"
            >
              terms of service
            </a>
            .
          </p>
          <FormButton type="submit" text="ACCEPT" onClick={afterLogin} />
        </CustomCard>
      </Modal>
      {loading ? <LoadingSpinner /> : null}
      <BackgroundImage src={carBg} />
      <Row className="m-0 align-items-center h-100">
        <Col lg={4} md={6} sm={8} className="mt-4">
          <div className="d-flex justify-content-center mb-4">
            <a href="https://motoclub.io/">
              <MotoclubLogo className="motoclub-logo" />
            </a>
          </div>
          <CustomCard header="Sign In" type="signin" className="m-0">
            <Card.Body className="d-flex justify-content-center p-0">
              <GoogleLogin
                clientId={googleClient}
                cookiePolicy="single_host_origin"
                responseType="code,token"
                onSuccess={googleLoginInit}
                render={(renderProps) => (
                  <FormButton
                    icon={
                      <GoogleIcon width="20px" height="100%" className="mx-2" />
                    }
                    text="Sign in with Google"
                    onClick={renderProps.onClick}
                  />
                )}
              />
            </Card.Body>

            <FormSepartor text="Or" type="dark" />

            <Formik
              initialValues={{ email: "", password: "", rememberMe: false }}
              validationSchema={LoginSchema}
              onSubmit={onSubmit}
            >
              {({ errors, handleChange, handleSubmit, values }) => (
                <Form
                  onSubmit={handleSubmit}
                  className="d-flex justify-content-center flex-column"
                >
                  <FormGroup className="d-flex flex-column align-items-center">
                    <FormInput
                      type="email"
                      name="email"
                      placeholder="Email Address"
                      onChange={handleChange}
                      error={errors.email && values.email}
                      children=""
                    />
                    <FormInput
                      type="password"
                      name="password"
                      placeholder="Password"
                      onChange={handleChange}
                      error={errors.password && values.password}
                      children=""
                    />
                  </FormGroup>

                  <TermsCheckbox
                    type="remember"
                    name="rememberMe"
                    onChange={handleChange}
                  />

                  <Form.Group className="d-flex justify-content-around mb-3">
                    <a
                      href="https://motoclub.io/support-faq-tab/customer-support/"
                      target="_blank"
                    >
                      <Card.Text className="login-txtBtn">
                        Technical Support
                      </Card.Text>
                    </a>
                    <Link to="/forgot-password">
                      <Card.Text className="login-txtBtn">
                        Forgot Password?
                      </Card.Text>
                    </Link>
                  </Form.Group>
                  <Form.Group className="d-flex justify-content-center">
                    <FormButton type="submit" text="SIGN IN" />
                  </Form.Group>
                  {authError ? (
                    <span className="text-center txt-medium text-danger mt-3">
                      {authError}
                    </span>
                  ) : null}
                  {(errors.email && values.email) ||
                  (errors.password && values.password) ? (
                    <span className="text-center txt-medium text-danger mt-3">
                      {errors.email || errors.password}
                    </span>
                  ) : null}
                </Form>
              )}
            </Formik>
          </CustomCard>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Login;
