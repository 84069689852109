import moment, { MomentInput } from 'moment';

export default class DateUtility {
  static formatDate = (date: MomentInput, format = 'MM/DD/YYYY hh:mm A') => {
    if (!date) {
      return '';
    }
    return moment(date).format(format);
  };

  static getDays = () =>
    Array.apply(null, Array(7)).map((_, i) =>
      moment(i, 'e')
        .startOf('week')
        .isoWeekday(i + 1)
        .format('dddd')
    );

  static addDays = (date: MomentInput, days: number) =>
    moment(date).add(days, 'days').toDate();

  static isDateAfter = (date1: MomentInput, date2: MomentInput) =>
    moment(date1).isAfter(moment(date2));
}
