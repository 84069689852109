import React, { PropsWithChildren } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import AppScreen from "src/screens";
import { UserService } from "src/utility/services";
import { Roles } from "src/utility/constants/common";

interface AuthContextType {
  user: any;
  loading: boolean;
  isAuthenticated: boolean;
  login: (data: any) => void;
  logout: () => void;
}

const AuthContext = React.createContext<AuthContextType>({
  user: null,
  loading: true,
  isAuthenticated: false,
  login: () => {},
  logout: () => {},
});

interface AuthProviderProps {
  children: any;
}

export const AuthProvider: React.FC<PropsWithChildren<AuthProviderProps>> = ({
  children,
}): React.ReactElement => {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const loadToken = () => {
      setIsAuthenticated(UserService.isAuthenticated());
      setUser(UserService.getUser());
      setLoading(false);
    };
    loadToken();
  }, []);

  const login = (data: any) => {
    setIsAuthenticated(true);
    setUser(data);
  };

  const logout = () => {
    setIsAuthenticated(false);
    setUser(null);
  };

  return (
    <AuthContext.Provider
      value={{ user, loading, isAuthenticated, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const AuthHook = () => React.useContext(AuthContext);

export const ProtectedRoute: React.FC = (): React.ReactElement | null => {
  const { pathname } = useLocation();

  const { isAuthenticated, loading, user } = AuthHook();
  const allowedMarketplace = React.useMemo(
    () => (user?.authorities || []).includes(Roles.marketplace),
    [user]
  );

  if (loading) {
    return null;
  }
  const Screen = () => {
    return isAuthenticated &&
      (pathname !== "/app/marketplace" || allowedMarketplace) ? (
      <AppScreen />
    ) : (
      <Navigate to="/login" />
    );
  };
  return (
    <Routes>
      <Route path="*" element={<Screen />} />
    </Routes>
  );
};
